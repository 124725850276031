import { Col, Layout, Row, Segmented, Space } from "antd";
import React, { useMemo, useState } from "react";

import { EOLFilterOptions, KeyValueIntItem } from "../client";
import {
  GROUP_BY_IMAGES,
  GroupByOptionsType,
} from "../components/filters/GroupByDropdown";
import { IMAGE_ALL } from "../components/filters/ImagesVerifiedFilter";
import ImagesView from "../components/ImagesView";
import { SearchInput } from "../components/Search";
import ImageVerifiedFilterDropdown from "../components/filters/ImagesVerifiedFilter";
import { Helmet } from "react-helmet-async";
import { SegmentedValue } from "antd/es/segmented";

const { Content } = Layout;

export const NO_BUCKET = "All";
export const EOL_BUCKET = "EOL";
export const EOL_LESS_SIX_MONTH_BUCKET = "EOL <6mo";
export const EOL_LESS_ONE_YEAR_BUCKET = "EOL <12mo";
export const EOL_MORE_ONE_YEAR_BUCKET = "EOL >12mo";

export type Filters =
  | typeof NO_BUCKET
  | typeof EOL_BUCKET
  | typeof EOL_LESS_SIX_MONTH_BUCKET
  | typeof EOL_LESS_ONE_YEAR_BUCKET
  | typeof EOL_MORE_ONE_YEAR_BUCKET;

const HomePage = () => {
  const [groupBy, setGroupBy] = useState<GroupByOptionsType>(GROUP_BY_IMAGES);
  const [filter, setFilter] = useState<EOLFilterOptions>(EOLFilterOptions.ALL);
  const [searchTerm, setSearchTerm] = useState("");
  const [imageVerifiedFilter, setImageVerifiedfilter] = useState(IMAGE_ALL);

  const API_URL = process.env.REACT_APP_API_URL;
  if (!API_URL) {
    throw Error("API_URL not set");
  }

  const EOLFilterOptionMappings = useMemo(
    () => ({
      [EOLFilterOptions.ALL]: "All",
      [EOLFilterOptions.EOL]: "EOL",
      [EOLFilterOptions.EOL_LESS_THAN_6MO]: "EOL <6mo",
      [EOLFilterOptions.EOL_LESS_THAN_12MO]: "EOL <12mo",
      [EOLFilterOptions.EOL_MORE_THAN_12MO]: "EOL >12mo",
    }),
    []
  );

  const renderGroupByView = (
    groupBy: string,
    searchTerm: string,
    verificationFilter: string,
    filter: EOLFilterOptions
  ) => {
    if (groupBy === GROUP_BY_IMAGES) {
      return (
        <ImagesView
          filter={filter}
          searchTerm={searchTerm}
          verifiedFilter={verificationFilter}
        />
      );
    }
  };

  return (
    <Content style={{ minWidth: 800 }}>
      <Content>
        <Helmet>
          <title>Images - Xeol</title>
        </Helmet>
        <Row style={{ marginTop: "24px" }}>
          <Col span={24}>
            <Row
              justify="space-between"
              align="middle"
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 24,
                marginRight: 24,
              }}
            >
              <Col>
                <Space direction="horizontal">
                  <SearchInput
                    placeholder={"search"}
                    onSearch={(value) => setSearchTerm(value)}
                  />
                  <ImageVerifiedFilterDropdown
                    value={imageVerifiedFilter}
                    handleSelect={setImageVerifiedfilter}
                    show={groupBy === GROUP_BY_IMAGES}
                  />
                </Space>
              </Col>
              <Col>
                <Segmented
                  options={Object.values(EOLFilterOptionMappings)}
                  onChange={(selectedText: SegmentedValue) => {
                    const selectedEnum = Object.keys(
                      EOLFilterOptionMappings
                    ).find(
                      (key) =>
                        EOLFilterOptionMappings[
                          key as keyof typeof EOLFilterOptionMappings
                        ] === selectedText
                    );
                    setFilter(selectedEnum as EOLFilterOptions);
                  }}
                />
              </Col>
            </Row>
            <Row>
              {renderGroupByView(
                groupBy,
                searchTerm,
                imageVerifiedFilter,
                filter
              )}
            </Row>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default HomePage;
