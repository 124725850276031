import React, { CSSProperties } from "react";
import { Card as AntdCard, Typography } from "antd";

const { Text } = Typography;

interface CardProps {
  width?: number;
  height?: number;
  title?: string;
  children?: React.ReactNode;
  extra?: any;
  actions?: any;
  meta?: any;
  style?: CSSProperties;
  disabled?: boolean;
}

const Card: React.FC<CardProps> = ({
  width,
  height,
  title,
  children,
  extra,
  actions,
  style,
  disabled = false,
}) => {
  const CardBodyStyle: CSSProperties = {
    margin: 24,
    borderRadius: 10,
    width: width,
    height: height,
    ...style,
  };

  return (
    <AntdCard
      style={CardBodyStyle}
      title={disabled ? <Text type="secondary">{title}</Text> : title}
      size="small"
      extra={extra}
      actions={actions}
    >
      {children}
    </AntdCard>
  );
};

export default Card;
