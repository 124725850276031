import { Layout, Result } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import React, { useState, useEffect, ReactNode } from "react";

const { Content } = Layout;

export const AUTH0_ROLES_URL = "https://auth.xeol.io/roles";

export const USER_ROLE = "user";
export const ADMIN_ROLE = "admin";
export const OWNER_ROLE = "owner";
export const READ_ONLY_ROLE = "read-only";

export type UserRoles =
  | typeof USER_ROLE
  | typeof ADMIN_ROLE
  | typeof OWNER_ROLE
  | typeof READ_ONLY_ROLE;

export type DecodedJWT = {
  "https://auth.xeol.io/roles": UserRoles[];
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  org_id: string;
};

export const checkPrivilege = (
  currentUserRole: UserRoles,
  targetUserRole: UserRoles
) => {
  if (targetUserRole == null || currentUserRole == null) {
    return false;
  }

  const hierarchy = {
    [OWNER_ROLE]: [OWNER_ROLE, ADMIN_ROLE, USER_ROLE, READ_ONLY_ROLE],
    [ADMIN_ROLE]: [ADMIN_ROLE, USER_ROLE, READ_ONLY_ROLE],
    [USER_ROLE]: [USER_ROLE],
    [READ_ONLY_ROLE]: [READ_ONLY_ROLE],
  };

  return hierarchy[currentUserRole].includes(targetUserRole);
};

interface Props {
  view: string;
  children?: ReactNode;
}

const RBACView: React.FC<Props> = ({ view, children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [userRole, setUserRole] = useState<UserRoles | null>(null);

  useEffect(() => {
    const decodeToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedJwt: DecodedJWT = jwtDecode(token);
        setUserRole(decodedJwt[AUTH0_ROLES_URL][0]);
      } catch (error) {
        console.error("error", error);
      }
    };

    decodeToken();
  }, [getAccessTokenSilently]);

  const UNAUTHORIZED_RESULT = (
    <Content
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
      }}
    >
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
      />
    </Content>
  );

  const renderView = () => {
    if (view === "settings" && userRole != OWNER_ROLE) {
      return UNAUTHORIZED_RESULT;
    } else if (
      (view === "policies" || view === "integrations") &&
      userRole === USER_ROLE
    ) {
      return UNAUTHORIZED_RESULT;
    }

    return <Content>{children}</Content>;
  };

  return renderView();
};

export default RBACView;
