import { EnumType, VariableType } from "json-to-graphql-query";
import {
  KeyValue,
  PackageNode,
  PackageNodeEolReason,
  PackageNodeOssfScore,
  PackageNodeSource,
  PackageNodeVulns,
} from "../../gql/graphql";
import { toReallyHumanReadableDate } from "../helpers";
import { projectRecompose } from "../projects";

export class DependencyQueryManager {
  private query: any;

  private type: (string | null)[] | never[];
  private namespace: string | null | undefined;
  private name: string | null | undefined;
  private cvssLevels: (string | null)[] | never[];
  private scoreLevels: (string | null)[] | never[];
  private lifecyleLevels: (string | null)[] | never[];
  private project: string | null | undefined;
  private projectSbomIDs: (string | null)[] | never[];
  private lifecycleReasons: (string | null)[] | never[];
  private vulnerability:
    | {
      label: string | undefined;
      value: string | undefined;
    }
    | null
    | undefined;
  constructor(
    query: any,
    type: (string | null)[] | never[],
    namespace: string | null | undefined,
    name: string | null | undefined,
    cvssLevels: (string | null)[] | never[],
    scoreLevels: (string | null)[] | never[],
    project: string | null | undefined,
    projectSbomIDs: (string | null)[] | never[],
    lifecyleLevels: (string | null)[] | never[],
    lifecycleReasons: (string | null)[] | never[],
    vulnerability:
      | {
        label: string | undefined;
        value: string | undefined;
      }
      | null
      | undefined
  ) {
    this.query = query;

    this.type = type;
    this.namespace = namespace;
    this.name = name;
    this.cvssLevels = cvssLevels;
    this.scoreLevels = scoreLevels;
    this.project = project;
    this.projectSbomIDs = projectSbomIDs;
    this.lifecyleLevels = lifecyleLevels;
    this.lifecycleReasons = lifecycleReasons;
    this.vulnerability = vulnerability;
  }

  private rebuildAndQuery() {
    const andConditions = [];

    if (this.type && this.type.length > 0) {
      andConditions.push({
        hasNameWith: {
          hasNamespaceWith: {
            hasTypeWith: { typeIn: this.type },
          },
        },
      });
    }

    if (this.namespace) {
      andConditions.push({
        hasNameWith: {
          hasNamespaceWith: { namespace: this.namespace },
        },
      });
    }

    if (this.name) {
      andConditions.push({
        hasNameWith: { name: this.name },
      });
    }

    if (this.project && this.projectSbomIDs) {
      andConditions.push({
        hasHasSbomWith: {
          idIn: this.projectSbomIDs,
        },
      });
    }

    if (
      (this.lifecyleLevels && this.lifecyleLevels.length > 0) ||
      (this.lifecycleReasons && this.lifecycleReasons.length > 0)
    ) {
      // Function to set time to midnight
      const setMidnight = (date: Date) => {
        date.setHours(0, 0, 0, 0);
        return date;
      };

      const currentDate = setMidnight(new Date());
      const sixMonthsLater = new Date(
        currentDate.getTime() + 6 * 30 * 24 * 60 * 60 * 1000
      );
      const oneYearLater = new Date(
        currentDate.getTime() + 365 * 24 * 60 * 60 * 1000
      );

      const LIFECYCLE_RANGES = {
        // eol
        critical: { eolDateLTE: currentDate.toISOString() },
        // eol_lt6months
        high: {
          eolDateLTE: sixMonthsLater.toISOString(),
          eolDateGT: currentDate.toISOString(),
        },
        // eol_lt12months
        medium: {
          eolDateLTE: oneYearLater.toISOString(),
          eolDateGT: currentDate.toISOString(),
        },
        // eol_gt12months
        low: {
          eolDateGT: oneYearLater.toISOString(),
        },
      };

      const lifecycleRangeConditions = this.lifecyleLevels
        .map((level) => {
          const range =
            LIFECYCLE_RANGES[level as keyof typeof LIFECYCLE_RANGES];
          if (range) {
            return {
              hasCertifyLifecycleWith: {
                hasLifecycleWith: range,
              },
            };
          }
          return null;
        })
        .filter((condition) => condition !== null);

      const lifecycleReasonConditions = this.lifecycleReasons
        .map((reason) => {
          if (!reason) return null;
          return {
            hasCertifyLifecycleWith: {
              hasLifecycleWith: {
                eolReason: new EnumType(reason),
              },
            },
          };
        })
        .filter((condition) => condition !== null);

      if (
        lifecycleRangeConditions.length > 0 ||
        lifecycleReasonConditions.length > 0
      ) {
        const lifecycleLevelCondition = {
          or: [...lifecycleRangeConditions, ...lifecycleReasonConditions],
        };
        andConditions.push(lifecycleLevelCondition);
      }
    }

    if (this.scoreLevels && this.scoreLevels.length > 0) {
      const OSSFS_SCORE_RANGES = {
        low: { aggregateScoreGT: 0.0, aggregateScoreLT: 4.0 },
        medium: { aggregateScoreGTE: 4.0, aggregateScoreLT: 7.0 },
        high: { aggregateScoreGTE: 7.0, aggregateScoreLT: 9.0 },
        superb: { aggregateScoreGTE: 9.0, aggregateScoreLTE: 10.0 },
        unknown: {},
      };

      const scoreConditions = this.scoreLevels
        .map((level) => {
          const range =
            OSSFS_SCORE_RANGES[level as keyof typeof OSSFS_SCORE_RANGES];
          if (range) {
            if (level === "unknown") {
              return {
                hasNameWith: {
                  hasHasSourceAtWith: {
                    hasSourceWith: {
                      hasScorecard: false,
                    },
                  },
                },
              };
            } else {
              return {
                hasNameWith: {
                  hasHasSourceAtWith: {
                    hasSourceWith: {
                      hasScorecardWith: range,
                    },
                  },
                },
              };
            }
          }
          return null;
        })
        .filter((condition) => condition !== null);

      if (scoreConditions.length > 0) {
        const scoreLevelCondition = { or: scoreConditions };
        andConditions.push(scoreLevelCondition);
      }
    }

    if (this.cvssLevels && this.cvssLevels.length > 0) {
      const CVSS_RANGES = {
        low: { cvssScoreGT: 0.0, cvssScoreLT: 4.0 },
        medium: { cvssScoreGTE: 4.0, cvssScoreLT: 7.0 },
        high: { cvssScoreGTE: 7.0, cvssScoreLT: 9.0 },
        critical: { cvssScoreGTE: 9.0, cvssScoreLTE: 10.0 },
        unknown: {},
      };

      const cvssConditions = this.cvssLevels
        .map((level) => {
          const range = CVSS_RANGES[level as keyof typeof CVSS_RANGES];
          if (range) {
            if (level === "unknown") {
              return {
                hasVulnsWith: {
                  cvssScoreIsNil: true,
                  vulnerabilityIDNotNil: true,
                },
              };
            } else {
              return {
                hasVulnsWith: range,
              };
            }
          }
          return null;
        })
        .filter((condition) => condition !== null);

      if (cvssConditions.length > 0) {
        const cvssLevelCondition = { or: cvssConditions };
        andConditions.push(cvssLevelCondition);
      }
    }

    if (this.vulnerability?.value) {
      andConditions.push({
        hasVulnsWith: {
          hasVulnerabilityWith: {
            id: this.vulnerability?.value ?? undefined,
          },
        },
      });
    }

    this.query.query.packageVersions.__args.where.and = andConditions;
  }

  getQuery({ ignorePagination }: { ignorePagination?: boolean } = {}) {
    this.rebuildAndQuery();

    if (!ignorePagination) {
      return this.query;
    }

    return {
      ...this.query,
      query: {
        ...this.query.query,
        __variables: {},
        packageVersions: {
          ...this.query.query.packageVersions,
          __args: {
            where: this.query.query.packageVersions.__args.where,
          },
        },
      },
    };
  }
}

export const DependenciesQueryPageSize = 25;

export const DependenciesQuery = {
  query: {
    __variables: {
      cursor: "Cursor",
    },
    packageVersions: {
      __typename: true,
      __args: {
        first: DependenciesQueryPageSize,
        after: new VariableType("cursor"),
        where: {
          and: [],
        },
      },
      totalCount: true,
      pageInfo: {
        endCursor: true,
        startCursor: true,
        hasNextPage: true,
      },
      edges: {
        node: {
          id: true,
          version: true,
          qualifiers: {
            key: true,
            value: true,
          },
          vulns: {
            vulnerability: {
              osvID: true,
            },
          },
          certifyLifecycle: {
            lifecycle: {
              eolDate: true,
              eolReason: true,
            },
          },
          inProjects: {
            name: true,
            namespace: true,
            type: true,
            owner: true,
          },
          name: {
            hasSourceAt: {
              source: {
                id: true,
                name: true,
                certifyScorecard: {
                  scorecard: {
                    aggregateScore: true,
                  },
                },
                namespace: {
                  namespace: true,
                  sourceType: {
                    type: true,
                  },
                },
              },
            },
            name: true,
            namespace: {
              namespace: true,
              type: {
                type: true,
              },
            },
          },
        },
      },
    },
  },
};

type PackageVersion = {
  id: string;
  version: string;
  hasSourceAt: null | string;
  certifyLifecycle: {
    lifecycle: { eolDate: string; eolReason: PackageNodeEolReason } | null;
  };
  qualifiers: Array<{ key: string; value: string }>;
  vulns: Array<{ vulnerability: null | PackageVulnerability }>;
  name: PackageName;
  inProjects: Array<{
    name: string;
    namespace?: string;
    type: string;
    owner: string;
  }>;
};

type PackageVulnerability = {
  id: string;
  osvID: string;
};

type CertifyScoreCard = {
  scorecard: {
    aggregateScore: number;
  };
};

type SourceInfo = {
  id: string;
  name: string;
  certifyScorecard: Array<CertifyScoreCard>;
  namespace: {
    namespace: string;
    sourceType: {
      type: string;
    };
  };
};

type PackageName = {
  name: string;
  hasSourceAt?: {
    source: SourceInfo;
  };
  namespace: {
    namespace: string;
    type: {
      type: string;
    };
  };
};

type PackageVersionsEdge = {
  node: PackageVersion;
};

type PackageVersions = {
  pageInfo: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
  };
  totalCount: number;
  edges: PackageVersionsEdge[];
};

export type DependenciesApiResponse = {
  packageVersions: PackageVersions;
};

export const transformDependenciesQuery = (
  data: DependenciesApiResponse
): (PackageNode & { projects: string[] })[] => {
  if (
    !data.packageVersions ||
    data.packageVersions?.edges.length === 0 ||
    !data.packageVersions?.edges == undefined
  ) {
    return [];
  }

  return data.packageVersions.edges.map((edge: PackageVersionsEdge) => {
    const ecosystem = edge.node.name.namespace.type.type;
    const id = edge.node.id;
    const namespace = edge.node.name.namespace.namespace;
    const packageName = edge.node.name.name;
    const version = edge.node.version;
    const qualifiers: KeyValue[] = edge.node.qualifiers
      ? edge.node.qualifiers.map(
        (qualifier) =>
        ({
          key: qualifier.key,
          value: qualifier.value,
        } as KeyValue)
      )
      : ([] as KeyValue[]);

    const source: PackageNodeSource = {
      repo: edge.node.name.hasSourceAt?.source.namespace
        ? `${edge.node.name.hasSourceAt?.source.namespace}/${edge.node.name.hasSourceAt?.source.name}`
        : "",
    };

    // Include the namespace in the name only if it's not an empty string
    const name = namespace ? `${namespace}/${packageName}` : packageName;

    const vulns: PackageNodeVulns[] = edge.node.vulns
      .filter((vuln) => vuln?.vulnerability?.osvID !== undefined) // Filter out vulns without osvID
      .map((vuln) => {
        const osvID = vuln?.vulnerability?.osvID;

        if (osvID == undefined) {
          return {} as PackageNodeVulns;
        }

        const updatedOsvID = osvID.slice(0, 4).toUpperCase() + osvID.slice(4);

        return {
          id: updatedOsvID,
          vulnID: updatedOsvID,
          link: `https://osv.dev/vulnerability/${updatedOsvID}`,
        } as PackageNodeVulns;
      });

    const score: PackageNodeOssfScore = {
      score:
        edge.node.name.hasSourceAt?.source.certifyScorecard &&
          edge.node.name.hasSourceAt.source.certifyScorecard.length > 0
          ? edge.node.name.hasSourceAt.source.certifyScorecard[0].scorecard
            .aggregateScore
          : 0,
      link: `https://securityscorecards.dev/viewer/?uri=${edge.node.name.hasSourceAt?.source.namespace}/${edge.node.name.hasSourceAt?.source.name}`,
    };

    const eol: string = toReallyHumanReadableDate(
      edge.node.certifyLifecycle?.lifecycle?.eolDate || ""
    );

    return {
      id: id,
      label: name,
      ecosystem: ecosystem,
      version: version,
      qualifiers: qualifiers,
      source: source,
      projects: edge.node.inProjects.map((project) =>
        projectRecompose({
          name: project.name,
          owner: project.owner,
          type: project.type,
          namespace: project.namespace,
        })
      ),
      attributes: {
        eol: {
          date: eol,
          reason: edge.node.certifyLifecycle?.lifecycle?.eolReason,
        },
        ossfScore: score,
        vulns: vulns,
      },
    } as PackageNode & { projects: string[] };
  });
};
