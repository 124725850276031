import { Alert, Button, Layout, Switch, message } from "antd";
import {
  DownCircleOutlined,
  DownloadOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  Card,
  Input,
  Row,
  Segmented,
  Select,
  Space,
  Tag,
  Table,
  Tooltip,
  Typography,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  ForceGraphLink,
  PackageNode,
  PackageNodeEol,
  PackageNodeOssfScore,
  PackageNodeVulns,
  ForceGraphNodesAndLinks,
  Project,
  useGetProjectSbomForceGraphQuery,
  useGetProjectSbomTargetsQuery,
  useProjectsQuery,
  ColorMap,
} from "../../gql/graphql";
import moment from "moment";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { ForceGraph2D } from "react-force-graph";
import { useLocation } from "react-router-dom";
import Loading from "../Loading";
import { truncateString, toReallyHumanReadableDate } from "../../utils/helpers";
import { PackageDrawer } from "./PackageDrawer";
import { GraphLegend, ColorOrb } from "./GraphLegend";
import {
  demoDataXeolBackendProject,
  demoDataXeolDashboardProject,
  demoDataXeolEngineProject,
  demoDataXeolFrontendProject,
} from "../demodata/Demo";
import { useAuth0 } from "@auth0/auth0-react";
import EllipsisTooltip from "../EllipsisTooltip";
import OSSFScore from "../OssfScore";
import Vulnerabilities from "../Vulnerabilities";
import { NoticeType } from "antd/es/message/interface";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import DownloadSBOMButton from "../buttons/DownloadSBOMButton";

const { Link } = Typography;
const { Content } = Layout;
const { Search } = Input;

type CustomForceGraphLink = {
  source: PackageNode;
  target: PackageNode;
};

type CustomForceGraphNodesAndLinks = {
  nodes: PackageNode[];
  links: CustomForceGraphLink[];
  colorMap: ColorMap[];
  isUpgradePathFeatureEnabled: boolean;
};

const directDepth = 0;
const indirectDepth = 20;

const ForceDirectedGraph: React.FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [dropdownState, setDropdownState] = useState({
    project: true,
    target: false,
  });

  const [projectSelectDropdown, setProjectSelectDropdown] = useState<
    SelectOptions[]
  >([]);
  const [targetSelectDropdown, setTargetSelectDropdown] = useState<
    SelectOptions[]
  >([]);

  const [packageVersionID, setPackageVersionID] = useQueryParam(
    "package_version_id",
    StringParam
  );
  const [selectedProject, setSelectedProject] = useQueryParam(
    "project",
    StringParam
  );
  const [selectedSbomID, setSelectedSbomID] = useQueryParam(
    "sbom_id",
    StringParam
  );
  const [selectedTarget, setSelectedTarget] = useQueryParam(
    "target",
    StringParam
  );
  const [graphDirect, setGraphDirect] = useQueryParam("direct", BooleanParam);
  const [targetSbomMap, setTargetSbomMap] = useState<Map<string, string>>(
    new Map()
  );

  const [nodeData, setNodeData] = useState<ForceGraphNodesAndLinks>({
    nodes: [],
    links: [],
    colorMap: [],
    isUpgradePathFeatureEnabled: false,
  });
  const [nodeCard, setNodeCard] = useState<PackageNode[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState<PackageNode | null>(null);

  const [selectedDisplaySegment, setSelectedDisplaySegment] =
    useState<string>("None");
  const [searchTerm, setSearchTerm] = useState("");
  const [hoveredNode, setHoveredNode] = useState<PackageNode | null>(null);
  const [ownersFilterOptions, setOwnersFilterOptions] =
    useState<SelectOptions[]>();
  const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
  const [depth, setDepth] = useState<number>(
    graphDirect === false ? indirectDepth : directDepth
  );
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [searchLoadingState, setSearchLoadingState] = useState(false); // TODO: remove, not that useful
  const { user } = useAuth0();

  const graphContentRef = useRef<HTMLDivElement>(null);
  const bottomContentRef = useRef<HTMLDivElement>(null);
  const FG_BG_COLOR = "#ffffff";
  const defaultProjectId = searchParams.get("project_id");
  const isDemoOrg = user && user.org_id == "org_dW8EBdJXefpIzhIl"; // xeol-demo
  const [highlightNodes, setHighlightNodes] = useState(new Set());
  const [highlightLinks, setHighlightLinks] = useState(new Set());

  // DEMO
  useEffect(() => {
    if (isDemoOrg) {
      setProjectSelectDropdown([
        {
          label: "github//xeol-io/dashboard-frontend",
          value: "github//xeol-io/dashboard-frontend",
        },
        {
          label: "github//xeol-io/dashboard-backend",
          value: "github//xeol-io/dashboard-backend",
        },
        {
          label: "github//xeol-io/xeol-backend",
          value: "github//xeol-io/xeol-backend",
        },
        {
          label: "github//xeol-io/graph-engine",
          value: "github//xeol-io/graph-engine",
        },
      ]);
    }
  }, [isDemoOrg]);

  const {
    data: projectsData,
    loading: projectsLoading,
    error: projectsError,
  } = useProjectsQuery({
    skip: isDemoOrg,
  });

  const {
    data: targetsData,
    loading: targetsLoading,
    error: targetsError,
  } = useGetProjectSbomTargetsQuery({
    variables: {
      projectName: selectedProject || "",
    },
    skip: !selectedProject || isDemoOrg,
  });

  const {
    data: graphData,
    loading: graphLoadingState,
    error: graphError,
  } = useGetProjectSbomForceGraphQuery({
    variables: {
      depth: depth,
      projectName: selectedProject || "",
      sbomID: selectedSbomID || "",
    },
    skip: !selectedProject || !selectedTarget || isDemoOrg || !selectedSbomID,
  });

  const showMessage = useCallback(
    (type: NoticeType, content: string) => {
      messageApi.open({
        type,
        content,
        duration: 1.2,
        style: {
          marginTop: "45px",
        },
      });
    },
    [messageApi]
  );

  // NOTE: we need to use two different version of the findPathToRoot function
  // because Apollo does some weird data transformations and once we set the nodeData
  // state the links are suddenly transformed into a CustomForceGraphLink[] instead of a ForceGraphLink[]
  const findPathToRootFullLinks = (
    startNodeID: string,
    links: CustomForceGraphLink[]
  ) => {
    const graph: { [key: string]: string[] } = {};

    const rootNodeID = links.find(
      (l) => !links.some((innerL) => innerL.target.id === l.source.id)
    )?.source.id;
    for (const link of links) {
      if (!graph[link.target.id]) {
        graph[link.target.id] = [];
      }
      graph[link.target.id].push(link.source.id);
    }
    const queue: [string, string[]][] = [[startNodeID, []]];
    let shortestPaths: string[][] = [];
    let shortestPathLength = Infinity;
    while (queue.length > 0) {
      const levelSize = queue.length;
      const currentLevelVisited = new Set<string>();
      for (let i = 0; i < levelSize; i++) {
        const [currentNode, path] = queue.shift()!;
        if (currentNode === rootNodeID) {
          if (path.length < shortestPathLength) {
            shortestPathLength = path.length;
            shortestPaths = [[...path, rootNodeID]];
          } else if (path.length === shortestPathLength) {
            shortestPaths.push([...path, rootNodeID]);
          }
          continue;
        }
        currentLevelVisited.add(currentNode);
        for (const neighbor of graph[currentNode] || []) {
          if (!currentLevelVisited.has(neighbor)) {
            queue.push([neighbor, [...path, currentNode]]);
          }
        }
      }
    }
    const aggregatedNodes = new Set<string>();
    const aggregatedLinks: any[] = [];
    for (const path of shortestPaths) {
      for (const node of path) {
        aggregatedNodes.add(node);
      }
      for (let i = 0; i < path.length - 1; i++) {
        const linkObj = links.find(
          (l) => l.target.id === path[i] && l.source.id === path[i + 1]
        );
        if (linkObj && !aggregatedLinks.includes(linkObj)) {
          aggregatedLinks.push(linkObj);
        }
      }
    }
    return {
      nodes: Array.from(aggregatedNodes),
      links: aggregatedLinks,
    };
  };
  // findPathToRoot uses BFS to find all paths to root that have the shortest
  // equal length. It then aggregates all nodes and links in those paths and
  // returns them.
  const findPathToRoot = (startNodeID: string, links: ForceGraphLink[]) => {
    const graph: { [key: string]: string[] } = {};

    const rootNodeID = links.find(
      (l) => !links.some((innerL) => innerL.target === l.source)
    )?.source;

    for (const link of links) {
      if (!graph[link.target]) {
        graph[link.target] = [];
      }
      graph[link.target].push(link.source);
    }

    const queue: [string, string[]][] = [[startNodeID, []]];
    let shortestPaths: string[][] = [];
    let shortestPathLength = Infinity;

    while (queue.length > 0) {
      const levelSize = queue.length;
      const currentLevelVisited = new Set<string>();

      for (let i = 0; i < levelSize; i++) {
        const [currentNode, path] = queue.shift()!;

        if (currentNode === rootNodeID) {
          if (path.length < shortestPathLength) {
            shortestPathLength = path.length;
            shortestPaths = [[...path, rootNodeID]];
          } else if (path.length === shortestPathLength) {
            shortestPaths.push([...path, rootNodeID]);
          }
          continue;
        }

        currentLevelVisited.add(currentNode);

        for (const neighbor of graph[currentNode] || []) {
          if (!currentLevelVisited.has(neighbor)) {
            queue.push([neighbor, [...path, currentNode]]);
          }
        }
      }
    }

    const aggregatedNodes = new Set<string>();
    const aggregatedLinks: any[] = [];

    for (const path of shortestPaths) {
      for (const node of path) {
        aggregatedNodes.add(node);
      }
      for (let i = 0; i < path.length - 1; i++) {
        const linkObj = links.find(
          (l) => l.target === path[i] && l.source === path[i + 1]
        );
        if (linkObj && !aggregatedLinks.includes(linkObj)) {
          aggregatedLinks.push(linkObj);
        }
      }
    }

    return {
      nodes: Array.from(aggregatedNodes),
      links: aggregatedLinks,
    };
  };

  const handleNodeClick = (node: PackageNode) => {
    const { nodes, links } = findPathToRootFullLinks(
      node.id,
      nodeData.links as unknown as CustomForceGraphLink[]
    );
    setHighlightNodes(new Set(nodes));
    setHighlightLinks(new Set(links));
    const nodeObjects = nodeData.nodes.filter((n) => nodes.includes(n.id));
    setNodeCard(nodeObjects.reverse());
  };

  // handle: user is finished selection, load graph data
  useEffect(() => {
    if (graphData) {
      const graph = graphData.getProjectSbomForceGraph;
      const globalNodes = graph.nodes.map((item: any) =>
        Object.assign({}, item)
      );
      const globalLinks = graph.links.map((item: any) =>
        Object.assign({}, item)
      );
      const colorMap = graph.colorMap.map((item: any) =>
        Object.assign({}, item)
      );
      const isUpgradePathFeatureEnabled = graph.isUpgradePathFeatureEnabled;
      const nData = {
        nodes: globalNodes as PackageNode[],
        links: globalLinks as ForceGraphLink[],
        colorMap: colorMap as ColorMap[],
        isUpgradePathFeatureEnabled,
      };
      setNodeData(nData);

      if (packageVersionID) {
        const targetNode = graph.nodes.find(
          (node: PackageNode) => node.id === packageVersionID
        );
        if (targetNode) {
          const { nodes: ns, links: ls } = findPathToRoot(
            targetNode.id,
            nData.links
          );
          setHighlightNodes(new Set(ns));
          setHighlightLinks(new Set(ls));
          const nodeObjects = graph.nodes.filter((n: any) => ns.includes(n.id));
          setNodeCard(nodeObjects.reverse());
        }
      } else {
        setNodeCard(graph.nodes);
        setHighlightNodes(new Set());
        setHighlightLinks(new Set());
      }

      if (graph.nodes.length < 2) {
        showMessage(
          "warning",
          "No nodes found for this project and target. Please try another."
        );
      }
    }
    if (graphError) {
      console.log(graphError);
      showMessage(
        "error",
        "Couldn't load graph data for this project and target"
      );
    }
  }, [graphData, graphError, showMessage, packageVersionID]);

  // handle: result from query after user selected a project
  // in dropdown
  useEffect(() => {
    if (targetsData) {
      const newTargetSbomMap = new Map<string, string>();
      targetsData.getProjectSbomTargets
        .filter((target) => target !== null)
        .forEach((target) => {
          newTargetSbomMap.set(target?.targetName, target?.sbomID);
        });
      setTargetSbomMap(newTargetSbomMap);

      // handle intital selection
      const firstEntry = newTargetSbomMap.entries().next().value;
      const selectOptions = targetsData.getProjectSbomTargets
        .filter((target) => target !== null)
        .map((target) => ({
          label: target?.targetName,
          value: target?.targetName,
        })) as SelectOptions[];
      setTargetSelectDropdown(selectOptions);
      setSelectedSbomID(newTargetSbomMap.get(firstEntry[0]));
      setDropdownState({
        project: true,
        target: true,
      });

      // only set a default target if there is no target in the url
      if (!selectedTarget) {
        setSelectedTarget(firstEntry[0]);
      }
    }
    if (targetsError) {
      showMessage("error", "Couldn't load targets for this project");
    }
  }, [targetsData, targetsError, selectedProject, showMessage]);

  const handleSelectTarget = async (e: string) => {
    setSelectedTarget(e);
    setSelectedSbomID(targetSbomMap.get(e) || "");
  };

  // handle: page is loaded, get projects
  useEffect(() => {
    const convertProjectToProjectName = (project: Project) => {
      const { type, owner, namespace, name } = project;
      return namespace
        ? `${type}//${owner}/${namespace}/${name}`
        : `${type}//${owner}/${name}`;
    };
    const extractProjects = (projects: Array<Project>) => {
      return projects.map((project: Project) => ({
        value: convertProjectToProjectName(project),
        label: convertProjectToProjectName(project),
      }));
    };

    // TODO: set page loading if projectsLoading is true
    if (projectsData) {
      const projects = extractProjects(
        projectsData.projects as Array<Project>
      ) as SelectOptions[];
      setProjectSelectDropdown(projects);
    }
    if (projectsError) {
      showMessage("error", "Couldn't load projects");
    }
  }, [projectsData, projectsError, showMessage]);

  type SelectOptions = {
    label: string;
    value: string;
  };

  const ContentStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  } as React.CSSProperties;

  const searchNodeByTerm = (node: PackageNode, term: string): boolean => {
    // Check if label includes the search term
    if (node.label.toLowerCase().includes(term.toLowerCase())) {
      return true;
    }

    // Check if the version includes the search term
    else if (node.version && node.version.includes(term)) {
      return true;
    }

    if (!node.attributes || !node.attributes.vulns) {
      return false;
    }

    // Check if any vulns' id or link includes the search term
    if (node.attributes.vulns.some((vuln) => vuln.id.includes(term))) {
      return true;
    }
    // // Check if eol date or link includes the search term
    // else if (node.attributes.eol && (node.attributes.eol.date.includes(term))) {
    //   return true;
    // }
    // // Check if ossfScore or link includes the search term
    // else if (node.attributes.ossfScore && (node.attributes.ossfScore.score.includes(term))) {
    //   return true;
    // }

    // Check if any owner includes the search term
    // else if (
    //   node.source &&
    //   node.source.owners &&
    //   node.source.owners.some((owner) => owner && owner.includes(term))
    // ) {
    //   return true;
    // }

    return false;
  };

  // DEMO
  const renderDemoGraphs = (project: string) => {
    if (project === "github//xeol-io/dashboard-backend") {
      setNodeData(demoDataXeolDashboardProject);
      setNodeCard(demoDataXeolDashboardProject.nodes);
    } else if (project === "github//xeol-io/xeol-backend") {
      setNodeData(demoDataXeolBackendProject);
      setNodeCard(demoDataXeolBackendProject.nodes);
    } else if (project === "github//xeol-io/graph-engine") {
      setNodeData(demoDataXeolEngineProject);
      setNodeCard(demoDataXeolEngineProject.nodes);
    } else if (project == "github//xeol-io/dashboard-frontend") {
      setNodeData(demoDataXeolFrontendProject);
      setNodeCard(demoDataXeolFrontendProject.nodes);
    }
  };

  // DEMO
  const returnDemoTarget = (project: string): string => {
    if (project === "github//xeol-io/xeol-dashboard") {
      return "docker.io/library/xeol-dashboard";
    } else if (project === "github//xeol-io/xeol-backend") {
      return "docker.io/library/xeol-backend";
    } else if (project === "github//xeol-io/graph-engine") {
      return "docker.io/library/graph-engine";
    } else if (project == "github//xeol-io/dashboard-frontend") {
      return "docker.io/library/xeol-dashboard-frontend";
    }
    return "";
  };

  const renderTopBar = () => {
    const TopRowStyle = {
      display: "flex",
      marginTop: "5px",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    } as React.CSSProperties;

    const handleDisplaySegmentChange = (selected: any) => {
      if (selected == "None") {
        setSelectedDisplaySegment("None");
      } else if (selected == "Version") {
        setSelectedDisplaySegment("Version");
      } else if (selected == "Score") {
        setSelectedDisplaySegment("Score");
      } else if (selected == "EOL") {
        setSelectedDisplaySegment("EOL");
      }
    };

    const handleSelectProject = (value: string) => {
      // DEMO
      setHighlightLinks(new Set());
      setHighlightNodes(new Set());

      if (isDemoOrg) {
        renderDemoGraphs(value);
        const target = returnDemoTarget(value);
        setSelectedTarget(target);
        setSelectedProject(value);
        return;
      }

      setSelectedProject(value);
      setSelectedTarget(undefined);
      setSelectedSbomID(undefined);
      setPackageVersionID(undefined);
    };

    const handleSearch = (value: string) => {
      if (value === "") {
        setNodeCard(nodeData.nodes);
      } else {
        setHighlightLinks(new Set());
        setHighlightNodes(new Set());
        setNodeCard((prevNodes) =>
          prevNodes.filter((node) => searchNodeByTerm(node, value))
        );
      }
      setSearchLoadingState(true);
      setSearchTerm(value);
      setSearchLoadingState(false);
    };

    return (
      <Row style={TopRowStyle}>
        {contextHolder}
        <Space>
          <Select
            showSearch
            disabled={!dropdownState["project"]}
            style={{ width: "15vw" }}
            loading={projectsLoading}
            placeholder="Choose project"
            optionFilterProp="children"
            onChange={handleSelectProject}
            value={selectedProject}
            filterOption={(input, option) =>
              (option?.label.toLocaleLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={projectSelectDropdown}
            popupMatchSelectWidth={600}
            suffixIcon={<DownCircleOutlined />}
          />
          <Select
            showSearch
            disabled={!dropdownState["target"]}
            style={{ width: "15vw" }}
            loading={targetsLoading}
            placeholder="Choose target"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label.toLocaleLowerCase() ?? "").includes(
                input.toLowerCase()
              )
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onChange={handleSelectTarget}
            value={selectedTarget}
            options={targetSelectDropdown}
            popupMatchSelectWidth={600}
            suffixIcon={<DownCircleOutlined />}
          />
          <Search
            style={{ minWidth: "20vw" }}
            placeholder="search your software supply chain"
            onSearch={handleSearch}
            loading={searchLoadingState}
            allowClear
          />
          <Segmented
            options={["None", "Version", "Score", "EOL"]}
            onChange={handleDisplaySegmentChange}
          />
          <Switch
            checkedChildren="Indirect"
            unCheckedChildren="Direct"
            defaultChecked={graphDirect === false}
            onChange={(checked: boolean) => {
              setGraphDirect(!checked);
              setDepth(checked ? indirectDepth : directDepth);
            }}
          />
        </Space>
      </Row>
    );
  };

  const getNodeColor = (node: PackageNode): string => {
    if (highlightNodes.size === 0) {
      if (searchTerm === "") {
        return node.color;
      } else if (searchNodeByTerm(node, searchTerm)) {
        return "#eb2f96";
      } else {
        return "#bfbfbf";
      }
    } else {
      return highlightNodes.has(node.id)
        ? node.color
        : "rgba(210, 210, 210, 0.5)";
    }
  };

  const getNodeLabel = (node: any) => {
    if (selectedDisplaySegment == "Version") {
      return node.version ? node.label + " @" + node.version : node.label;
    } else if (selectedDisplaySegment === "Score") {
      return node.attributes.ossfScore
        ? node.label + " (" + node.attributes.ossfScore.score + ")"
        : node.label;
    } else if (selectedDisplaySegment === "EOL") {
      return node.attributes.eol
        ? node.label + " (" + node.attributes.eol.date + ")"
        : node.label;
    } else {
      return node.label;
    }
  };

  const nodeCanvasObject = (node: any, ctx: any, globalScale: any) => {
    let fontSize = 2;
    let nodeRadius = 2;

    if (
      (searchTerm !== "" && searchNodeByTerm(node, searchTerm)) ||
      (hoveredNode && hoveredNode.id === node.id)
    ) {
      fontSize = 16 / globalScale;
      nodeRadius = 4;
    }

    // draw text
    const label = getNodeLabel(node);
    ctx.font = `${fontSize}px Sans-Serif`;
    const textWidth = ctx.measureText(label).width;
    const bckgDimensions: [number, number] = [textWidth, fontSize].map(
      (n) => n + fontSize * 0.2
    ) as [number, number];

    if (node.x === undefined || node.y === undefined) return;

    ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
    ctx.fillRect(
      node.x - bckgDimensions[0] / 2,
      node.y - bckgDimensions[1] / 2 + 4,
      ...bckgDimensions
    );

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "rgb(80, 80, 80)"; // Even lighter black
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(label, node.x, node.y + 4);

    // draw node
    ctx.beginPath();
    ctx.arc(node.x, node.y, nodeRadius, 0, 2 * Math.PI, false);
    ctx.fillStyle = getNodeColor(node);
    ctx.fill();

    node.__bckgDimensions = bckgDimensions;
  };

  const handleNodeHover = (node: any, previousNode: any) => {
    if (node) {
      setHoveredNode(node);
    } else {
      setHoveredNode(null);
    }
  };

  const handleBackgroundClick = () => {
    setNodeCard(nodeData.nodes);
    setHighlightNodes(new Set());
    setHighlightLinks(new Set());
    // setSelectedOwners(
    //   generateOwnersFilterOptions(nodeData.nodes).map(
    //     (option) => option.value
    //   )
    // );
  };

  const renderBottomCard = (nodeCard: PackageNode[]) => {
    const BottomCardStyle = {
      height: "100%",
      width: "100%",
    } as React.CSSProperties;

    const renderName = (node: PackageNode) => {
      if (!node.label) {
        return "-";
      }
      return node.source && node.source.repo ? (
        <>
          <EllipsisTooltip title={node.label}>
            <ColorOrb color={node.color} inline={true} />
            <Link
              className="light-blue-link"
              href={node.source.repo}
              target="_blank"
              rel="noopener noreferrer"
            >
              {node.label}
            </Link>
          </EllipsisTooltip>
        </>
      ) : (
        <>
          <EllipsisTooltip title={node.label}>
            <ColorOrb color={node.color} inline={true} />
            {node.label}
          </EllipsisTooltip>
        </>
      );
    };

    const renderVersion = (version: string) => {
      if (!version) {
        return "-";
      }

      if (version.length > 8) {
        return (
          <Tooltip title={version}>
            <Tag>{truncateString(version, 8)}</Tag>
          </Tooltip>
        );
      }

      return <Tag>{version}</Tag>;
    };

    const renderEOLDate = (eol: PackageNodeEol) => {
      if (!eol || !eol.date) {
        return "-";
      }
      return eol.link !== "" ? (
        <Link
          className="light-blue-link"
          href={eol.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tag color="blue">
            <LinkOutlined /> {eol.date}
          </Tag>
        </Link>
      ) : (
        toReallyHumanReadableDate(eol.date)
      );
    };

    // const handleOwnersSelect = (value: string[]) => {
    //   const filterNodesByOwners = (
    //     nodes: PackageNode[],
    //     owners: string[]
    //   ): PackageNode[] => {
    //     return nodes.filter((node) => {
    //       if (!node.source || !node.source.owners) {
    //         return true;
    //       }
    //       return node.source.owners.some(
    //         (owner) => owner && owners.includes(owner)
    //       );
    //     });
    //   };
    //   setSelectedOwners(value);
    //   setNodeCard(filterNodesByOwners(nodeData.nodes, value));
    // };

    const columns: ColumnsType<PackageNode> = [
      {
        title: "Name",
        dataIndex: "",
        key: "name",
        width: "40%",
        render: renderName,
        ellipsis: true,
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        width: "10%",
        render: renderVersion,
      },
      {
        title: "Score",
        dataIndex: ["attributes", "ossfScore"],
        key: "score",
        width: "10%",
        sortDirections: ["ascend", "descend"],
        sorter: (a, b) => {
          const scoreA = a.attributes?.ossfScore?.score ?? 11;
          const scoreB = b.attributes?.ossfScore?.score ?? 11;
          return scoreA - scoreB;
        },
        render: (score: PackageNodeOssfScore) => {
          return <OSSFScore score={score} />;
        },
      },
      {
        title: "EOL",
        dataIndex: ["attributes", "eol"],
        key: "eol",
        width: "15%",
        sorter: (a: PackageNode, b: PackageNode) => {
          const eolA = a.attributes?.eol?.date;
          const eolB = b.attributes?.eol?.date;

          const timestampA = eolA ? new Date(eolA).getTime() : 0;
          const timestampB = eolB ? new Date(eolB).getTime() : 0;

          return timestampA - timestampB;
        },
        render: renderEOLDate,
      },
      {
        title: "Vulnerabilities",
        dataIndex: ["attributes", "vulns"],
        key: "vulns",
        width: "25%",
        sorter: (a: PackageNode, b: PackageNode) => {
          const vulnsA = a.attributes?.vulns?.length ?? 0;
          const vulnsB = b.attributes?.vulns?.length ?? 0;
          return vulnsA - vulnsB;
        },
        render: (vulns: PackageNodeVulns[]) => (
          <Vulnerabilities vulns={vulns} />
        ),
      },
    ];

    return (
      <Card style={BottomCardStyle} actions={[]}>
        {selectedSbomID && !graphLoadingState ? (
          <DownloadSBOMButton sbomID={selectedSbomID} />
        ) : (
          <DownloadSBOMButton disabled />
        )}
        <Space style={{ width: "100%" }} direction="vertical">
          {/* <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Filter by package owner"
            value={selectedOwners}
            onChange={handleOwnersSelect}
            options={ownersFilterOptions}
          /> */}
          <Table
            columns={columns}
            dataSource={nodeCard.map((item, index) => ({
              key: index,
              ...item,
            }))}
            key="id"
            virtual={true}
            scroll={{ y: dimensions.height / 1.3, x: 100 }}
            pagination={false}
            onRow={(record, _) => {
              return {
                onClick: () => {
                  setSelectedNode(record);
                  setIsDrawerVisible(true);
                },
              };
            }}
          />
          <PackageDrawer
            isDrawerVisible={isDrawerVisible}
            setIsDrawerVisible={setIsDrawerVisible}
            selectedSbomID={selectedSbomID || ""}
            selectedNode={selectedNode}
            upgradePathFeatureEnabled={nodeData.isUpgradePathFeatureEnabled}
            setSelectedNode={setSelectedNode}
            selectedProject={selectedProject || ""}
          />
        </Space>
      </Card>
    );
  };

  useEffect(() => {
    const updateGraphContentDimensions = () => {
      if (graphContentRef.current) {
        const { width, height } =
          graphContentRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    updateGraphContentDimensions();
    window.addEventListener("resize", updateGraphContentDimensions);
    return () =>
      window.removeEventListener("resize", updateGraphContentDimensions);
  }, [graphContentRef]);

  return (
    <Content key="graph-view" style={ContentStyle}>
      {renderTopBar()}
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: 1,
        }}
      >
        <GraphLegend colorMap={nodeData.colorMap} />
      </div>
      <Row ref={graphContentRef} style={{ height: "47vh" }}>
        {graphLoadingState ? (
          <Loading view={false} />
        ) : (
          <ForceGraph2D
            width={dimensions.width}
            height={dimensions.height}
            backgroundColor={FG_BG_COLOR}
            nodeRelSize={2}
            nodeLabel={() => ""}
            nodeCanvasObject={nodeCanvasObject}
            nodeColor={getNodeColor}
            nodeCanvasObjectMode={() => "before"}
            onNodeHover={handleNodeHover}
            linkDirectionalArrowLength={3}
            linkDirectionalArrowRelPos={1}
            linkDirectionalParticles={0}
            linkColor={(link) =>
              highlightLinks.has(link)
                ? "rgba(90, 90, 90, 0.8)"
                : "rgba(140, 140, 140, 0.5)"
            }
            linkWidth={(link) => (highlightLinks.has(link) ? 2 : 0.5)}
            linkDirectionalParticleWidth={(link) =>
              highlightLinks.has(link) ? 4 : 1
            }
            onNodeClick={handleNodeClick}
            onBackgroundClick={handleBackgroundClick}
            graphData={nodeData}
          />
        )}
      </Row>
      <Row ref={bottomContentRef} style={{ height: "47vh" }}>
        {renderBottomCard(nodeCard)}
      </Row>
    </Content>
  );
};

export default ForceDirectedGraph;
