import React, { useState } from "react";
import { PackageNode, ProjectResponse } from "../gql/graphql";
import { Input, Select, Space, Table, Tooltip } from "antd";
import { toHumanReadableDateTime } from "../utils/helpers";
import GraphProjectButton from "./buttons/GraphProjectButton";
import EllipsisTooltip from "./EllipsisTooltip";
import DownloadSBOMButton from "./buttons/DownloadSBOMButton";
const { Option } = Select;

interface Props {
  projects: ProjectResponse[];
  pkg: PackageNode;
}

const PackageProjects: React.FC<Props> = ({ projects, pkg }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const flattenedData = projects.flatMap((project: ProjectResponse) => {
    if (!project.commits || !project.commits.length) {
      return [];
    }

    return project.commits.map((commit) => ({
      key: `${project.name}-${commit.hash}`,
      name: project.name,
      commit: commit.hash,
      target: commit.target,
      createdAt: commit.createdAt,
      sbomID: commit.sbomID,
    }));
  });

  const columns = [
    {
      title: "Project",
      dataIndex: "name",
      key: "project",
      width: "40%",
    },
    {
      title: "Target",
      dataIndex: "target",
      key: "target",
      width: "10%",
    },
    {
      title: "Commit",
      dataIndex: "commit",
      key: "commit",
      width: "25%",
      ellipsis: true,
      render: (
        commit: string,
        record: { sbomID: string; name: string; target: string }
      ) => (
        <div>
          <div
            style={{
              marginLeft: "6px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Tooltip title={commit}>{commit}</Tooltip>
          </div>
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <GraphProjectButton
              sbomID={record.sbomID}
              project={record.name}
              target={record.target}
              package_version_id={pkg.id}
            />
            <span style={{ marginLeft: "4px" }}>
              <DownloadSBOMButton sbomID={record.sbomID} />
            </span>
          </div>
        </div>
      ),
    },
    {
      title: "Scanned",
      dataIndex: "createdAt",
      key: "scanned",
      width: "20%",
      render: (date: string) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              marginLeft: "6px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <Tooltip title={toHumanReadableDateTime(date)}>
              {toHumanReadableDateTime(date)}
            </Tooltip>
          </div>
        </div>
      ),
      defaultSortOrder: "descend" as const,
      sorter: (a: { createdAt: string }, b: { createdAt: string }) => {
        const timestampA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
        const timestampB = b.createdAt ? new Date(b.createdAt).getTime() : 0;

        return timestampA - timestampB;
      },
    },
  ];

  const handleSearch = (e: any) => {
    setSearchText(e.target.value);
    setCurrentPage(1);
  };

  const handleProjectSelect = (value: any) => {
    setSelectedProject(value);
    setCurrentPage(1);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const projectOptions = Array.from(
    new Set(projects.map((data) => data.name))
  ).map((project) => (
    <Option key={project} value={project}>
      {project}
    </Option>
  ));

  const filteredData = flattenedData.filter((item) => {
    return (
      (selectedProject ? item.name === selectedProject : true) &&
      item.commit.includes(searchText)
    );
  });

  return (
    <div style={{ paddingTop: 24, width: "100%" }}>
      <Space direction="vertical" size="large">
        <Space size="middle">
          <Input.Search
            placeholder="commit hash"
            onChange={handleSearch}
            style={{ width: 300 }}
          />
          <Select
            allowClear
            showSearch
            placeholder="Select Project"
            onChange={handleProjectSelect}
            style={{ width: 450 }}
          >
            {projectOptions}
          </Select>
        </Space>
        <Table
          dataSource={filteredData}
          columns={columns}
          pagination={{
            position: ["bottomCenter"],
            hideOnSinglePage: true,
            current: currentPage,
            pageSize: 25,
            showSizeChanger: false,
            total: filteredData.length,
            onChange: handlePaginationChange,
          }}
          tableLayout="fixed"
          style={{ width: "100%" }}
          size="small"
        />
      </Space>
    </div>
  );
};

export default PackageProjects;
