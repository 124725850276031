import { Alert, Col, Row, Timeline, Typography, Spin } from "antd";
import {
  CompletePackageResponse,
  PackageNode,
  GetPackageUpgradePathByConstraintsQuery,
  GetPackageUpgradePathQuery,
} from "../../gql/graphql";
import React from "react";
import TruncatedText from "../TruncatedText";
import { UpCircleOutlined, EllipsisOutlined } from "@ant-design/icons";

interface UpgradePathProps {
  upgradePathData: CompletePackageResponse | undefined;
  upgradePathLoading: boolean | undefined;
  upgradePathError: any;
  pkg: PackageNode;
}

const { Text } = Typography;

const textWidth = 442;

export const UpgradePathTimeline: React.FC<UpgradePathProps> = ({
  upgradePathData,
  upgradePathLoading,
  upgradePathError,
  pkg,
}) => {
  const joinNameAndNamespace = (name: string, namespace: string): string => {
    if (namespace === "") {
      return name;
    }
    return `${namespace}/${name}`;
  };

  if (upgradePathLoading) {
    return (
      <div className="spin-container-box">
        <Spin />
      </div>
    );
  }

  if (upgradePathError != undefined) {
    if (pkg.attributes?.pkgType?.toLowerCase() == "nuget") {
      return (
        <Alert
          style={{ marginTop: "24px" }}
          message="Upgrade Path Not Found"
          description={
            <p>
              {`We couldn't find any direct dependencies that require ${pkg.label} to be this version. You could try pinning this transitive dependency using `}
              <a href="https://devblogs.microsoft.com/nuget/introducing-central-package-management/">
                transitive dependency pinning with NuGet.
              </a>
            </p>
          }
          type="info"
        />
      );
    }

    return (
      <Alert
        style={{ marginTop: "24px" }}
        message="Upgrade Path Not Found"
        // description={`We couldn't find any direct dependencies where the version of the package ${pkg.label} >= v${selectedDropdownVersion}.`}
        type="info"
      />
    );
  }

  const isTopLevelPackage =
    pkg?.label ===
    joinNameAndNamespace(
      upgradePathData?.name ?? "",
      upgradePathData?.namespace ?? ""
    );

  if (isTopLevelPackage && upgradePathData) {
    return (
      <div style={{ marginTop: "24px" }}>
        <Text>This is a top-level package, you can upgrade it directly.</Text>
        <Row style={{ marginTop: "12px" }}>
          <Col span={12}>
            <TruncatedText
              label={joinNameAndNamespace(
                upgradePathData.name,
                upgradePathData.namespace
              )}
              columnLength={textWidth}
            />
          </Col>
          <Col span={3}>
            <Text>{upgradePathData.currentVersion}</Text>
          </Col>
          <Col span={1}>
            <UpCircleOutlined style={{ color: "#52c41a" }} />
          </Col>
          <Col span={3}>
            <Text strong>{upgradePathData.cleanVersion}</Text>
          </Col>
        </Row>
      </div>
    );
  }
  if (upgradePathData) {
    return (
      <Timeline
        style={{ marginTop: "24px" }}
        items={[
          {
            color: "red",
            children: (
              <Row>
                <Col span={12}>
                  <TruncatedText label={pkg.label} columnLength={textWidth} />
                </Col>
                <Col span={3}>
                  <Text>{pkg.version}</Text>
                </Col>
                <Col span={1}>
                  <UpCircleOutlined style={{ color: "#52c41a" }} />
                </Col>
                <Col span={3}>
                  <Text>
                    {upgradePathData.depNoLongerExists
                      ? "[removed]"
                      : upgradePathData.depCleanVersion}
                  </Text>
                </Col>
              </Row>
            ),
          },
          {
            color: "gray",
            children: <EllipsisOutlined />,
          },
          {
            color: "red",
            children: (
              <Row>
                <Col span={12}>
                  <TruncatedText
                    label={joinNameAndNamespace(
                      upgradePathData.name,
                      upgradePathData.namespace
                    )}
                    columnLength={textWidth}
                  />
                </Col>
                <Col span={3}>
                  <Text>{upgradePathData.currentVersion}</Text>
                </Col>
                <Col span={1}>
                  <UpCircleOutlined style={{ color: "#52c41a" }} />
                </Col>
                <Col span={3}>
                  <Text strong>{upgradePathData.cleanVersion}</Text>
                </Col>
              </Row>
            ),
          },
        ]}
      />
    );
  }

  return <></>;
};
