import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import posthog from "posthog-js";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
});

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN!}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      scope: "openid profile email read:organization_members",
    }}
    // useRefreshTokensFallback is required so that we don't get missing_refresh_token errors
    // See https://github.com/auth0/auth0-spa-js/blob/master/FAQ.md#why-am-i-getting-a-missing_refresh_token-error-after-upgrading-to-v2
    useRefreshTokensFallback={true}
    // useRefreshTokens and cacheLocation are required to support silent authentication
    // for browsers with Intelligent Tracking Prevention (ITP) enabled like
    // Safari and Brave. See https://github.com/auth0/auth0-react/issues/101
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <React.StrictMode>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <App />
        </QueryParamProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
