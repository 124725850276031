import { Button, Input, Layout, message, Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

const { TextArea } = Input;
const { Content } = Layout;
const { Text } = Typography;

interface Props {
  style: any;
  disabled: boolean;
  rows?: number;
  value?: string;
  onChange?: (value: string) => void;
}

const NO_STATUS = "";
const WARNING_STATUS = "warning";
const ERROR_STATUS = "error";

type Status = typeof NO_STATUS | typeof WARNING_STATUS | typeof ERROR_STATUS;

const JSONTextArea: React.FC<Props> = ({ style, disabled, rows = 6, value, onChange }) => {
  const [jsonInput, setJsonInput] = useState("");
  const [status, setStatus] = useState<Status>(NO_STATUS);

  const [messageApi, contextHolder] = message.useMessage();

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setJsonInput(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const formatJson = () => {
    try {
      const parsed = JSON.parse(jsonInput);
      const formatted = JSON.stringify(parsed, null, 2);
      setJsonInput(formatted);
      setStatus(NO_STATUS);
    } catch (error: any) {
      message.error(error.toString());
      setStatus(ERROR_STATUS);
    }
  };

  return (
    <Content style={{ position: "relative" }}>
      {contextHolder}
      <TextArea
        style={style}
        rows={rows}
        value={value || jsonInput}
        placeholder="Paste your validation JSON here"
        onChange={handleTextAreaChange}
        disabled={disabled}
        status={status}
      />
      <Button
        style={{
          position: "absolute",
          bottom: 10,
          right: 10
        }}
        size="small"
        onClick={formatJson}><CodeOutlined /> Format</Button>
    </Content >
  );
};

export default JSONTextArea;
