import {
  Avatar,
  Button,
  Col,
  Empty,
  Layout,
  List,
  Pagination,
  Popover,
  Row,
  Select,
  AutoComplete,
  Tag,
  Tooltip,
} from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { parseISO } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SortButton, { SORT_ASC } from "./buttons/SortButton";
import TruncatedText from "./TruncatedText";
import Loading from "../components/Loading";
import {
  DefaultService,
  EOLFilterOptions,
  ProjectImage,
  OpenAPI,
  Page_ProjectImage_,
  Operator,
  LabelFilter,
  DynamicKeysModel,
} from "../client";
import { ImageDrawer, renderLabelsList } from "./graph/ImageDrawer";
import { renderSoftwareIcon } from "../utils/iconRenderer";
import { renderSignatureTag } from "../utils/TagRenderer";
import { SearchOutlined } from "@ant-design/icons";

const { Content } = Layout;

interface Props {
  filter: EOLFilterOptions;
  searchTerm: string;
  verifiedFilter?: string;
}

const pageSize = 25;

type SelectOptions = {
  label: string;
  value: string;
};

const ImagesView: React.FC<Props> = ({
  filter,
  searchTerm,
  verifiedFilter,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const API_URL = process.env.REACT_APP_API_URL;
  const imageNameColRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);

  const [imagesList, setImagesList] = useState<ProjectImage[]>([]);
  const [imageNameColLength, setImageNameColLength] = useState(
    window.innerWidth * 0.6
  );
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<ProjectImage>();
  const [selectedProjectName, setSelectedProjectName] = useState<string>();
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [labelFilter, setLabelFilter] = useState<LabelFilter>();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [labelKeys, setLabelKeys] = useState<SelectOptions[]>([]);
  const [labelValues, setLabelValues] = useState<SelectOptions[]>([]);
  const [keysDict, setKeysDict] = useState<Record<string, Array<string>>>();

  const handleOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  const handleSort = (event: any, sortOrder = SORT_ASC) => {
    const sortByImageName = (
      images: ProjectImage[],
      sortOrder = SORT_ASC
    ): ProjectImage[] => {
      return [...images].sort((a, b) => {
        if (sortOrder === SORT_ASC) {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
    };

    const sortByImageVerifiedAndDate = (
      images: ProjectImage[],
      sortOrder = SORT_ASC
    ): ProjectImage[] => {
      return [...images].sort((a, b) => {
        if (a.imageVerified !== b.imageVerified) {
          return sortOrder === SORT_ASC
            ? Number(b.imageVerified) - Number(a.imageVerified)
            : Number(a.imageVerified) - Number(b.imageVerified);
        }

        const aDate = a.latestScanDate
          ? parseISO(a.latestScanDate).getTime()
          : -Infinity;
        const bDate = b.latestScanDate
          ? parseISO(b.latestScanDate).getTime()
          : -Infinity;

        return sortOrder === SORT_ASC ? aDate - bDate : bDate - aDate;
      });
    };

    switch (event.currentTarget.getAttribute("data-key")) {
      case "sort-images-btn":
        setImagesList(sortByImageName(imagesList, sortOrder));
        break;
      case "sort-verified-btn":
        setImagesList(sortByImageVerifiedAndDate(imagesList, sortOrder));
        break;
      default:
        break;
    }
  };

  const imageNameColWidth = 10;
  const imageTagColWidth = 4;
  const imageVerifiedColWidth = 5;
  const imageLabelsColWidth = 5;

  const fetchImageLabels = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      OpenAPI.BASE = API_URL!;
      OpenAPI.HEADERS = {
        Authorization: `Bearer ${token}`,
      };
      await DefaultService.getImageLabelsImageLabelsGet()
        .then((response: DynamicKeysModel) => {
          setKeysDict(response.labels);

          const labelKeys: SelectOptions[] = Object.keys(response.labels).map(
            (key) => ({
              value: key,
              label: key,
            })
          );
          setLabelKeys(labelKeys);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, [API_URL, getAccessTokenSilently]);

  const fetchImages = useCallback(
    async ({
      page = 1,
      size = 25,
      search,
      verified,
      filter,
      requestBody,
    }: {
      page: number;
      size: number;
      search?: string | undefined;
      filter?: EOLFilterOptions;
      verified: string | undefined;
      requestBody: LabelFilter | undefined;
    }) => {
      setLoading(true);
      try {
        const token = await getAccessTokenSilently();
        OpenAPI.BASE = API_URL!;
        OpenAPI.HEADERS = {
          Authorization: `Bearer ${token}`,
        };
        await DefaultService.getImagesImagesPost(
          search,
          verified,
          filter,
          page,
          size,
          requestBody
        ).then((response: Page_ProjectImage_) => {
          setImagesList(response.items);
          setPage(response.page!);
          setNumPages(response.pages!);
          setTotal(response.total!);
        });
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    [API_URL, getAccessTokenSilently, setLoading]
  );

  useEffect(() => {
    fetchImages({
      page: 1,
      size: pageSize,
      search: searchTerm,
      filter: filter,
      verified: verifiedFilter,
      requestBody: labelFilter,
    });
    fetchImageLabels();
  }, [
    searchTerm,
    verifiedFilter,
    filter,
    labelFilter,
    fetchImages,
    fetchImageLabels,
  ]);

  const [key, setKey] = useState<string | undefined>(undefined);
  const [operator, setOperator] = useState(undefined);
  const [value, setValue] = useState(null);

  const handleUpdateFilter = () => {
    if (key && operator && value) {
      const op = operator === "in" ? Operator.IN : Operator.NOT_IN;
      setLabelFilter({ key, operator: op, value });
    }
  };

  return (
    <Content
      style={{ marginTop: "20px", paddingLeft: "2%", paddingRight: "2%" }}
    >
      {loading ? (
        <Loading view={false} />
      ) : (
        <>
          <Content>
            <Row align="middle">
              <Col span={imageNameColWidth}>
                <SortButton
                  dataKey="sort-images-btn"
                  label="Images"
                  onClick={handleSort}
                />
              </Col>
              <Col span={imageTagColWidth}>
                <SortButton
                  dataKey="sort-tags-btn"
                  label="Tag"
                  onClick={handleSort}
                  disabled={true}
                />
              </Col>
              <Col span={imageVerifiedColWidth}>
                <SortButton
                  dataKey="sort-verified-btn"
                  label="Verified"
                  onClick={handleSort}
                />
              </Col>
              <Col span={imageLabelsColWidth}>
                <SortButton
                  dataKey="sort-labels-btn"
                  label="Labels"
                  onClick={handleSort}
                  disabled={true}
                />
                <Popover
                  content={
                    <div style={{ padding: 8 }}>
                      <div style={{ paddingBottom: 8 }}>
                        <strong>Filter by label</strong>
                      </div>
                      <div>
                        <AutoComplete
                          showSearch
                          placeholder="value"
                          style={{ width: 300, marginLeft: 5 }}
                          onChange={setValue}
                          defaultValue={value}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          options={labelValues}
                        />
                        <Select
                          placeholder="operator"
                          style={{ width: 75, marginLeft: 5 }}
                          onChange={setOperator}
                          defaultValue={operator}
                          getPopupContainer={(trigger) => trigger.parentElement}
                        >
                          <Select.Option value="in">in</Select.Option>
                          <Select.Option value="not in">not in</Select.Option>
                        </Select>
                        <Select
                          showSearch
                          placeholder="key"
                          style={{ width: 300, marginLeft: 5 }}
                          onChange={(value: string) => {
                            setKey(value);
                            setLabelValues(
                              keysDict![value].map((value) => ({
                                value: value,
                                label: value,
                              }))
                            );
                          }}
                          defaultValue={key}
                          getPopupContainer={(trigger) => trigger.parentElement}
                          options={labelKeys}
                        />

                        <div style={{ marginTop: 8 }}>
                          <Button
                            disabled={!(key && operator && value)}
                            type="primary"
                            onClick={() => {
                              handleUpdateFilter();
                              setPopoverOpen(false);
                            }}
                            size="small"
                            style={{ width: 75 }}
                          >
                            Search
                          </Button>
                          <Button
                            onClick={() => {
                              setLabelFilter(undefined);
                              setOperator(undefined);
                              setKey(undefined);
                              setValue(null);
                              setLabelValues([]);
                              setPopoverOpen(false);
                            }}
                            size="small"
                            type="link"
                          >
                            reset
                          </Button>
                        </div>
                      </div>
                    </div>
                  }
                  trigger="click"
                  open={popoverOpen}
                  onOpenChange={handleOpenChange}
                >
                  <span>
                    <Button type="link">
                      <SearchOutlined />
                    </Button>
                  </span>
                </Popover>
              </Col>
            </Row>
            {imagesList.length === 0 ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No images found"
              />
            ) : (
              <List
                dataSource={imagesList}
                renderItem={(image) => (
                  <List.Item
                    style={{ width: "100%" }}
                    className="eol-list-item-bordered"
                    onClick={() => {
                      setSelectedImage(image);
                      setSelectedProjectName(image.projectName);
                      setIsDrawerVisible(true);
                    }}
                  >
                    <Row style={{ width: "100%" }} align="middle">
                      <Col span={imageNameColWidth} ref={imageNameColRef}>
                        <Avatar
                          style={{ marginRight: 6 }}
                          size={19}
                          shape="circle"
                          src={renderSoftwareIcon("Docker", "")}
                        />
                        <TruncatedText
                          label={image.name}
                          columnLength={imageNameColLength}
                        />
                      </Col>
                      <Col span={imageTagColWidth}>
                        <Tooltip title={image.tag}>
                          <Tag>
                            <div
                              style={{
                                maxWidth: "100px",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {image.tag}
                            </div>
                          </Tag>
                        </Tooltip>
                      </Col>
                      <Col span={imageVerifiedColWidth}>
                        {renderSignatureTag(image)}
                      </Col>
                      <Col span={imageLabelsColWidth}>
                        {renderLabelsList(image.labels, 25, 78)}
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            )}
          </Content>
          {selectedImage && selectedProjectName && (
            <ImageDrawer
              isDrawerVisible={isDrawerVisible}
              setIsDrawerVisible={setIsDrawerVisible}
              selectedImage={selectedImage}
              projectName={selectedProjectName}
              setSelectedImage={setSelectedImage}
            />
          )}
          <Pagination
            className="pagination-bar"
            hideOnSinglePage={true}
            showSizeChanger={false}
            current={page}
            total={total}
            pageSize={pageSize}
            onChange={(newPage) => {
              fetchImages({
                page: newPage,
                size: pageSize,
                search: searchTerm,
                filter: filter,
                verified: verifiedFilter,
                requestBody: labelFilter,
              });
            }}
          />
        </>
      )}
    </Content>
  );
};

export default ImagesView;
