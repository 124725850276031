import { PackageNode, PackageNodeEolReason } from "../../gql/graphql";

export const demoDataXeolDashboardProject = {
  nodes: [
    {
      id: "docker",
      ecosystem: "npm",
      label: "docker.io/library/xeol-dashboard",
      version: "",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
        componentType: "library",
        pkgType: "xeol",
      },
      source: null,
    },
    {
      id: "ubuntu",
      ecosystem: "npm",
      label: "Ubuntu",
      version: "20.04",
      qualifiers: [],
      color: "#fa8c16",
      attributes: {
        eol: {
          date: "04/02/25",
          link: "https://endoflife.date/ubuntu",
          reason: PackageNodeEolReason.VendorAnnounced,
        },
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
        componentType: "library",
        pkgType: "xeol",
      },
      source: null,
    },
    {
      id: "antd",
      ecosystem: "npm",
      label: "antd",
      version: "5.7.0",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 6.6,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/ant-design/ant-design",
        },
        vulns: [],
      },
      source: {
        owners: ["ant-design"],
        repo: "https://github.com/ant-design/ant-design",
      },
    },
    {
      id: "@ant-design/colors",
      ecosystem: "npm",
      label: "@ant-design/colors",
      version: "7.0.0",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 4.0,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/ant-design/ant-design-colors",
        },
        vulns: [],
      },
      source: {
        owners: ["ant-design"],
        repo: "https://github.com/ant-design/ant-design-colors",
      },
    },
    {
      id: "react",
      ecosystem: "npm",
      label: "react",
      version: "18.2.0",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 5.5,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/facebook/react",
        },
        vulns: [],
      },
      source: {
        owners: ["facebook"],
        repo: "https://github.com/facebook/react",
      },
    },
    {
      id: "react-dom",
      ecosystem: "npm",
      label: "react-dom",
      version: "18.2.0",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [
          {
            id: "GHSA-mvjj-gqq2-p4hw",
            vulnID: "GHSA-mvjj-gqq2-p4hw",
            link: "https://osv.dev/vulnerability/GHSA-mvjj-gqq2-p4hw",
          },
        ],
      },
      source: {
        owners: ["facebook"],
        repo: "https://github.com/facebook/react",
      },
    },
    {
      id: "moment",
      ecosystem: "npm",
      label: "moment",
      version: "2.29.4",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 3.1,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/moment/moment",
        },
        vulns: [
          {
            id: "GHSA-446m-mv8f-q348",
            vulnID: "GHSA-446m-mv8f-q348",
            link: "https://osv.dev/vulnerability/GHSA-446m-mv8f-q348",
          },
        ],
      },
      source: {
        owners: ["moment"],
        repo: "https://github.com/moment/moment",
      },
    },
    {
      id: "scheduler",
      ecosystem: "npm",
      label: "scheduler",
      version: "0.23.0",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 5.0,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/facebook/react",
        },
        vulns: [],
      },
      source: {
        owners: ["facebook", "test"],
        repo: "https://github.com/facebook/react",
      },
    },
    {
      id: "luxon",
      ecosystem: "npm",
      label: "luxon",
      version: "3.4.0",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 4.9,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/moment/luxon",
        },
        vulns: [
          {
            id: "GHSA-3xq5-wjfh-ppjc",
            vulnID: "GHSA-3xq5-wjfh-ppjc",
            link: "https://osv.dev/vulnerability/GHSA-3xq5-wjfh-ppjc",
          },
        ],
      },
      source: {
        owners: ["moment"],
        repo: "https://github.com/moment/luxon",
      },
    },
    {
      id: "axios",
      ecosystem: "npm",
      label: "axios",
      version: "1.4.0",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 5.7,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/axios/axios",
        },
        vulns: [],
      },
      source: {
        owners: ["axios"],
        repo: "https://github.com/axios/axios",
      },
    },
    {
      id: "typescript",
      ecosystem: "npm",
      label: "typescript",
      version: "4.4.2",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 7.1,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/microsoft/TypeScript",
        },
        vulns: [],
      },
      source: {
        owners: ["microsoft"],
        repo: "https://github.com/microsoft/typescript",
      },
    },
    {
      id: "next",
      ecosystem: "npm",
      label: "next",
      version: "13.4.10",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 4.2,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/vercel/next.js",
        },
        vulns: [],
      },
      source: {
        owners: ["vercel"],
        repo: "https://github.com/vercel/next.js",
      },
    },
    {
      id: "react-router-dom",
      ecosystem: "npm",
      label: "react-router-dom",
      version: "6.14.1",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 3.6,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/remix-run/react-router",
        },
        vulns: [],
      },
      source: {
        owners: ["remix-run"],
        repo: "https://github.com/remix-run/react-router",
      },
    },
    {
      id: "react-query",
      ecosystem: "npm",
      label: "react-query",
      version: "3.39.3",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 3.9,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/tannerlinsley/react-query",
        },
        vulns: [],
      },
      source: {
        owners: ["TanStack"],
        repo: "https://github.com/TanStack/query",
      },
    },
    {
      id: "file-saver",
      ecosystem: "npm",
      label: "file-saver",
      version: "2.0.5",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 2.5,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/eligrey/FileSaver.js",
        },
        vulns: [],
      },
      source: {
        owners: ["eligrey"],
        repo: "https://github.com/eligrey/filesaver.js",
      },
    },
    {
      id: "three",
      ecosystem: "npm",
      label: "three",
      version: "0.124.0",
      qualifiers: [],
      color: "#13c2c2",
      attributes: {
        componentType: "library",
        pkgType: "npm",
        eol: null,
        ossfScore: {
          score: 7.0,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/mrdoob/three.js",
        },
        vulns: [
          {
            id: "GHSA-fq6p-x6j3-cmmq",
            vulnID: "GHSA-fq6p-x6j3-cmmq",
            link: "https://osv.dev/vulnerability/GHSA-fq6p-x6j3-cmmq",
          },
        ],
      },
      source: {
        owners: ["mrdoob"],
        repo: "https://github.com/mrdoob/three.js",
      },
    },
  ] satisfies PackageNode[],
  links: [
    { source: "docker", target: "ubuntu" },
    { source: "ubuntu", target: "react" },
    { source: "antd", target: "react" },
    { source: "antd", target: "react-dom" },
    { source: "antd", target: "@ant-design/colors" },
    { source: "antd", target: "moment" },
    { source: "antd", target: "luxon" },
    { source: "antd", target: "scheduler" },
    { source: "react", target: "react-dom" },
    { source: "react", target: "axios" },
    { source: "react", target: "typescript" },
    { source: "react", target: "next" },
    { source: "react", target: "react-router-dom" },
    { source: "react", target: "react-query" },
    { source: "react", target: "file-saver" },
    { source: "react", target: "three" },
  ],
  colorMap: [],
  isUpgradePathFeatureEnabled: false,
};

export const demoDataXeolBackendProject = {
  nodes: [
    {
      id: "docker",
      ecosystem: "npm",
      label: "docker.io/library/xeol-backend",
      version: "",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
        pkgType: "npm",
        componentType: "library",
      },
      source: null,
    },
    {
      id: "ubuntu",
      ecosystem: "npm",
      label: "Ubuntu",
      version: "20.04",
      qualifiers: [],
      color: "#fa8c16",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: {
          date: "04/02/25",
          link: "https://endoflife.date/ubuntu",
          reason: PackageNodeEolReason.VendorAnnounced,
        },
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "python",
      ecosystem: "npm",
      label: "Python",
      version: "3.11",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: {
          date: "04/01/24",
          link: "https://endoflife.date/python",
          reason: PackageNodeEolReason.VendorAnnounced,
        },
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pytest",
      ecosystem: "npm",
      label: "pytest",
      version: "7.4.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 7.0,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/pytest-dev/pytest",
        },
        vulns: [],
      },
      source: {
        owners: ["pytest-dev"],
        repo: "https://github.com/pytest-dev/pytest",
      },
    },
    {
      id: "urllib3",
      ecosystem: "npm",
      label: "urllib3",
      version: "1.26.3",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 9.1,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/urllib3/urllib3",
        },
        vulns: [
          {
            id: "GHSA-q2q7-5pp4-w6pg",
            vulnID: "GHSA-q2q7-5pp4-w6pg",
            link: "https://osv.dev/vulnerability/GHSA-q2q7-5pp4-w6pg",
          },
          {
            id: "GHSA-5phf-pp7p-vc2r",
            vulnID: "GHSA-5phf-pp7p-vc2r",
            link: "https://osv.dev/vulnerability/GHSA-5phf-pp7p-vc2r",
          },
        ],
      },
      source: {
        owners: ["urllib3"],
        repo: "https://github.com/urllib3/urllib3",
      },
    },
    {
      id: "python-dotenv",
      ecosystem: "npm",
      label: "python-dotenv",
      version: "1.0.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 4.0,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/theskumar/python-dotenv",
        },
        vulns: [],
      },
      source: {
        owners: ["theskumar"],
        repo: "https://github.com/theskumar/python-dotenv",
      },
    },
    {
      id: "requests",
      ecosystem: "npm",
      label: "requests",
      version: "2.31.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 8.6,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/psf/requests",
        },
        vulns: [],
      },
      source: {
        owners: ["psf"],
        repo: "https://github.com/psf/requests",
      },
    },
    {
      id: "rsa",
      ecosystem: "npm",
      label: "rsa",
      version: "4.6.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 5.8,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/sybrenstuvel/python-rsa",
        },
        vulns: [
          {
            id: "PYSEC-2020-100",
            vulnID: "PYSEC-2020-100",
            link: "https://osv.dev/vulnerability/PYSEC-2020-100",
          },
        ],
      },
      source: {
        owners: ["sybrenstuvel"],
        repo: "https://github.com/sybrenstuvel/python-rsa",
      },
    },
    {
      id: "pyasn1",
      ecosystem: "npm",
      label: "pyasn1",
      version: "0.5.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 4.2,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/etingof/pyasn1",
        },
        vulns: [],
      },
      source: {
        owners: ["pyasn1"],
        repo: "https://github.com/pyasn1/pyasn1",
      },
    },
    {
      id: "pydantic",
      ecosystem: "npm",
      label: "pydantic",
      version: "1.8.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 7.4,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/samuelcolvin/pydantic",
        },
        vulns: [
          {
            id: "GHSA-5jqp-qgf6-3pvh",
            vulnID: "GHSA-5jqp-qgf6-3pvh",
            link: "https://osv.dev/vulnerability/GHSA-5jqp-qgf6-3pvh",
          },
        ],
      },
      source: {
        owners: ["pydantic"],
        repo: "https://github.com/pydantic/pydantic",
      },
    },
    {
      id: "typing-extensions",
      label: "typing-extensions",
      ecosystem: "npm",
      version: "4.7.1",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: {
        owners: ["python"],
        repo: "https://github.com/python/typing_extensions",
      },
    },
    {
      id: "fastapi",
      label: "fastapi",
      ecosystem: "npm",
      version: "0.65.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 5.8,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/tiangolo/fastapi",
        },
        vulns: [
          {
            id: "GHSA-8h2j-cgx8-6xv7",
            vulnID: "GHSA-8h2j-cgx8-6xv7",
            link: "https://osv.dev/vulnerability/GHSA-8h2j-cgx8-6xv7",
          },
        ],
      },
      source: {
        owners: ["tiangolo"],
        repo: "https://github.com/tiangolo/fastapi",
      },
    },
    {
      id: "starlette",
      label: "starlette",
      ecosystem: "npm",
      version: "0.14.2",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 6.6,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/encode/starlette",
        },
        vulns: [
          {
            id: "GHSA-v5gw-mw7f-84px",
            vulnID: "GHSA-v5gw-mw7f-84px",
            link: "https://osv.dev/vulnerability/GHSA-v5gw-mw7f-84px",
          },
          {
            id: "GHSA-74m5-2c7w-9w3x",
            vulnID: "GHSA-74m5-2c7w-9w3x",
            link: "https://osv.dev/vulnerability/GHSA-74m5-2c7w-9w3x",
          },
          {
            id: "GHSA-3qj8-93xh-pwh2",
            vulnID: "GHSA-3qj8-93xh-pwh2",
            link: "https://osv.dev/vulnerability/GHSA-3qj8-93xh-pwh2",
          },
          {
            id: "PYSEC-2023-48",
            vulnID: "PYSEC-2023-48",
            link: "https://osv.dev/vulnerability/PYSEC-2023-48",
          },
          {
            id: "PYSEC-2023-83",
            vulnID: "PYSEC-2023-83",
            link: "https://osv.dev/vulnerability/PYSEC-2023-83",
          },
        ],
      },
      source: {
        owners: ["encode"],
        repo: "https://github.com/encode/starlette",
      },
    },
    {
      id: "SQLAlchemy",
      label: "SQLAlchemy",
      ecosystem: "npm",
      version: "2.0.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 5.6,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/sqlalchemy/sqlalchemy",
        },
        vulns: [],
      },
      source: {
        owners: ["sqlalchemy"],
        repo: "https://github.com/sqlalchemy/sqlalchemy",
      },
    },
    {
      id: "websockets",
      label: "websockets",
      ecosystem: "npm",
      version: "10.3.0",
      qualifiers: [],
      color: "#52c41a",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 6.1,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/aaugustin/websockets",
        },
        vulns: [],
      },
      source: {
        owners: ["python-websockets"],
        repo: "https://github.com/python-websockets/websockets",
      },
    },
  ] satisfies PackageNode[],
  links: [
    { source: "docker", target: "ubuntu" },
    { source: "ubuntu", target: "python" },
    { source: "python", target: "pytest" },
    { source: "python", target: "urllib3" },
    { source: "python", target: "python-dotenv" },
    { source: "python", target: "requests" },
    { source: "python", target: "rsa" },
    { source: "rsa", target: "pyasn1" },
    { source: "python", target: "pydantic" },
    { source: "pydantic", target: "typing-extensions" },
    { source: "python", target: "fastapi" },
    { source: "fastapi", target: "pydantic" },
    { source: "fastapi", target: "typing-extensions" },
    { source: "fastapi", target: "starlette" },
    { source: "python", target: "SQLAlchemy" },
    { source: "python", target: "websockets" },
  ],
  colorMap: [],
  isUpgradePathFeatureEnabled: false,
};

export const demoDataXeolEngineProject = {
  nodes: [
    {
      id: "docker",
      label: "docker.io/library/graph-engine",
      ecosystem: "npm",
      version: "",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "ubuntu",
      label: "Ubuntu",
      ecosystem: "npm",
      version: "20.04",
      qualifiers: [],
      color: "#fa8c16",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: {
          date: "04/02/25",
          link: "https://endoflife.date/ubuntu",
          reason: PackageNodeEolReason.VendorAnnounced,
        },
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "golang",
      label: "Go",
      ecosystem: "npm",
      version: "1.19",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: {
          date: "08/08/23",
          link: "https://endoflife.date/go",
          reason: PackageNodeEolReason.VendorAnnounced,
        },
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "ent",
      label: "ent",
      ecosystem: "npm",
      version: "0.12.2",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 4.6,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/ent/ent",
        },
        vulns: [],
      },
      source: {
        owners: ["ent"],
        repo: "https://github.com/ent/ent",
      },
    },
    {
      id: "cyclonedx-go",
      label: "cyclonedx-go",
      ecosystem: "npm",
      version: "0.7.2",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 6.7,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/cyclonedx/cyclonedx-go",
        },
        vulns: [],
      },
      source: {
        owners: ["CycloneDX"],
        repo: "https://github.com/cyclonedx/cyclonedx-go",
      },
    },
    {
      id: "uuid",
      label: "uuid",
      ecosystem: "npm",
      version: "4.4.0",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 5.6,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/gofrs/uuid",
        },
        vulns: [],
      },
      source: {
        owners: ["gofrs"],
        repo: "https://github.com/gofrs/uuid",
      },
    },
    {
      id: "goquery",
      label: "goquery",
      ecosystem: "npm",
      version: "1.8.1",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 5.4,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/PuerkitoBio/goquery",
        },
        vulns: [],
      },
      source: {
        owners: ["PuerkitoBio"],
        repo: "https://github.com/puerkitobio/goquery",
      },
    },
    {
      id: "cascadia",
      label: "cascadia",
      ecosystem: "npm",
      version: "1.3.1",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 4.9,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/andybalholm/cascadia",
        },
        vulns: [],
      },
      source: {
        owners: ["andybalholm"],
        repo: "https://github.com/andybalholm/cascadia",
      },
    },
    {
      id: "net",
      label: "net",
      ecosystem: "npm",
      version: "0.7.0",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [
          {
            id: "GO-2023-1988",
            vulnID: "GO-2023-1988",
            link: "https://osv.dev/vulnerability/GO-2023-1988",
          },
        ],
      },
      source: {
        owners: ["go"],
        repo: "https://go.googlesource.com/net",
      },
    },
    {
      id: "syft",
      label: "syft",
      ecosystem: "npm",
      version: "0.83.1",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 4.2,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/anchore/syft",
        },
        vulns: [],
      },
      source: {
        owners: ["anchore"],
        repo: "https://github.com/anchore/syft",
      },
    },
    {
      id: "docker/docker",
      label: "docker/docker",
      ecosystem: "npm",
      version: "24.0.2",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 7.7,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/moby/moby",
        },
        vulns: [
          {
            id: "GHSA-j249-ghv5-7mxv",
            vulnID: "GHSA-j249-ghv5-7mxv",
            link: "https://osv.dev/vulnerability/GHSA-j249-ghv5-7mxv",
          },
        ],
      },
      source: {
        owners: ["moby"],
        repo: "https://github.com/moby/moby",
      },
    },
    {
      id: "sigstore",
      label: "sigstore",
      ecosystem: "npm",
      version: "1.7.2",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 8.4,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/sigstore/sigstore",
        },
        vulns: [],
      },
      source: {
        owners: ["sigstore"],
        repo: "https://github.com/sigstore/sigstore",
      },
    },
    {
      id: "log",
      label: "log",
      ecosystem: "npm",
      version: "1.9.0",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 3.2,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/apex/log",
        },
        vulns: [],
      },
      source: {
        owners: ["apex"],
        repo: "https://github.com/apex/log",
      },
    },
    {
      id: "yaml.v3",
      label: "yaml.v3",
      ecosystem: "npm",
      version: "3.0.0",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [
          {
            id: "GO-2022-0603",
            vulnID: "GO-2022-0603",
            link: "https://osv.dev/vulnerability/GO-2022-0603",
          },
        ],
      },
      source: {
        owners: ["go-yaml"],
        repo: "https://github.com/go-yaml/yaml/tree/v3.0.1",
      },
    },
    {
      id: "sys",
      label: "sys",
      ecosystem: "npm",
      version: "0.0.0-20190412213103",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [
          {
            id: "GO-2022-0493",
            vulnID: "GO-2022-0493",
            link: "https://osv.dev/vulnerability/GO-2022-0493",
          },
        ],
      },
      source: {
        owners: ["go"],
        repo: "https://go.googlesource.com/sys",
      },
    },
    {
      id: "aws-sdk-go",
      label: "aws-sdk-go",
      ecosystem: "npm",
      version: "1.20.6",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 5.6,
          link: "https://securityscorecards.dev/viewer/?uri=github.com/aws/aws-sdk-go",
        },
        vulns: [
          {
            id: "GO-2022-0391",
            vulnID: "GO-2022-0391",
            link: "https://osv.dev/vulnerability/GO-2022-0391",
          },
          {
            id: "GO-2022-0646",
            vulnID: "GO-2022-0646",
            link: "https://osv.dev/vulnerability/GO-2022-0646",
          },
        ],
      },
      source: {
        owners: ["aws"],
        repo: "https://github.com/aws/aws-sdk-go",
      },
    },
  ] satisfies PackageNode[],
  links: [
    { source: "docker", target: "ubuntu" },
    { source: "ubuntu", target: "golang" },
    { source: "golang", target: "ent" },
    { source: "golang", target: "cyclonedx-go" },
    { source: "golang", target: "uuid" },
    { source: "golang", target: "goquery" },
    { source: "goquery", target: "cascadia" },
    { source: "goquery", target: "net" },
    { source: "golang", target: "syft" },
    { source: "net", target: "syft" },
    { source: "syft", target: "cyclonedx-go" },
    { source: "syft", target: "docker/docker" },
    { source: "golang", target: "sigstore" },
    { source: "sigstore", target: "net" },
    { source: "golang", target: "log" },
    { source: "log", target: "yaml.v3" },
    { source: "log", target: "sys" },
    { source: "log", target: "aws-sdk-go" },
  ],
  colorMap: [],
  isUpgradePathFeatureEnabled: false,
};

export const demoDataXeolFrontendProject = {
  nodes: [
    {
      id: "b339234eba09890a",
      label: "my-application",
      ecosystem: "npm",
      version: "0.0.0",
      qualifiers: [],
      color: "#fa8c16",
      attributes: {
        pkgType: "npm",
        componentType: "application",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/%40mwap/async@0.0.2?package-id=441daacedad3f495",
      label: "@mwap/async",
      ecosystem: "npm",
      version: "0.0.2",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/dot-case@3.0.4?package-id=08a5c71a4f70d326",
      label: "dot-case",
      ecosystem: "npm",
      version: "3.0.4",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/example-npm@1.0.0?package-id=2e66d464ed54ba62",
      label: "example-npm",
      ecosystem: "npm",
      version: "1.0.0",
      qualifiers: [],
      color: "#722ed1",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/loader-utils@2.0.0?package-id=e2191e5fe7b947fe",
      label: "loader-utils",
      ecosystem: "npm",
      version: "2.0.0",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [
          {
            id: "1",
            vulnID: "GHSA-76p3-8jx3-jpfq",
            link: "https://osv.dev/vulnerability/GHSA-76p3-8jx3-jpfq",
          },
        ],
      },
      source: null,
    },
    {
      id: "pkg:npm/lower-case@2.0.2?package-id=23820a42fde6f915",
      label: "lower-case",
      ecosystem: "npm",
      version: "2.0.2",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/no-case@3.0.4?package-id=072dc1b45951ae00",
      label: "no-case",
      ecosystem: "npm",
      version: "3.0.4",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/snake-case@3.0.4?package-id=fc93ba8210dc8946",
      label: "snake-case",
      ecosystem: "npm",
      version: "3.0.4",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/tslib@2.6.2?package-id=a1a45d103e0fef12",
      label: "tslib",
      ecosystem: "npm",
      version: "2.6.2",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/big.js@5.2.2?package-id=ee9a0f9c7dee798c",
      label: "big.js",
      ecosystem: "npm",
      version: "5.2.2",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/emojis-list@3.0.0?package-id=1db528fbb3a8f277",
      label: "emojis-list",
      ecosystem: "npm",
      version: "3.0.0",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
    {
      id: "pkg:npm/json5@2.2.3?package-id=3c988c0afaa6954c",
      label: "json5",
      ecosystem: "npm",
      version: "2.2.3",
      qualifiers: [],
      color: "#1677ff",
      attributes: {
        pkgType: "npm",
        componentType: "library",
        eol: null,
        ossfScore: {
          score: 0,
          link: "",
        },
        vulns: [],
      },
      source: null,
    },
  ] satisfies PackageNode[],
  links: [
    {
      source: "b339234eba09890a",
      target: "pkg:npm/example-npm@1.0.0?package-id=2e66d464ed54ba62",
    },
    {
      source: "pkg:npm/%40mwap/async@0.0.2?package-id=441daacedad3f495",
      target: "pkg:npm/loader-utils@2.0.0?package-id=e2191e5fe7b947fe",
    },
    {
      source: "pkg:npm/%40mwap/async@0.0.2?package-id=441daacedad3f495",
      target: "pkg:npm/snake-case@3.0.4?package-id=fc93ba8210dc8946",
    },
    {
      source: "pkg:npm/dot-case@3.0.4?package-id=08a5c71a4f70d326",
      target: "pkg:npm/no-case@3.0.4?package-id=072dc1b45951ae00",
    },
    {
      source: "pkg:npm/dot-case@3.0.4?package-id=08a5c71a4f70d326",
      target: "pkg:npm/tslib@2.6.2?package-id=a1a45d103e0fef12",
    },
    {
      source: "pkg:npm/example-npm@1.0.0?package-id=2e66d464ed54ba62",
      target: "pkg:npm/%40mwap/async@0.0.2?package-id=441daacedad3f495",
    },
    {
      source: "pkg:npm/loader-utils@2.0.0?package-id=e2191e5fe7b947fe",
      target: "pkg:npm/big.js@5.2.2?package-id=ee9a0f9c7dee798c",
    },
    {
      source: "pkg:npm/loader-utils@2.0.0?package-id=e2191e5fe7b947fe",
      target: "pkg:npm/emojis-list@3.0.0?package-id=1db528fbb3a8f277",
    },
    {
      source: "pkg:npm/loader-utils@2.0.0?package-id=e2191e5fe7b947fe",
      target: "pkg:npm/json5@2.2.3?package-id=3c988c0afaa6954c",
    },
    {
      source: "pkg:npm/lower-case@2.0.2?package-id=23820a42fde6f915",
      target: "pkg:npm/tslib@2.6.2?package-id=a1a45d103e0fef12",
    },
    {
      source: "pkg:npm/no-case@3.0.4?package-id=072dc1b45951ae00",
      target: "pkg:npm/lower-case@2.0.2?package-id=23820a42fde6f915",
    },
    {
      source: "pkg:npm/no-case@3.0.4?package-id=072dc1b45951ae00",
      target: "pkg:npm/tslib@2.6.2?package-id=a1a45d103e0fef12",
    },
    {
      source: "pkg:npm/snake-case@3.0.4?package-id=fc93ba8210dc8946",
      target: "pkg:npm/dot-case@3.0.4?package-id=08a5c71a4f70d326",
    },
    {
      source: "pkg:npm/snake-case@3.0.4?package-id=fc93ba8210dc8946",
      target: "pkg:npm/tslib@2.6.2?package-id=a1a45d103e0fef12",
    },
  ],
  colorMap: [],
  isUpgradePathFeatureEnabled: true,
};
