import { ApolloProvider } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { App, ConfigProvider, Layout, theme } from "antd";
import enUS from "antd/lib/locale/en_US";
import React, { useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes } from "react-router-dom";
import "reactflow/dist/style.css";
import { useApolloClient } from "./components/ApolloClient";
import Loading from "./components/Loading";
import Menu from "./components/Menu";
import AnalyticsPage from "./pages/analytics";
import GraphPage from "./pages/graph";
import IntegrationsPage from "./pages/integrations";
import QueryBuilder from "./pages/inventory";
import LoginPage from "./pages/login";
import NotFoundPage from "./pages/not-found-page";
import PackagePage from "./pages/package";
import PoliciesPage from "./pages/policies";
import SettingsPage from "./pages/settings";
import SignalsPage from "./pages/signals";
import TeamPage from "./pages/team";

const { useToken } = theme;

const queryClient = new QueryClient();
const { Content, Sider } = Layout;

const XeolApp: React.FC = () => {
  const { isLoading, isAuthenticated } = useAuth0();
  const client = useApolloClient();
  const [collapsed, setCollapsed] = useState(false);
  const { token } = useToken();

  if (isLoading) {
    return <Loading />;
  }

  const SiderStyle = {
    height: "100vh",
    position: "sticky",
    top: 0,
    left: 0,
    backgroundColor: "#f5f5f5",
  } as React.CSSProperties;

  const ContentStyle = {
    minWidth: 1000,
    padding: 18,
    backgroundColor: token.colorBgContainer,
  };

  const renderApp = () => {
    return (
      <Routes>
        <Route
          path="/*"
          element={
            isAuthenticated ? (
              <ApolloProvider client={client}>
                <QueryClientProvider client={queryClient}>
                  <Layout>
                    <Sider
                      style={SiderStyle}
                      trigger={null}
                      collapsible
                      collapsed={collapsed}
                    >
                      <Menu collapsed={collapsed} onCollapse={setCollapsed} />
                    </Sider>
                    <Layout>
                      <Content id={"page-content"} style={ContentStyle}>
                        <Routes>
                          <Route path="/" element={<AnalyticsPage />} />
                          <Route path="/graph" element={<GraphPage />} />
                          <Route path="/policies" element={<PoliciesPage />} />
                          <Route path="/signals" element={<SignalsPage />} />
                          <Route
                            path="/integrations"
                            element={<IntegrationsPage />}
                          />
                          <Route
                            path="/package/:type/:namespaceandname/:version"
                            element={<PackagePage />}
                          />

                          <Route path="/inventory" element={<QueryBuilder />} />
                          <Route path="/team" element={<TeamPage />} />
                          <Route path="/settings" element={<SettingsPage />} />
                          <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                      </Content>
                    </Layout>
                  </Layout>
                </QueryClientProvider>
              </ApolloProvider>
            ) : (
              <LoginPage />
            )
          }
        />
      </Routes>
    );
  };

  return (
    <ConfigProvider locale={enUS}>
      <HelmetProvider>
        <App>{renderApp()}</App>
      </HelmetProvider>
    </ConfigProvider>
  );
};

export default XeolApp;
