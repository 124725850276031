import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Input,
  Image,
  Layout,
  List,
  message,
  MenuProps,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
} from "antd";
import {
  DownCircleOutlined,
  MinusCircleFilled,
  MoreOutlined,
  QuestionCircleOutlined,
  UserAddOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useState, useEffect, ChangeEvent } from "react";
import QueueAnim from "rc-queue-anim";

import { OpenAPI, DefaultService } from "../client";
import Card from "../components/Card";
import Loading from "../components/Loading";
import {
  checkPrivilege,
  UserRoles,
  AUTH0_ROLES_URL,
  USER_ROLE,
  ADMIN_ROLE,
  OWNER_ROLE,
} from "../utils/jwt";
import { Helmet } from "react-helmet-async";

const { Content } = Layout;

const InputStyle = {
  width: 300,
};

const HoverDeleteUserButtonStyle = {
  color: "#ffa39e",
};

const DefaultDeleteUserButtonStyle = {
  color: "#f5222d",
};

interface User {
  user_id: string;
  email: string;
  picture: string;
  name: string;
  roles: UserRoles[];
}

const TeamPage: React.FC = () => {
  const [usersList, setUsersList] = useState([]);
  const [invitee, setInvitee] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [userRole, setUserRole] = useState<UserRoles>(USER_ROLE);
  const [currentUserRole, setCurrentUserRole] = useState<UserRoles | null>(
    null
  );
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [hoveredUserRole, setHoveredUserRole] = useState<UserRoles | null>(
    null
  );
  const [hoveredUserId, setHoveredUserId] = useState<string | null>(null);
  const [hoveredDeleteUserButton, setHoveredDeleteUserButton] = useState(false);
  const [inviteButtonLoading, setInviteButtonLoading] = useState(false);
  const [isRoleSelectOpen, setRoleSelectOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user, getAccessTokenSilently } = useAuth0();
  const [messageApi, contextHolder] = message.useMessage();
  const API_URL = process.env.REACT_APP_API_URL;

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setInputValue(email);
    setInvitee(email);
    setIsValidEmail(validateEmail(email));
  };

  const handleUserRoleChange = (value: string) => {
    setUserRole(value as UserRoles);
  };

  const inviteUser = async () => {
    setInviteButtonLoading(true);
    const token = await getAccessTokenSilently();
    OpenAPI.BASE = API_URL!;
    OpenAPI.HEADERS = {
      Authorization: `Bearer ${token}`,
    };
    await DefaultService.inviteOrgUserAuth0Auth0OrgIdInvitationsPost(
      user?.org_id,
      { email: invitee, role: userRole }
    ).then(() => {
      setInviteButtonLoading(false);
      setInputValue("");
      setIsValidEmail(false);
      messageApi.success(`${invitee} invited.`);
    });
  };

  const deleteUser = async () => {
    getAccessTokenSilently()
      .then((token) => {
        return axios.delete(
          `${API_URL}/auth0/${user?.org_id}/members/${hoveredUserId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      })
      .then(() => {
        handleMouseLeaveListItem();
        setUsersList(
          usersList.filter((user: User) => user.user_id !== hoveredUserId)
        );
      })
      .catch((error) => {
        console.error(error);
        messageApi.error("Something went wrong, try again.");
      });
  };

  const canDelete = (user: User, currentUser: any): boolean => {
    return (
      !!currentUserRole &&
      !!hoveredUserRole &&
      !!checkPrivilege(currentUserRole, hoveredUserRole) &&
      user.user_id === hoveredUserId &&
      user.user_id !== currentUser.sub
    );
  };

  const handleMouseEnterListItem = (user: User) => {
    setHoveredUserId(user.user_id);
    setHoveredUserRole(user.roles[0]);
  };

  const handleMouseLeaveListItem = () => {
    setHoveredUserId(null);
    setHoveredUserRole(null);
  };

  const renderUserInviteBar = (inputValue: string) => {
    return (
      <Space direction="horizontal">
        <Input
          value={inputValue}
          style={InputStyle}
          placeholder="email"
          onChange={handleInputChange}
        />
        <Select
          defaultValue={USER_ROLE}
          style={{ width: 120 }}
          onChange={handleUserRoleChange}
          onDropdownVisibleChange={(isOpen) => setRoleSelectOpen(isOpen)}
          suffixIcon={
            isRoleSelectOpen ? <UpCircleOutlined /> : <DownCircleOutlined />
          }
          options={[
            { value: USER_ROLE, label: USER_ROLE },
            {
              value: ADMIN_ROLE,
              label: ADMIN_ROLE,
              disabled: currentUserRole === USER_ROLE,
            },
            {
              value: OWNER_ROLE,
              label: OWNER_ROLE,
              disabled:
                currentUserRole === USER_ROLE || currentUserRole === ADMIN_ROLE,
            },
          ]}
        />
        <Button
          icon={<UserAddOutlined />}
          disabled={!isValidEmail}
          loading={inviteButtonLoading}
          onClick={() => inviteUser()}
        >
          Invite
        </Button>
      </Space>
    );
  };

  const renderUsersList = (usersList: User[], currentUser: any) => {
    const renderTitle = (user: User, currentUser: any) => {
      const getTagColor = (userRole: UserRoles) => {
        switch (userRole) {
          case USER_ROLE:
            return "green";
          case ADMIN_ROLE:
            return "geekblue";
          case OWNER_ROLE:
            return "magenta";
        }
      };

      return (
        <Row align="middle">
          <Col span={10}>{user.name}</Col>
          <Col span={13}>
            <Tag
              color={getTagColor(user.roles[0])}
              style={{ fontWeight: "400" }}
            >
              {user.roles[0]}
            </Tag>
          </Col>
          <Col span={1}>
            {canDelete(user, currentUser) && (
              <Popconfirm
                title={`Remove User`}
                description={`Are you sure you want to remove ${user.name}?`}
                onConfirm={deleteUser}
                okText="Remove"
                cancelText="Cancel"
                okButtonProps={{
                  style: {
                    backgroundColor: "#f5222d",
                    color: "white",
                  },
                }}
                icon={<QuestionCircleOutlined style={{ color: "#f5222d" }} />}
              >
                <MinusCircleFilled
                  style={
                    hoveredDeleteUserButton
                      ? HoverDeleteUserButtonStyle
                      : DefaultDeleteUserButtonStyle
                  }
                  onMouseEnter={() => setHoveredDeleteUserButton(true)}
                  onMouseLeave={() => setHoveredDeleteUserButton(false)}
                />
              </Popconfirm>
            )}
          </Col>
        </Row>
      );
    };

    return (
      <List itemLayout="horizontal" dataSource={usersList}>
        <QueueAnim type={["right", "left"]} leaveReverse>
          {usersList.map((user) => (
            <List.Item
              key={user.email}
              onMouseEnter={() => handleMouseEnterListItem(user)}
              onMouseLeave={() => handleMouseLeaveListItem()}
              style={{ marginBottom: 12 }}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={
                      <Image referrerPolicy="no-referrer" src={user.picture} />
                    }
                    size="large"
                  />
                }
                title={renderTitle(user, currentUser)}
                description={user.email}
              />
            </List.Item>
          ))}
        </QueueAnim>
      </List>
    );
  };

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const token = await getAccessTokenSilently();
        OpenAPI.BASE = API_URL!;
        OpenAPI.HEADERS = { Authorization: `Bearer ${token}` };
        await DefaultService.getOrgMembersAuth0Auth0OrgIdMembersGet(
          user?.org_id
        ).then((response) => {
          setUsersList(response);
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    };

    const decodeToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedJwt: any = jwtDecode(token);
        setCurrentUserRole(decodedJwt?.[AUTH0_ROLES_URL][0]);
      } catch (error) {
        console.error("error", error);
      }
    };

    decodeToken();
    fetchMembers();
  }, [getAccessTokenSilently, API_URL, user]);

  return (
    <Content>
      <Helmet>
        <title>Team - Xeol</title>
      </Helmet>
      {contextHolder}
      {loading ? (
        <Loading />
      ) : (
        <Card title="Users">
          <Space style={{ width: "100%" }} direction="vertical" size="large">
            {renderUserInviteBar(inputValue)}
            {renderUsersList(usersList, user)}
          </Space>
        </Card>
      )}
    </Content>
  );
};

export default TeamPage;
