import React, { useState } from "react";
import { Button, Card, Col, Row } from "antd";
import { ExportOutlined } from "@ant-design/icons";
import PieChart from "./PieChart";
import StackedBarChart from "./StackedBarChart";

interface Props {
  title: string;
  margin: number;
  pieData: any;
  barData: any;
  lineData: any;
  levelsMap: Record<string, string>;
  getQueryParams: (kind: string, level: string) => URLSearchParams;
}

const TrendCard: React.FC<Props> = ({
  title,
  margin,
  pieData,
  barData,
  lineData,
  levelsMap,
  getQueryParams,
}) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const levelButtons = Object.entries(levelsMap).map(([buttonName, level]) => (
    <Button
      key={buttonName}
      style={{ marginRight: "5px", marginLeft: "5px" }}
      target="_blank"
      rel="noopener noreferrer"
      href={`/inventory?m=dependencies&${getQueryParams(title, level)}`}
    >
      {buttonName}
      <ExportOutlined
        style={{
          marginLeft: "4px",
          fontSize: "12px",
        }}
      />
    </Button>
  ));

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Card
        style={{ margin: margin }}
        title={title}
        extra={isHovering ? <>{levelButtons}</> : null}
      >
        <Row>
          <Col span={10}>
            <PieChart
              width={0}
              height={210}
              legendFontSize={12}
              data={pieData}
              analytics={true}
            />
          </Col>
          <Col span={14}>
            <StackedBarChart
              height={210}
              barData={barData}
              lineData={lineData}
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default TrendCard;
