import React from "react";
import { Badge, Layout, Row, Col } from "antd";

import IntegrationCard from "../components/IntegrationCard";
import { renderSoftwareIcon } from "../utils/iconRenderer";
import RBACView from "../utils/jwt";
import { Helmet } from "react-helmet-async";

const { Content } = Layout;

const WizIntegration = {
  avatar: renderSoftwareIcon("wiz-logo", ""),
  title: "Wiz",
  description: "Push your EOL data into Wiz",
  disable: true,
};

const ErmeticIntegration = {
  avatar: "",
  title: "Ermetic",
  description: "Push your data into Ermertic",
};

const LaceworkIntegration = {
  avatar: "",
  title: "Lacework",
  description: "Push your data into Lacework",
};

const OpsLevelIntegration = {
  avatar: "",
  title: "OpsLevel",
  description: "Push your data into OpsLevel",
};

const BackstageIntegration = {
  avatar: "",
  title: "Backstage",
  description: "Push your data into Backstage",
};

const CortexIntegration = {
  avatar: "",
  title: "Cortex",
  description: "Push your data into Cortex",
};

const contentStyle = {
  padding: 24,
  height: "100%",
};

const integrations = [
  [WizIntegration],
  // [WizIntegration, ErmeticIntegration, LaceworkIntegration],
  // [OpsLevelIntegration, BackstageIntegration, CortexIntegration],
];

const IntegrationsPage: React.FC = () => {
  return (
    <RBACView view="integrations">
      <Helmet>
        <title>Integrations - Xeol</title>
      </Helmet>
      <Content style={contentStyle}>
        {integrations.map((row, rowIndex) => (
          <Row
            key={rowIndex}
            style={{ marginBottom: "24px" }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            {row.map((item, index) => (
              <Col key={index} className="gutter-row" span={8}>
                {item.disable ? (
                  <Badge.Ribbon key={item.title + "-ribbon"} text="Coming">
                    <IntegrationCard integration={item} />
                  </Badge.Ribbon>
                ) : (
                  <IntegrationCard integration={item} />
                )}
              </Col>
            ))}
          </Row>
        ))}
      </Content>
    </RBACView>
  );
};

export default IntegrationsPage;
