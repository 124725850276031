import React from "react";
import { List } from "antd";
import { ColorMap } from "../../gql/graphql";

interface GraphLegendProps {
  colorMap: Array<ColorMap>;
}

export const GraphLegend: React.FC<GraphLegendProps> = ({ colorMap }) => {
  if (colorMap.length === 0) {
    return <div style={{ minWidth: "70px", padding: "0 0 0 12px" }}></div>;
  }
  return (
    <div style={{ minWidth: "70px", padding: "0 0 0 12px" }}>
      <List
        size="small"
        dataSource={colorMap}
        renderItem={({ color, pkgType }) => (
          <List.Item
            style={{
              display: "flex",
              padding: 0,
              border: "none",
            }}
          >
            <ColorOrb color={color} inline={false} />
            <span style={{ fontSize: "0.7rem" }}>{pkgType}</span>
          </List.Item>
        )}
      />
    </div>
  );
};

export const ColorOrb = ({
  color,
  inline,
}: {
  color: string;
  inline?: boolean;
}) => {
  return (
    <div
      style={{
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        backgroundColor: color,
        marginRight: inline ? "5px" : "12px",
        display: inline ? "inline-block" : "block",
      }}
    ></div>
  );
};
