import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

interface Props {
  view?: boolean;
}

const Loading: React.FC<Props> = ({ view = true }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: view ? "100vh" : "100%",
        width: "100%",
      }}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Loading;
