import { Tooltip } from "antd";
import React, { useEffect, useRef } from "react";
import { useState } from "react";

interface EllipsisTooltipProps {
  title: string;
  children: React.ReactNode;
}

// EllipsisTooltip is a component that will show a tooltip if the content is truncated
const EllipsisTooltip: React.FC<EllipsisTooltipProps> = ({
  title,
  children,
}) => {
  const [visible, setVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleVisibleChange = (isVisible: boolean) => {
    if (
      containerRef.current &&
      containerRef.current.clientWidth < containerRef.current.scrollWidth
    ) {
      setVisible(isVisible);
    }
  };

  useEffect(() => {
    // This is to ensure that the tooltip visibility is checked initially and any time the content changes
    handleVisibleChange(visible);
  }, [children, title, visible]);

  return (
    <Tooltip open={visible} onOpenChange={handleVisibleChange} title={title}>
      <div
        ref={containerRef}
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default EllipsisTooltip;
