import { ReactComponent as DockerIcon } from "../icons/docker.svg";
import { ReactComponent as FileIcon } from "../icons/file.svg";
import React from "react";

export const renderTypeIcon = (imageName: string) => {
  if (imageName == "File" || imageName == "SourceScan") {
    return FileIcon;
  } else {
    return DockerIcon;
  }
};

export const renderSoftwareIcon = (
  productName: string,
  artifactType: string
) => {
  const name = productName.toLowerCase();

  if (name.includes("global-logo")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/global-logo.png";
  } else if (name.includes("project-logo")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/project-logo.png";
  } else if (name.includes("project-notary-logo")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/project-notary-logo.png";
  } else if (name.includes("global-notary-logo")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/global-notary-logo.png";
  } else if (name.includes("wiz-logo")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/wiz-logo.png";
  } else if (name.includes("key-logo")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/key-logo.png";
  } else if (name.includes("docker")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/docker-logo.png";
  } else if (name.includes("redis")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/redis-logo.png";
  } else if (name.includes("ubuntu")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/ubuntu-logo.png";
  } else if (name.includes("mongo")) {
    return "https://upload.wikimedia.org/wikipedia/commons/0/00/Mongodb-svgrepo-com.svg";
  } else if (name.includes("alpine")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/alpine-logo.png";
  } else if (name.includes("fedora")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/fedora-logo.png";
  } else if (name.includes("debian")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/debian-logo.png";
  } else if (name.includes("arch")) {
    return "https://upload.wikimedia.org/wikipedia/commons/7/7d/Arcolinux.svg";
  } else if (name.includes("postgres")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/postgres-logo.png";
  } else if (name.includes("windows")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/windows-logo.png";
  } else if (name.includes("sqlite")) {
    return "https://upload.wikimedia.org/wikipedia/commons/9/97/Sqlite-square-icon.svg";
  } else if (name.includes("kubernetes")) {
    return "https://upload.wikimedia.org/wikipedia/commons/3/39/Kubernetes_logo_without_workmark.svg";
  } else if (name.includes("centos")) {
    return "https://upload.wikimedia.org/wikipedia/commons/9/9e/CentOS_Graphical_Symbol.svg";
  } else if (name.includes("python")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/python-logo.png";
  } else if (name.includes("vue")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/vuejs-logo.png";
  } else if (name.includes("react")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/react-logo.png";
  } else if (name.includes("node")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/node-logo.png";
  } else if (name.includes("golang")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/golang-logo.png";
  } else if (name.includes("bootstrap")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/bootstrap-logo.png";
  } else if (name.includes("microsoft .net")) {
    return "https://upload.wikimedia.org/wikipedia/commons/7/7d/Microsoft_.NET_logo.svg";
  } else if (artifactType.includes("dotnet")) {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/dot-net-package-logo.png";
  } else {
    return "https://s3.amazonaws.com/static.xeol.io/third-party-icons/default-logo.png";
  }
};
