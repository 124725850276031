import { Tag } from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";
import React from "react";

import { toHumanReadableDateTime } from "./helpers";

export const renderSignatureTag = (image: any) => {
  if (image.imageVerified) {
    return (
      <Tag color="green">
        <LockOutlined style={{ color: "#389e0d" }} />{" "}
        {image.latestScanDate && toHumanReadableDateTime(image.latestScanDate)}
      </Tag>
    );
  } else {
    return (
      <Tag color="red">
        <UnlockOutlined style={{ color: "#cf1322" }} />{" "}
        {image.latestScanDate && toHumanReadableDateTime(image.latestScanDate)}
      </Tag>
    );
  }
};
