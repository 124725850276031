import { Space, Tag, Typography } from "antd";
import { ecosystemColorMap } from "./helpers";
import {
  KeyValue,
  Package,
  PackageNode,
  PackageNodeEolReason,
  PackageNodeOssfScore,
  PackageNodeVulns,
} from "../gql/graphql";
import React from "react";
import OSSFScore from "../components/OssfScore";
import Vulnerabilities from "../components/Vulnerabilities";
import { ColumnsType } from "antd/es/table";
import PopoverList from "../components/PopoverList";
import TruncatedText from "../components/TruncatedText";
import { EolReasonTag } from "../components/tags/EolReasonTag";
import { format, formatDistance } from "date-fns";
import { DistanceDate } from "../components/dates/DistanceDate";

export const dependenciesColumns: ColumnsType<PackageNode> = [
  {
    title: "Ecosystem",
    dataIndex: "ecosystem",
    key: "ecosystem",
    width: "5%",
    render: (ecosystem: keyof typeof ecosystemColorMap) => {
      return (
        <Tag bordered={false} color={ecosystemColorMap[ecosystem]}>
          {ecosystem}
        </Tag>
      );
    },
  },
  {
    title: "Package",
    dataIndex: "label",
    key: "label",
    sortDirections: ["ascend", "descend"],
    render: (_text: string, packageResponse: PackageNode) => {
      return (
        <TruncatedText
          type={"primary"}
          label={packageResponse.label}
          columnLength={425}
        />
      );
    },
    sorter: (a: PackageNode, b: PackageNode) => {
      const nameA = a?.label ?? "";
      const nameB = b?.label ?? "";
      return nameA.localeCompare(nameB);
    },
  },
  {
    title: "Version",
    dataIndex: "version",
    key: "version",
    width: "10%",
    render: (version: string) => {
      return (
        <TruncatedText type={"primary"} label={version} columnLength={125} />
      );
    },
  },
  {
    title: "Qualifiers",
    dataIndex: "qualifiers",
    key: "qualifiers",
    width: "15%",
    render: (qualifiers: KeyValue[]) => {
      if (!qualifiers || qualifiers.length === 0) return null;
      return (
        <PopoverList
          mainTag={
            <Tag color="blue" key={qualifiers[0].key}>
              {qualifiers[0].key}: {qualifiers[0].value}
            </Tag>
          }
          popoverTags={qualifiers.slice(1).map((qualifier) => (
            <Tag color="blue" key={qualifier.key}>
              {qualifier.key}: {qualifier.value}
            </Tag>
          ))}
        />
      );
    },
  },
  {
    title: "Score",
    dataIndex: ["attributes", "ossfScore"],
    key: "score",
    sortDirections: ["ascend", "descend"],
    width: "10%",
    sorter: (a: PackageNode, b: PackageNode) => {
      const scoreA = a?.attributes?.ossfScore?.score ?? 11;
      const scoreB = b?.attributes?.ossfScore?.score ?? 11;
      return scoreA - scoreB;
    },
    render: (score: PackageNodeOssfScore) => {
      return <OSSFScore score={score} />;
    },
  },
  {
    title: "EOL",
    key: "eol",
    dataIndex: ["attributes", "eol", "date"],
    sortDirections: ["ascend", "descend"],
    width: "15%",
    render: (date: string, node) => {
      const eolDate = date ? new Date(date) : null;

      return eolDate ? (
        <Space direction="vertical" size={3}>
          <DistanceDate date={eolDate} />
          <EolReasonTag node={node}></EolReasonTag>
        </Space>
      ) : (
        "-"
      );
    },
  },
  {
    title: "Vulnerabilities",
    dataIndex: ["attributes", "vulns"],
    key: "vulns",
    width: "15%",
    sorter: (a: PackageNode, b: PackageNode) => {
      const vulnsA = a.attributes?.vulns?.length ?? 0;
      const vulnsB = b.attributes?.vulns?.length ?? 0;
      return vulnsA - vulnsB;
    },
    render: (vulns: PackageNodeVulns[]) => {
      if (!vulns || vulns.length === 0) {
        return "-";
      } else {
        return (
          <PopoverList
            mainTag={<Vulnerabilities singleVuln={vulns[0]} />}
            popoverTags={vulns.slice(1).map((vuln) => (
              <Vulnerabilities key={vuln.id} singleVuln={vuln} />
            ))}
          />
        );
      }
    },
  },
] as ColumnsType<PackageNode>;
