import React from "react";
import { PackageNode, PackageNodeEolReason } from "../../gql/graphql";
import { Tag, Tooltip } from "antd";
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

export const EolReasonTag = (props: {
  node: Omit<PackageNode, "__typename">;
}) => {
  const { node } = props;
  if (!node.attributes?.eol) return null;

  const { reason } = node.attributes.eol;

  const color = "orange";

  let toolTipText: string, tagText: string;

  switch (reason) {
    case PackageNodeEolReason.RegistryDeprecated:
      toolTipText = "This version was marked as deprecated on its registry";
      tagText = "Registry Deprecated";
      break;
    case PackageNodeEolReason.SourceArchived:
      toolTipText = "This package had its source code repository archived";
      tagText = "Source Archived";
      break;
    case PackageNodeEolReason.VendorAnnounced:
      toolTipText = "This had an EOL date announced by its maintainer(s)";
      tagText = "Vendor Announced";
      break;
    default:
      return null;
  }

  return (
    <Tooltip overlayInnerStyle={{ textAlign: "center" }} title={toolTipText}>
      <Tag color={color}>{tagText}</Tag>
    </Tooltip>
  );
};
