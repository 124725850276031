/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnalyticsEolResponse } from '../models/AnalyticsEolResponse';
import type { CertificatesModel } from '../models/CertificatesModel';
import type { DynamicKeysModel } from '../models/DynamicKeysModel';
import type { EolAnalytics } from '../models/EolAnalytics';
import type { EOLFilterOptions } from '../models/EOLFilterOptions';
import type { EolSoftwareFirst } from '../models/EolSoftwareFirst';
import type { LabelFilter } from '../models/LabelFilter';
import type { NotesModel } from '../models/NotesModel';
import type { NotesPatchModel } from '../models/NotesPatchModel';
import type { OrganizationsModel } from '../models/OrganizationsModel';
import type { OrganizationsPatchModel } from '../models/OrganizationsPatchModel';
import type { Page_EolSoftwareFirst_ } from '../models/Page_EolSoftwareFirst_';
import type { Page_ProjectAndImage_ } from '../models/Page_ProjectAndImage_';
import type { Page_ProjectImage_ } from '../models/Page_ProjectImage_';
import type { Page_SoftwareFirst_ } from '../models/Page_SoftwareFirst_';
import type { Period } from '../models/Period';
import type { PoliciesModel } from '../models/PoliciesModel';
import type { PoliciesPatchModel } from '../models/PoliciesPatchModel';
import type { ScansHistory } from '../models/ScansHistory';
import type { UsersModel } from '../models/UsersModel';
import type { XeolEventPayload } from '../models/XeolEventPayload';
import type { XeolEventPayloadV2 } from '../models/XeolEventPayloadV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Get Analytics Page Eol Counts
     * @param period
     * @param start
     * @param end
     * @param packageType
     * @returns AnalyticsEolResponse Successful Response
     * @throws ApiError
     */
    public static getAnalyticsPageEolCountsAnalyticsEolCountsGet(
        period: Period,
        start: string,
        end: string,
        packageType?: (string | null),
    ): CancelablePromise<AnalyticsEolResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analytics/eol/counts',
            query: {
                'period': period,
                'start': start,
                'end': end,
                'package_type': packageType,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Eol Analytics
     * @returns EolAnalytics Successful Response
     * @throws ApiError
     */
    public static getEolAnalyticsAnalyticsEolOverviewGet(): CancelablePromise<EolAnalytics> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/analytics/eol/overview',
        });
    }

    /**
     * Get Api Key
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getApiKeyApiKeyGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_key',
        });
    }

    /**
     * Create Api Key
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createApiKeyApiKeyPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_key',
        });
    }

    /**
     * Update Api Key
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateApiKeyApiKeyIdPatch(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api_key/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Certificate
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createCertificateCertificateUpsertPost(
        requestBody: CertificatesModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/certificate/upsert',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Certificate
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getCertificateCertificateGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/certificate',
        });
    }

    /**
     * Get Project Image Software
     * @param imageTagId
     * @param search
     * @param eolFilter
     * @param page Page number
     * @param size Page size
     * @returns Page_EolSoftwareFirst_ Successful Response
     * @throws ApiError
     */
    public static getProjectImageSoftwareImageTagsImageTagIdEolSoftwareGet(
        imageTagId: string,
        search?: (string | null),
        eolFilter?: (EOLFilterOptions | null),
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_EolSoftwareFirst_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/image_tags/{image_tag_id}/eol_software',
            path: {
                'image_tag_id': imageTagId,
            },
            query: {
                'search': search,
                'eol_filter': eolFilter,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Image Labels
     * @returns DynamicKeysModel Successful Response
     * @throws ApiError
     */
    public static getImageLabelsImageLabelsGet(): CancelablePromise<DynamicKeysModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/image/labels',
        });
    }

    /**
     * Get Images
     * Returns a paginated list of images for a given organization, along with their EOL status.
     *
     * Args:
     * organization_id (UUID4): The ID of the organization to retrieve images for.
     * search (str, optional): A search string to filter images by name. Defaults to None.
     * filter (EOLFilterOptions, optional): An EOL filter option to filter images by EOL status. Defaults to None.
     *
     * Returns:
     * Page[ProjectImage]: A paginated list of images for the given organization, along with their EOL status.
     * @param search
     * @param verified
     * @param eolFilter
     * @param page Page number
     * @param size Page size
     * @param requestBody
     * @returns Page_ProjectImage_ Successful Response
     * @throws ApiError
     */
    public static getImagesImagesPost(
        search?: (string | null),
        verified?: (string | null),
        eolFilter?: (EOLFilterOptions | null),
        page: number = 1,
        size: number = 50,
        requestBody?: (LabelFilter | null),
    ): CancelablePromise<Page_ProjectImage_> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/images',
            query: {
                'search': search,
                'verified': verified,
                'eol_filter': eolFilter,
                'page': page,
                'size': size,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Software Notes
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listSoftwareNotesNotesGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/notes',
        });
    }

    /**
     * Create Note
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createNoteNotesPost(
        requestBody: NotesModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/notes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Note
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateNoteNotesIdPatch(
        id: string,
        requestBody: NotesPatchModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/notes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Note
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateNoteNotesIdDelete(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/notes/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Organization
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createOrganizationOrganizationsPost(
        requestBody: OrganizationsModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Organization
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readOrganizationOrganizationsIdGet(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Organization
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateOrganizationOrganizationsIdPatch(
        id: string,
        requestBody: OrganizationsPatchModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/organizations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Organization
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteOrganizationOrganizationsIdDelete(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/organizations/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Read Organization By Auth0 Id
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static readOrganizationByAuth0IdOrganizationsAuth0IdGet(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/auth0/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Policies
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listPoliciesV1PolicyGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/policy',
        });
    }

    /**
     * Create Policy
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createPolicyV1PolicyPost(
        requestBody: PoliciesModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/policy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Policies V2
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listPoliciesV2V2PolicyGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v2/policy',
        });
    }

    /**
     * Get Policy
     * @param id
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getPolicyV1PolicyIdGet(
        id: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/policy/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Policy
     * @param id
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updatePolicyV1PolicyIdPatch(
        id: string,
        requestBody: PoliciesPatchModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/policy/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Policy
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deletePolicyV1PolicyIdDelete(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/policy/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Project Software
     * @param projectId
     * @returns EolSoftwareFirst Successful Response
     * @throws ApiError
     */
    public static getProjectSoftwareProjectsProjectIdEolSoftwareGet(
        projectId: string,
    ): CancelablePromise<Array<EolSoftwareFirst>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{project_id}/eol_software',
            path: {
                'project_id': projectId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Projects And Images
     * This function retrieves projects and images based on the organization id, search query, and EOL filter options.
     * It returns a paginated list of projects and images.
     *
     * :param organization_id: The UUID of the organization
     * :param search: The search query string
     * :param eol_filter: The EOL filter options
     * :return: A paginated list of projects and images
     * @param search
     * @param eolFilter
     * @param page Page number
     * @param size Page size
     * @returns Page_ProjectAndImage_ Successful Response
     * @throws ApiError
     */
    public static getProjectsAndImagesProjectsImagesGet(
        search?: string,
        eolFilter?: EOLFilterOptions,
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_ProjectAndImage_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/images',
            query: {
                'search': search,
                'eol_filter': eolFilter,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Project
     * Deletes a project.
     *
     * Args:
     * - id: UUID4: The ID of the project to delete.
     * - _: UUID4: The ID of the organization.
     * - role: str: role of the user
     *
     * Returns:
     * - None
     * @param id
     * @returns void
     * @throws ApiError
     */
    public static deleteProjectProjectsIdDelete(
        id: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{id}',
            path: {
                'id': id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Eol Software
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listEolSoftwareV1ScannerEolSoftwareGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/scanner/eol_software',
        });
    }

    /**
     * Ingest Scan
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static ingestScanV1ScanPut(
        requestBody: XeolEventPayload,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/scan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Ingest Scan V2
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static ingestScanV2V2ScanPut(
        requestBody: XeolEventPayloadV2,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v2/scan',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Projects
     * @returns any Successful Response
     * @throws ApiError
     */
    public static listProjectsV1ScannerProjectsGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/scanner/projects',
        });
    }

    /**
     * List Software Scan History
     * @param imageName
     * @param projectName
     * @returns ScansHistory Successful Response
     * @throws ApiError
     */
    public static listSoftwareScanHistoryV1ScanHistoryGet(
        imageName: string,
        projectName: string,
    ): CancelablePromise<ScansHistory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/scan/history',
            query: {
                'image_name': imageName,
                'project_name': projectName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Software
     * @param search
     * @param eolFilter
     * @param page Page number
     * @param size Page size
     * @returns Page_SoftwareFirst_ Successful Response
     * @throws ApiError
     */
    public static getSoftwareSoftwareGet(
        search?: (string | null),
        eolFilter?: (EOLFilterOptions | null),
        page: number = 1,
        size: number = 50,
    ): CancelablePromise<Page_SoftwareFirst_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/software',
            query: {
                'search': search,
                'eol_filter': eolFilter,
                'page': page,
                'size': size,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Org Members
     * @param auth0OrgId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getOrgMembersAuth0Auth0OrgIdMembersGet(
        auth0OrgId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth0/{auth0_org_id}/members',
            path: {
                'auth0_org_id': auth0OrgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Org Member
     * @param auth0OrgId
     * @param userId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeOrgMemberAuth0Auth0OrgIdMembersUserIdDelete(
        auth0OrgId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/auth0/{auth0_org_id}/members/{user_id}',
            path: {
                'auth0_org_id': auth0OrgId,
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Invite Org User
     * @param auth0OrgId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static inviteOrgUserAuth0Auth0OrgIdInvitationsPost(
        auth0OrgId: string,
        requestBody: UsersModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth0/{auth0_org_id}/invitations',
            path: {
                'auth0_org_id': auth0OrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Org Invitations
     * @param auth0OrgId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getOrgInvitationsAuth0Auth0OrgIdInvitationsGet(
        auth0OrgId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/auth0/{auth0_org_id}/invitations',
            path: {
                'auth0_org_id': auth0OrgId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Health
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getHealthHealthzGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/healthz',
        });
    }

}
