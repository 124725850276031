import React, { useEffect, useState } from "react";
import { DatePicker, Space, TimeRangePickerProps } from "antd";

type RangeValue = Parameters<
  NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>
>[0];

import dayjs, { Dayjs } from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

import { Period } from "../../gql/graphql";
import { RangePickerProps } from "antd/es/date-picker";
import {
  LightFilter,
  ProFormDateRangePicker,
  ProFormSelect,
} from "@ant-design/pro-components";

export const PICKER_DATE = "date";
export const PICKER_WEEK = "week";
export const PICKER_MONTH = "month";
export const PICKER_QUARTER = "quarter";
export const PICKER_YEAR = "year";

export type PickerTypes =
  | typeof PICKER_DATE
  | typeof PICKER_WEEK
  | typeof PICKER_MONTH
  | typeof PICKER_QUARTER
  | typeof PICKER_YEAR;

const monthlyRangePresets: TimeRangePickerProps["presets"] = [
  { label: "Last 30 days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 3 months", value: [dayjs().add(-3, "M"), dayjs()] },
  { label: "Last 6 months", value: [dayjs().add(-6, "M"), dayjs()] },
  { label: "Last 12 months", value: [dayjs().add(-12, "M"), dayjs()] },
];

const dailyRangePresets: TimeRangePickerProps["presets"] = [
  { label: "Last 7 days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 days", value: [dayjs().add(-90, "d"), dayjs()] },
  { label: "Last 180 days", value: [dayjs().add(-180, "d"), dayjs()] },
];

const defaultRange90Days: [Dayjs, Dayjs] = [dayjs().add(-90, "d"), dayjs()];

interface Props {
  period: Period | null;
  rangeStart: Date | null;
  rangeEnd: Date | null;
  setPeriod: (value: Period) => void;
  setRangeStart: (value: Date) => void;
  setRangeEnd: (value: Date) => void;
  disabled?: boolean;
}

const DateRangePicker: React.FC<Props> = ({
  period,
  rangeStart,
  rangeEnd,
  setPeriod,
  setRangeStart,
  setRangeEnd,
  disabled = false,
}) => {
  const [picker, setPicker] = useState<PickerTypes>(PICKER_DATE);
  const [rangePresets, setRangePresets] =
    useState<TimeRangePickerProps["presets"]>(dailyRangePresets);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const onSelectChange = (value: Period) => {
    setPeriod(value);

    switch (value) {
      case Period.Daily:
        setPicker(PICKER_DATE);
        setRangePresets(dailyRangePresets);
        break;
      case Period.Monthly:
        setPicker(PICKER_MONTH);
        setRangePresets(monthlyRangePresets);
        break;
    }
  };

  const rangePickerCallback = (
    dates: RangeValue,
    dateStrings: [string, string]
  ) => {
    const today = dayjs();
    let start: Date = dates?.[0]?.toDate() || defaultRange90Days[0].toDate();
    let end: Date = dates?.[1]?.toDate() || defaultRange90Days[1].toDate();

    if (period === Period.Monthly) {
      start =
        dates?.[0]?.startOf("month").toDate() || defaultRange90Days[0].toDate();

      // Check if the selected end date is in the current month and year
      if (
        dates?.[1] &&
        dates[1].isSame(today, "month") &&
        dates[1].isSame(today, "year")
      ) {
        end = today.toDate(); // Set end to today's date
      } else {
        end =
          dates?.[1]?.endOf("month").toDate() || defaultRange90Days[1].toDate(); // Otherwise, set end to the end of the month
      }
    }

    setRangeStart(start);
    setRangeEnd(end);
  };

  useEffect(() => {
    if (period === Period.Daily) {
      setPicker(PICKER_DATE);
      setRangePresets(dailyRangePresets);
      rangePickerCallback([dayjs(rangeStart), dayjs(rangeEnd)], ["", ""]);
    } else if (period === Period.Monthly) {
      setPicker(PICKER_MONTH);
      setRangePresets(monthlyRangePresets);
      rangePickerCallback([dayjs(rangeStart), dayjs(rangeEnd)], ["", ""]);
    }
  }, [period, rangePickerCallback]);

  return (
    <Space>
      <LightFilter size="middle" collapse={false}>
        <ProFormSelect
          name="period"
          label="Period"
          style={{ width: 130 }}
          initialValue={period}
          disabled={disabled}
          allowClear={false}
          onChange={onSelectChange}
          options={[
            { value: Period.Daily, label: "Daily" },
            { value: Period.Monthly, label: "Monthly" },
          ]}
        />
        <ProFormDateRangePicker
          name="date"
          allowClear={false}
          initialValue={[dayjs(rangeStart), dayjs(rangeEnd)]}
          fieldProps={{
            picker: picker,
            onChange: rangePickerCallback,
            disabledDate: disabledDate,
            presets: rangePresets,
          }}
        />
      </LightFilter>
    </Space>
  );
};

export default DateRangePicker;
