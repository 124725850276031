import axios from "axios";
import {
  Avatar,
  Button,
  Card,
  Input,
  message,
  Layout,
  Modal,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import {
  FileTextOutlined,
  PushpinOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import React, { useState } from "react";

import { EolSoftwareFirst, Page_EolSoftwareFirst_ } from "../../client";
import { USER_ROLE, UserRoles } from "../../utils/jwt";

const { Meta } = Card;
const { TextArea } = Input;
const { Content } = Layout;
const { Text } = Typography;

interface Props {
  softwareId: string;
  userProfile: string | undefined;
  eolPackageData: any;
  setEolPackageData: (eolData: any) => void;
  noteCount: number;
  setNoteCount: (noteCount: number) => void;
  userRole?: UserRoles;
  note?: string | null;
  noteId?: string | null;
  noteCreator?: string | null;
}

const NoteButton: React.FC<Props> = ({
  softwareId,
  userProfile,
  eolPackageData,
  setEolPackageData,
  noteCount,
  setNoteCount,
  userRole = USER_ROLE,
  note = null,
  noteId = null,
  noteCreator = null,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [softwareNote, setSoftwareNote] = useState("");
  const [deleting, setDeleting] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();
  const { getAccessTokenSilently } = useAuth0();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleButtonClick = async () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleNoteCreation = async () => {
    if (softwareNote === "") {
      messageApi.error("Note cannot be empty!");
      return;
    }

    const payload = {
      note_content: softwareNote,
      eol_software: softwareId,
      user_profile: userProfile,
    };

    const token = await getAccessTokenSilently();
    const res = await axios.post(`${API_URL}/notes`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.status === 201 && eolPackageData) {
      const updatedList = eolPackageData.map((item: any) => {
        if (item.id === softwareId) {
          return {
            ...item,
            productNote: {
              id: res.data.id,
              noteContent: res.data.note_content,
              userProfile: res.data.user_profile,
            },
          };
        }
        return item;
      });

      setEolPackageData(updatedList);
      setNoteCount((noteCount += 1));
    }

    messageApi.success("Note added!");
    setIsModalOpen(false);
  };

  const handleNoteDelete = async () => {
    setDeleting(true);

    const token = await getAccessTokenSilently();
    const res = await axios.delete(`${API_URL}/notes/${noteId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.status === 204 && eolPackageData) {
      const updatedList = eolPackageData.map((item: any) => {
        if (item.id === softwareId) {
          return {
            ...item,
            productNote: null,
          };
        }
        return item;
      });

      setEolPackageData(updatedList);
      setNoteCount((noteCount -= 1));
    }

    messageApi.success("Note deleted!");
    setDeleting(false);
    setIsModalOpen(false);
  };

  const onTextAreaChange = (e: any) => {
    setSoftwareNote(e.target.value);
  };

  return (
    <Content>
      {contextHolder}
      {note !== null ? (
        <Content>
          <Button
            style={{
              borderColor: "#faad14",
              backgroundColor: "#fffbe6",
              color: "#d48806",
            }}
            icon={<FileTextOutlined />}
            size="small"
            onClick={handleButtonClick}
          >
            Note
          </Button>
          <Modal
            width={500}
            closable={false}
            open={isModalOpen}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                key="delete"
                onClick={handleNoteDelete}
                loading={deleting}
                danger
                disabled={userRole === USER_ROLE}
              >
                Delete
              </Button>,
            ]}
          >
            <Space direction="vertical">
              {noteCreator !== null ? (
                <Avatar src={noteCreator} />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )}
              <Text>{`"${note}"`}</Text>
            </Space>
          </Modal>
        </Content>
      ) : (
        <Content>
          <Button
            icon={<PushpinOutlined />}
            size="small"
            onClick={handleButtonClick}
            disabled={userRole === USER_ROLE}
          >
            Note
          </Button>
          <Modal
            width={500}
            closable={false}
            open={isModalOpen}
            onOk={handleNoteCreation}
            onCancel={handleCancel}
            okText="Save"
          >
            <TextArea
              showCount
              maxLength={1000}
              style={{ marginBottom: 12, height: 120 }}
              onChange={onTextAreaChange}
              placeholder="Add your note for this software here"
            />
          </Modal>
        </Content>
      )}
    </Content>
  );
};

export default NoteButton;
