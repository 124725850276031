import { Select } from "antd";
import React, { useState } from "react";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

export const IMAGE_VERIFIED = "Verified";
export const IMAGE_UNVERIFIED = "Unverified";
export const IMAGE_ALL = "All Images";

export type ImageVerifiedStatusType =
  | typeof IMAGE_VERIFIED
  | typeof IMAGE_UNVERIFIED
  | typeof IMAGE_ALL;

interface Props {
  show?: boolean;
  value?: string;
  handleSelect?: (selected: ImageVerifiedStatusType) => void;
}

const ImageVerifiedFilterDropdown: React.FC<Props> = ({ value, handleSelect, show }) => {
  const [selected, setSelected] = useState<ImageVerifiedStatusType>(IMAGE_ALL);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = (value: ImageVerifiedStatusType) => {
    setSelected(value);
    if (handleSelect) {
      handleSelect(value);
    }
  };

  return (
    show ? (
      <Select
        style={{ width: 110 }}
        value={selected}
        onChange={handleChange}
        onDropdownVisibleChange={isOpen => setDropdownOpen(isOpen)}
        suffixIcon={isDropdownOpen ? <UpCircleOutlined /> : <DownCircleOutlined />}
      >
        <Option value={IMAGE_VERIFIED}>{IMAGE_VERIFIED}</Option>
        <Option value={IMAGE_UNVERIFIED}>{IMAGE_UNVERIFIED}</Option>
        <Option value={IMAGE_ALL}>{IMAGE_ALL}</Option>
      </Select >
    ) : (
      <div></div>
    )
  );
};

export default ImageVerifiedFilterDropdown;
