import { Layout, Space, Row, Col, Tooltip, Tag, Button } from "antd";
import Table from "antd/es/table";
import type { TableColumnsType } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { gql, useQuery } from "@apollo/client";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { CursorPagination } from "../components/CursorPagination";
import {
  ProjectQueryManager,
  ProjectQueryPageSize,
  ProjectRowData,
  ProjectsApiResponse,
  ProjectsQuery,
  TargetRowData,
  projectsColumns,
  transformProjectsQuery,
} from "../utils/queries/projects";
import SeverityColors from "../utils/colors";
import DownloadSBOMButton from "../components/buttons/DownloadSBOMButton";
import { DownOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons";
import TruncatedText from "../components/TruncatedText";
import DependencySelectFilter from "../components/filters/DependencySelectFilter";
import {
  ArrayParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

const { Content } = Layout;

const contentStyle = {
  margin: 12,
  width: "100%",
  minWidth: 800,
};

const ProjectsPage: React.FC = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [cursor, setCursor] = useState<string | null | undefined>(undefined);
  const [currentQuery, setCurrentQuery] = useState(ProjectsQuery);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<
    string | null | undefined
  >();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [project, setProject] = useQueryParam("project", StringParam);
  const [projectSbomIDs, setProjectSbomIDs] = useQueryParam(
    "psbids",
    withDefault(ArrayParam, [])
  );

  const { data, loading, error, refetch } = useQuery<ProjectsApiResponse>(
    gql`
      ${jsonToGraphQLQuery(currentQuery)}
    `,
    { context: { path: "/query" } }
  );

  useEffect(() => {
    const newQuery = new ProjectQueryManager(
      currentQuery,
      project,
      projectSbomIDs
    );
    setCurrentQuery(newQuery.getQuery());
  }, [projectSbomIDs, project, currentQuery]);

  const onChange = (
    newEvaluatedKey: string | null | undefined,
    currentPage: number
  ) => {
    setCursor(newEvaluatedKey);
    setCurrentPage(currentPage);
  };

  useEffect(() => {
    setLastEvaluatedKey(data?.projects.pageInfo.endCursor);

    // Calculate total pages based on totalCount and page size
    const totalCount = data?.projects.totalCount;
    let totalPages = 0;
    if (typeof totalCount === "number" && totalCount > 0) {
      totalPages = Math.ceil(totalCount / ProjectQueryPageSize);
    }

    setTotalPages(totalPages);
  }, [data]);

  useEffect(() => {
    setIsRefetching(true);
    refetch({
      cursor: cursor,
    }).then(() => {
      setIsRefetching(false);
    });
  }, [cursor, refetch]);

  const expandedRow = (row: ProjectRowData) => {
    const columns: TableColumnsType<TargetRowData> = [
      {
        title: "Target",
        dataIndex: "name",
        key: "name",
        width: "40%",
        render: (value) => {
          return (
            <TruncatedText
              label={value}
              type={"primary"}
              columnLength={window.innerWidth * 0.48}
            />
          );
        },
      },
      {
        title: "Dependencies",
        dataIndex: "dependencies",
        key: "dependencies",
        fixed: "left",
        width: "6%",
        render(value, record) {
          return (
            <div
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Button
                icon={<SearchOutlined />}
                onClick={() =>
                  (window.location.href = `/inventory?m=dependencies&project=${encodeURIComponent(
                    record.project
                  )}&psbids=${record.sbomID}`)
                }
                style={{ height: "25px", padding: "2px 7px" }}
                type="link"
              />
              <Tooltip title={value}>{value}</Tooltip>
            </div>
          );
        },
      },
      {
        title: "Vulns",
        dataIndex: "vulnCounts",
        key: "vulnCounts",
        width: "15%",
        render: (vulns: any) => {
          if (!vulns) return null;

          const isDisabled = (count: number) => count === 0;

          const contentStyle = (count: number) => ({
            color: count === 0 ? "grey" : "inherit",
          });

          return (
            <Row align="middle">
              <Col span={4.8}>
                <Tooltip title="cvss: unknown">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(vulns.unknown)
                        ? SeverityColors.Unknown
                        : "default"
                    }
                  >
                    <span style={contentStyle(vulns.unknown)}>
                      {vulns.unknown}
                    </span>
                  </Tag>
                </Tooltip>
              </Col>
              <Col span={4.8}>
                <Tooltip title="cvss: low">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(vulns.low) ? SeverityColors.Low : "default"
                    }
                  >
                    <span style={contentStyle(vulns.low)}>{vulns.low}</span>
                  </Tag>
                </Tooltip>
              </Col>
              <Col span={4.8}>
                <Tooltip title="cvss: medium">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(vulns.medium)
                        ? SeverityColors.Medium
                        : "default"
                    }
                  >
                    <span style={contentStyle(vulns.medium)}>
                      {vulns.medium}
                    </span>
                  </Tag>
                </Tooltip>
              </Col>
              <Col span={4.8}>
                <Tooltip title="cvss: high">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(vulns.high) ? SeverityColors.High : "default"
                    }
                  >
                    <span style={contentStyle(vulns.high)}>{vulns.high}</span>
                  </Tag>
                </Tooltip>
              </Col>
              <Col span={4.8}>
                <Tooltip title="cvss: critical">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(vulns.critical)
                        ? SeverityColors.Critical
                        : "default"
                    }
                  >
                    <span style={contentStyle(vulns.critical)}>
                      {vulns.critical}
                    </span>
                  </Tag>
                </Tooltip>
              </Col>
            </Row>
          );
        },
      },
      {
        title: "EOL",
        dataIndex: "eolCounts",
        key: "eolCounts",
        width: "10%",
        render: (eolCounts: any) => {
          const isDisabled = (count: number) => count === 0;

          const contentStyle = (count: number) => ({
            color: count === 0 ? "grey" : "inherit",
          });

          return (
            <Row align="middle">
              <Col span={6}>
                <Tooltip title="EOL in more than 12 months">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(eolCounts.low)
                        ? SeverityColors.Low
                        : "default"
                    }
                  >
                    <span style={contentStyle(eolCounts.low)}>
                      {eolCounts.low}
                    </span>
                  </Tag>
                </Tooltip>
              </Col>
              <Col span={6}>
                <Tooltip title="EOL within 12 months">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(eolCounts.medium)
                        ? SeverityColors.Medium
                        : "default"
                    }
                  >
                    <span style={contentStyle(eolCounts.medium)}>
                      {eolCounts.medium}
                    </span>
                  </Tag>
                </Tooltip>
              </Col>
              <Col span={6}>
                <Tooltip title="EOL within 6 months">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(eolCounts.high)
                        ? SeverityColors.High
                        : "default"
                    }
                  >
                    <span style={contentStyle(eolCounts.high)}>
                      {eolCounts.high}
                    </span>
                  </Tag>
                </Tooltip>
              </Col>
              <Col span={6}>
                <Tooltip title="EOL">
                  <Tag
                    bordered={true}
                    color={
                      !isDisabled(eolCounts.critical)
                        ? SeverityColors.Critical
                        : "default"
                    }
                  >
                    <span style={contentStyle(eolCounts.critical)}>
                      {eolCounts.critical}
                    </span>
                  </Tag>
                </Tooltip>
              </Col>
            </Row>
          );
        },
      },
      {
        title: "Seen",
        dataIndex: "seen",
        key: "seen",
        width: "10%",
      },
      {
        title: "SBOM",
        dataIndex: "sbomID",
        key: "sbomID",
        width: "8%",
        render: (sbomID: string) => {
          return <DownloadSBOMButton sbomID={sbomID}></DownloadSBOMButton>;
        },
      },
    ];

    return (
      <Table
        size="small"
        className="nested-table"
        columns={columns}
        dataSource={row.targets}
        pagination={false}
      />
    );
  };

  const filters = {
    project,
    projectSbomIDs,
  };

  const handleFilterChange = (
    filterName: string,
    option:
      | { label: string; value: string }
      | { label: string; value: string }[]
  ) => {
    setCurrentPage(1);

    switch (filterName) {
      case "project":
        option = option as { label: string; value: string };
        setProject(option?.label || undefined);
        setProjectSbomIDs(option?.value.split(",") || []);
        break;
      default:
        break;
    }
  };

  return (
    <Content style={contentStyle}>
      <Helmet>
        <title>Projects - Xeol</title>
      </Helmet>
      <Space style={{ width: "100%" }} direction="vertical">
        <DependencySelectFilter
          filters={filters}
          enabledFilters={{
            project: true,
          }}
          onFilterChange={handleFilterChange}
        />
        <Table
          className="ant-parent-nested-table"
          size="small"
          expandable={{
            expandedRowRender: expandedRow,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <DownOutlined
                  style={{
                    fontSize: "12px",
                    marginLeft: "4px",
                    marginRight: "4px",
                  }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <RightOutlined
                  style={{
                    fontSize: "12px",
                    marginLeft: "4px",
                    marginRight: "4px",
                  }}
                  onClick={(e) => onExpand(record, e)}
                />
              ),
          }}
          style={{ marginTop: 15 }}
          dataSource={data ? transformProjectsQuery(data) : []}
          columns={projectsColumns}
          loading={loading || isRefetching}
          locale={{ emptyText: "No projects found" }}
          rowKey="id"
          pagination={false}
        />
        <Row>
          <Col>
            <CursorPagination
              lastEvaluatedKey={lastEvaluatedKey}
              onChange={onChange}
              size={"small"}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </Col>
        </Row>
      </Space>
    </Content>
  );
};

export default ProjectsPage;
