import React from "react";
import { Pie, PieConfig } from "@ant-design/plots";
import { KeyValueIntItem } from "../../client";
import SeverityColors from "../../utils/colors";

interface PieChartProps {
  width: number;
  height: number;
  data: KeyValueIntItem[];
  disabled?: boolean;
  legendFontSize?: number;
  analytics?: boolean;
}

const mockData = [
  { name: "npm", value: 5 },
  { name: "maven", value: 5 },
  { name: "pypi", value: 5 },
  { name: "nuget", value: 5 },
];

const PieChart: React.FC<PieChartProps> = ({
  width,
  height,
  data,
  disabled = false,
  analytics = false,
  legendFontSize = 16,
}) => {
  const renderPieColor = () => {
    if (disabled) {
      return "#bfbfbf";
    }

    if (analytics) {
      return [
        SeverityColors.Critical,
        SeverityColors.High,
        SeverityColors.Medium,
        SeverityColors.Low,
        SeverityColors.Unknown,
      ];
    }

    return undefined;
  };

  const pieConfig: PieConfig = {
    appendPadding: 10,
    data: disabled ? mockData : data,
    angleField: "value",
    colorField: "name",
    radius: 1,
    innerRadius: 0.6,
    width: width,
    height: height,
    color: renderPieColor(),
    label: {
      type: "outer",
      offset: 12,
      content: "{value}",
      labelLine: {
        style: {
          lineWidth: disabled ? 0 : 2,
          lineDash: [2, 2],
        },
      },
      style: {
        textAlign: "center",
        fontSize: disabled ? 0 : 16,
        fill: "#262626",
      },
    },
    legend: {
      itemName: {
        style: {
          fill: "#262626",
          fontSize: disabled ? 0 : legendFontSize,
        },
      },
      layout: "vertical",
      position: "left",
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        content: "",
      },
    },
  };

  return disabled ? (
    <Pie {...pieConfig} style={{ pointerEvents: "none" }} />
  ) : (
    <Pie {...pieConfig} />
  );
};

export default PieChart;
