import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import React, { useEffect, useState } from "react";

interface CursorPaginationProps {
  startKey?: string | null;
  lastEvaluatedKey: string | null | undefined;
  onChange: (newKey: string | null, ...args: any[]) => void;
  size: "small" | "large";
  totalPages: number;
  currentPage: number;
}

export const CursorPagination: React.FC<CursorPaginationProps> = ({
  startKey,
  lastEvaluatedKey,
  onChange,
  size,
  totalPages,
  currentPage,
}) => {
  /* Use stack to keep track of which evaluated keys have been previously seen */
  const [prevEvaluatedKeys, setPrevEvaluatedKeys] = useState<string[]>([]);
  /* Keep track of the current evaluated key */
  const [currentEvaluatedKey, setCurrentEvaluatedKey] = useState<string | null>(
    null
  );
  /* Button style changes based on size */
  const [buttonStyle, setButtonStyle] = useState<React.CSSProperties>({});

  const popEvaluatedKey = (): string | null => {
    const newPrevEvaluatedKeys = [...prevEvaluatedKeys];
    const prevEvaluatedKey = newPrevEvaluatedKeys.pop() || null;
    setPrevEvaluatedKeys(newPrevEvaluatedKeys);
    setCurrentEvaluatedKey(prevEvaluatedKey);
    return prevEvaluatedKey;
  };

  const pushEvaluatedKey = (evaluatedKey: string): string => {
    setPrevEvaluatedKeys([...prevEvaluatedKeys, currentEvaluatedKey as string]);
    setCurrentEvaluatedKey(evaluatedKey);
    return evaluatedKey;
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      onChange(pushEvaluatedKey(lastEvaluatedKey as string), newPage);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      onChange(popEvaluatedKey(), newPage);
    }
  };

  useEffect(() => {
    /* Reset stack of keys when pagination is reset back to the beginning */
    if (!startKey) {
      setPrevEvaluatedKeys([]);
      setCurrentEvaluatedKey(null);
    }
  }, [startKey]);

  /* Display forward and back buttons */
  return (
    <Space direction="horizontal">
      {totalPages !== 0 && (
        <>
          <Button
            tabIndex={-1}
            size={size}
            disabled={
              !prevEvaluatedKeys.length && prevEvaluatedKeys.length <= 1
            }
            style={buttonStyle}
            onClick={handlePrevious}
          >
            <LeftOutlined />
          </Button>
          <span>
            {currentPage} / {totalPages}
          </span>

          <Button
            tabIndex={-1}
            size={size}
            disabled={
              totalPages === 0 ||
              currentPage === totalPages ||
              !lastEvaluatedKey
            }
            style={buttonStyle}
            onClick={handleNext}
          >
            <RightOutlined />
          </Button>
        </>
      )}
    </Space>
  );
};
