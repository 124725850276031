import { Radar } from "@ant-design/plots";
import React from "react";

interface Props {
  data: any;
  height: number;
  disabled?: boolean;
}

const mockData = [
  { name: "Vulnerability", value: 0 },
  { name: "Maintainability", value: 0 },
  // { name: "Support", value: 0 },
  { name: "Licensing", value: 0 },
];

const RadarChart: React.FC<Props> = ({ data, height, disabled = false }) => {
  const config = {
    data: disabled ? mockData : data,
    xField: "name",
    yField: "value",
    appendPadding: [0, 10, 0, 10],
    height: height,
    meta: {
      value: {
        min: 0,
        max: 100,
        nice: true,
      },
    },
    xAxis: {
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    point: {
      size: disabled ? 0 : 3,
    },
    area: {},
    color: disabled ? "#bfbfbf" : "#638bf4",
  };

  return disabled ? (
    <Radar {...config} style={{ pointerEvents: "none" }} />
  ) : (
    <Radar {...config} />
  );
};

export default RadarChart;
