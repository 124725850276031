import { VulnCount } from "../gql/graphql";

export interface TrendData {
  time: string;
  percent: number;
}

export interface TrendBarData {
  time: string;
  value: number;
  type: string;
}

export interface PieData {
  name: string;
  value: number;
}

export interface MiniData {
  numerator: number;
  denominator: number;
  percents: [number];
}

export const transformOssfData = (
  ossfCounts: VulnCount[],
  allOssfCounts: VulnCount[]
): {
  trendBarData: TrendBarData[];
  pieData: PieData[];
  trendData: TrendData[];
  miniData: MiniData;
  supplyChainScore: number;
} => {
  const transformedOssfData = ossfCounts.flatMap((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    return [
      { time: item.date, value: item.critical, type: "0.1 - 3.9" },
      { time: item.date, value: item.high, type: "4.0 - 6.9" },
      { time: item.date, value: item.medium, type: "7.0 - 8.9" },
      { time: item.date, value: item.low, type: "9.0 - 10.0" },
      // { time: item.date, value: item.unknown, type: "Unknown" },
      // { time: item.date, value: item.none, type: "None" },
    ];
  });

  const ossfTrendLineData = ossfCounts.map((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }
    const totalVuln =
      item.critical + item.high + item.medium + item.low + item.unknown;
    const percentCritical = totalVuln
      ? Math.round((item.critical / totalVuln) * 100)
      : 0;
    return {
      time: item.date,
      percent: percentCritical,
    };
  }) as TrendData[];

  const latestOssfDate = transformedOssfData.reduce(
    (max, item) => (item.time > max ? item.time : max),
    transformedOssfData[0]?.time
  );

  const ossfAllCriticalPercents = allOssfCounts.map((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    const nom = item.critical;
    const denom =
      item.critical + item.high + item.medium + item.low + item.unknown;
    const percentCritical = denom ? Math.round((nom / denom) * 100) : 0;
    return percentCritical;
  }) as [number];

  const latestOssfData = transformedOssfData.filter(
    (item) => item.time === latestOssfDate
  );

  const latestAllOssfRawData = allOssfCounts.filter((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    return item.date === latestOssfDate;
  }) as VulnCount[];
  const radarOssfData: PieData[] = latestOssfData.map((item) => ({
    name: item.type,
    value: item.value,
  }));

  const supplyChainOssfScore =
    100 - ossfAllCriticalPercents[ossfAllCriticalPercents.length - 1];

  const miniData = {
    numerator: latestAllOssfRawData[0].critical,
    denominator:
      latestAllOssfRawData[0].critical +
      latestAllOssfRawData[0].high +
      latestAllOssfRawData[0].medium +
      latestAllOssfRawData[0].low +
      latestAllOssfRawData[0].unknown,
    percents: ossfAllCriticalPercents,
  };

  return {
    trendBarData: transformedOssfData,
    pieData: radarOssfData,
    trendData: ossfTrendLineData,
    miniData: miniData,
    supplyChainScore: supplyChainOssfScore,
  };
};

export const transformVulnData = (
  vulnCounts: VulnCount[],
  allVulnCounts: VulnCount[]
): {
  trendBarData: TrendBarData[];
  pieData: PieData[];
  trendData: TrendData[];
  miniData: MiniData;
  supplyChainScore: number;
  totalPackageCounts: number;
} => {
  const transformedVulnData = vulnCounts.flatMap((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    return [
      { time: item.date, value: item.critical, type: "Critical" },
      { time: item.date, value: item.high, type: "High" },
      { time: item.date, value: item.medium, type: "Medium" },
      { time: item.date, value: item.low, type: "Low" },
      { time: item.date, value: item.unknown, type: "Unscored" },
      // { time: item.date, value: item.none, type: "None" },
    ];
  });

  const vulnTrendLineData = vulnCounts.map((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }
    const totalVuln =
      item.critical + item.high + item.medium + item.low + item.unknown;
    const percentCritical = totalVuln
      ? Math.round((item.critical / totalVuln) * 100)
      : 0;
    return {
      time: item.date,
      percent: percentCritical,
    };
  }) as TrendData[];

  const latestVulnDate = transformedVulnData.reduce(
    (max, item) => (item.time > max ? item.time : max),
    transformedVulnData[0]?.time
  );

  const allVulnCriticalPercents = allVulnCounts.map((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    const nom = item.critical;
    const denom =
      item.critical + item.high + item.medium + item.low + item.unknown;

    const percentCritical = denom ? Math.round((nom / denom) * 100) : 0;

    return percentCritical;
  }) as [number];
  const latestVulnData = transformedVulnData.filter(
    (item) => item.time === latestVulnDate
  );
  const latestAllVulnRawData = allVulnCounts.filter((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    return item.date === latestVulnDate;
  }) as VulnCount[];

  const radarData: PieData[] = latestVulnData.map((item) => ({
    name: item.type,
    value: item.value,
  }));

  const supplyChainVulnScore =
    100 - allVulnCriticalPercents[allVulnCriticalPercents.length - 1];

  const miniData = {
    numerator: latestAllVulnRawData[0].critical,
    denominator:
      latestAllVulnRawData[0].critical +
      latestAllVulnRawData[0].high +
      latestAllVulnRawData[0].medium +
      latestAllVulnRawData[0].low +
      latestAllVulnRawData[0].unknown,
    percents: allVulnCriticalPercents,
  };

  const totalPackageCounts =
    latestAllVulnRawData[0].critical +
    latestAllVulnRawData[0].high +
    latestAllVulnRawData[0].medium +
    latestAllVulnRawData[0].low +
    latestAllVulnRawData[0].unknown +
    latestAllVulnRawData[0].none;

  return {
    trendBarData: transformedVulnData,
    pieData: radarData,
    trendData: vulnTrendLineData,
    miniData: miniData,
    supplyChainScore: supplyChainVulnScore,
    totalPackageCounts: totalPackageCounts,
  };
};

export const transformEolData = (
  eolCounts: VulnCount[],
  allEolCounts: VulnCount[]
): {
  trendBarData: TrendBarData[];
  pieData: PieData[];
  trendData: TrendData[];
  miniData: MiniData;
  supplyChainScore: number;
  totalPackageCounts: number;
} => {
  const transformedEolData = eolCounts.flatMap((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    return [
      { time: item.date, value: item.critical, type: "Critical" },
      { time: item.date, value: item.high, type: "High" },
      { time: item.date, value: item.medium, type: "Medium" },
      { time: item.date, value: item.low, type: "Low" },
      { time: item.date, value: item.unknown, type: "Unscored" },
      // { time: item.date, value: item.none, type: "None" },
    ];
  });

  const eolTrendLineData = eolCounts.map((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }
    const totalEol =
      item.critical + item.high + item.medium + item.low + item.unknown;
    const percentCritical = totalEol
      ? Math.round((item.critical / totalEol) * 100)
      : 0;
    return {
      time: item.date,
      percent: percentCritical,
    };
  }) as TrendData[];

  const latestEolDate = transformedEolData.reduce(
    (max, item) => (item.time > max ? item.time : max),
    transformedEolData[0]?.time
  );

  const allEolCriticalPercents = allEolCounts.map((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    const nom = item.critical;
    const denom =
      item.critical + item.high + item.medium + item.low + item.unknown;

    const percentCritical = denom ? Math.round((nom / denom) * 100) : 0;

    return percentCritical;
  }) as [number];
  const latestEolData = transformedEolData.filter(
    (item) => item.time === latestEolDate
  );
  const latestAllEolRawData = allEolCounts.filter((item) => {
    if (!item) {
      return []; // Skip null or undefined items
    }

    return item.date === latestEolDate;
  }) as VulnCount[];

  const radarData: PieData[] = latestEolData.map((item) => ({
    name: item.type,
    value: item.value,
  }));

  const supplyChainEolScore =
    100 - allEolCriticalPercents[allEolCriticalPercents.length - 1];

  const miniData = {
    numerator: latestAllEolRawData[0].critical,
    denominator:
      latestAllEolRawData[0].critical +
      latestAllEolRawData[0].high +
      latestAllEolRawData[0].medium +
      latestAllEolRawData[0].low +
      latestAllEolRawData[0].unknown,
    percents: allEolCriticalPercents,
  };

  const totalPackageCounts =
    latestAllEolRawData[0].critical +
    latestAllEolRawData[0].high +
    latestAllEolRawData[0].medium +
    latestAllEolRawData[0].low +
    latestAllEolRawData[0].unknown +
    latestAllEolRawData[0].none;

  return {
    trendBarData: transformedEolData,
    pieData: radarData,
    trendData: eolTrendLineData,
    miniData: miniData,
    supplyChainScore: 60,
    totalPackageCounts: totalPackageCounts,
  };
};

export const calcOverallSupplyChainScore = (
  supplyChainVulnScore: number,
  supplyChainEolScore: number,
  supplyChainOssfScore: number
) => {
  const score =
    supplyChainVulnScore * 0.5 +
    supplyChainEolScore * 0.25 +
    supplyChainOssfScore * 0.25;

  if (score >= 97) return "A+";
  if (score >= 93) return "A";
  if (score >= 90) return "A-";
  if (score >= 87) return "B+";
  if (score >= 83) return "B";
  if (score >= 80) return "B-";
  if (score >= 77) return "C+";
  if (score >= 73) return "C";
  if (score >= 70) return "C-";
  if (score >= 67) return "D+";
  if (score >= 63) return "D";
  if (score >= 60) return "D-";
  return "F";
};
