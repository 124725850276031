import { EnumType, VariableType } from "json-to-graphql-query";
import { projectRecompose } from "../projects";
import { getRelativeTimeString } from "../helpers";
import { ColumnsType } from "antd/es/table";
import { Button, Col, Row, Tag, Tooltip } from "antd";
import SeverityColors from "../colors";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import { targetRecompose } from "../targets";

export const projectsColumns: ColumnsType<ProjectRowData> = [
  {
    title: "Project",
    dataIndex: "project",
    key: "project",
    width: "45%",
  },
  {
    title: "Dependencies",
    dataIndex: "dependencies",
    key: "dependencies",
    width: "8%",
    render(value, record) {
      const sbomIDsParams = record.sbomIDs
        .map((id: string) => `psbids=${id}`)
        .join("&");
      const inventoryUrl = `/inventory?m=dependencies&project=${encodeURIComponent(
        record.project
      )}&${sbomIDsParams}`;

      return (
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          <Button
            icon={<SearchOutlined />}
            onClick={() => {
              window.location.href = inventoryUrl;
            }}
            style={{ height: "25px", padding: "2px 7px" }}
            type="link"
          />
          <Tooltip title={value}>{value}</Tooltip>
        </div>
      );
    },
  },
  {
    title: "Vulns",
    dataIndex: "vulns",
    key: "vulns",
    width: "15%",
    render: (vulns: any) => {
      if (!vulns) return null;

      const isDisabled = (count: number) => count === 0;

      const contentStyle = (count: number) => ({
        color: count === 0 ? "grey" : "inherit",
      });

      return (
        <Row align="middle">
          <Col span={4.8}>
            <Tooltip title="cvss: unknown">
              <Tag
                bordered={true}
                color={
                  !isDisabled(vulns.unknown)
                    ? SeverityColors.Unknown
                    : "default"
                }
              >
                <span style={contentStyle(vulns.unknown)}>{vulns.unknown}</span>
              </Tag>
            </Tooltip>
          </Col>
          <Col span={4.8}>
            <Tooltip title="cvss: low">
              <Tag
                bordered={true}
                color={!isDisabled(vulns.low) ? SeverityColors.Low : "default"}
              >
                <span style={contentStyle(vulns.low)}>{vulns.low}</span>
              </Tag>
            </Tooltip>
          </Col>
          <Col span={4.8}>
            <Tooltip title="cvss: medium">
              <Tag
                bordered={true}
                color={
                  !isDisabled(vulns.medium) ? SeverityColors.Medium : "default"
                }
              >
                <span style={contentStyle(vulns.medium)}>{vulns.medium}</span>
              </Tag>
            </Tooltip>
          </Col>
          <Col span={4.8}>
            <Tooltip title="cvss: high">
              <Tag
                bordered={true}
                color={
                  !isDisabled(vulns.high) ? SeverityColors.High : "default"
                }
              >
                <span style={contentStyle(vulns.high)}>{vulns.high}</span>
              </Tag>
            </Tooltip>
          </Col>
          <Col span={4.8}>
            <Tooltip title="cvss: critical">
              <Tag
                bordered={true}
                color={
                  !isDisabled(vulns.critical)
                    ? SeverityColors.Critical
                    : "default"
                }
              >
                <span style={contentStyle(vulns.critical)}>
                  {vulns.critical}
                </span>
              </Tag>
            </Tooltip>
          </Col>
        </Row>
      );
    },
  },
  {
    title: "EOL",
    dataIndex: "eolCounts",
    key: "eolCounts",
    width: "10%",
    render: (eolCounts: any) => {
      const isDisabled = (count: number) => count === 0;

      const contentStyle = (count: number) => ({
        color: count === 0 ? "grey" : "inherit",
      });

      return (
        <Row align="middle">
          <Col span={6}>
            <Tooltip title="EOL in more than 12 months">
              <Tag
                bordered={true}
                color={
                  !isDisabled(eolCounts.low) ? SeverityColors.Low : "default"
                }
              >
                <span style={contentStyle(eolCounts.low)}>{eolCounts.low}</span>
              </Tag>
            </Tooltip>
          </Col>
          <Col span={6}>
            <Tooltip title="EOL within 12 months">
              <Tag
                bordered={true}
                color={
                  !isDisabled(eolCounts.medium)
                    ? SeverityColors.Medium
                    : "default"
                }
              >
                <span style={contentStyle(eolCounts.medium)}>
                  {eolCounts.medium}
                </span>
              </Tag>
            </Tooltip>
          </Col>
          <Col span={6}>
            <Tooltip title="EOL within 6 months">
              <Tag
                bordered={true}
                color={
                  !isDisabled(eolCounts.high) ? SeverityColors.High : "default"
                }
              >
                <span style={contentStyle(eolCounts.high)}>
                  {eolCounts.high}
                </span>
              </Tag>
            </Tooltip>
          </Col>
          <Col span={6}>
            <Tooltip title="EOL">
              <Tag
                bordered={true}
                color={
                  !isDisabled(eolCounts.critical)
                    ? SeverityColors.Critical
                    : "default"
                }
              >
                <span style={contentStyle(eolCounts.critical)}>
                  {eolCounts.critical}
                </span>
              </Tag>
            </Tooltip>
          </Col>
        </Row>
      );
    },
  },
  {
    title: "Seen",
    dataIndex: "seen",
    key: "seen",
    wdith: "10%",
  },
  {
    title: "",
    dataIndex: "",
    key: "sbomID",
    width: "8%",
  },
] as ColumnsType<ProjectRowData>;

export class ProjectQueryManager {
  private query: any;
  private project: string | null | undefined;
  private projectSbomIDs: (string | null)[] | never[];

  constructor(
    query: any,
    project: string | null | undefined,
    projectSbomIDs: (string | null)[] | never[]
  ) {
    this.query = query;
    this.project = project;
    this.projectSbomIDs = projectSbomIDs;
  }

  private rebuildAndQuery() {
    const andConditions = [];

    if (this.project && this.projectSbomIDs) {
      andConditions.push({
        hasCommitsWith: {
          hasHasSbomWith: {
            idIn: this.projectSbomIDs,
          },
        },
      });
    }

    this.query.query.projects.__args.where.and = andConditions;
  }

  getQuery() {
    this.rebuildAndQuery();
    return this.query;
  }
}

export const ProjectQueryPageSize = 25;

export const ProjectsQuery = {
  query: {
    __variables: {
      cursor: "Cursor",
    },
    projects: {
      __typename: true,
      __args: {
        after: new VariableType("cursor"),
        orderBy: [
          { field: new EnumType("TYPE"), direction: new EnumType("ASC") },
          { field: new EnumType("OWNER"), direction: new EnumType("ASC") },
          { field: new EnumType("NAMESPACE"), direction: new EnumType("ASC") },
          { field: new EnumType("NAME"), direction: new EnumType("ASC") },
        ],
        where: {
          and: [],
        },
        first: ProjectQueryPageSize,
      },
      totalCount: true,
      pageInfo: {
        endCursor: true,
        startCursor: true,
        hasNextPage: true,
      },
      edges: {
        node: {
          id: true,
          name: true,
          namespace: true,
          type: true,
          owner: true,
          commits: {
            __args: {
              orderBy: {
                field: new EnumType("CREATED_AT"),
                direction: new EnumType("DESC"),
              },
              first: 1,
            },
            edges: {
              node: {
                hash: true,
                createdAt: true,
                hasSbom: {
                  __args: {
                    first: 5,
                  },
                  edges: {
                    node: {
                      id: true,
                      package: {
                        id: true,
                        version: true,
                        name: {
                          name: true,
                          namespace: {
                            namespace: true,
                            type: {
                              type: true,
                            },
                          },
                        },
                      },
                      aggregateVulnCounts: {
                        low: true,
                        medium: true,
                        high: true,
                        critical: true,
                        unknown: true,
                      },
                      aggregateEolCounts: {
                        eol: true,
                        eol_lt6months: true,
                        eol_lt12months: true,
                        eol_gt12months: true,
                      },
                      hasPackageVersion: {
                        totalCount: true,
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
};

export type ProjectsApiResponse = {
  projects: {
    __typename: "ProjectConnection";
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
    };
    totalCount: number;
    edges: Array<{
      node: {
        id: string;
        name: string;
        namespace: string;
        type: string;
        owner: string;
        commits: {
          edges: Array<{
            node: {
              hash: string;
              createdAt: string;
              hasSbom: {
                edges: Array<{
                  node: {
                    id: string;
                    package: {
                      id: string;
                      version: string;
                      name: {
                        name: string;
                        namespace: {
                          namespace: string;
                          type: {
                            type: string;
                          };
                        };
                      };
                    };
                    aggregateVulnCounts: {
                      low: number;
                      medium: number;
                      high: number;
                      critical: number;
                      unknown: number;
                    };
                    aggregateEolCounts: {
                      eol: number;
                      eol_lt6months: number;
                      eol_lt12months: number;
                      eol_gt12months: number;
                    };
                    hasPackageVersion: {
                      totalCount: number;
                    };
                  };
                }>;
              };
            };
          }>;
        };
      };
    }>;
  };
};
interface VulnCounts {
  unknown: number;
  low: number;
  medium: number;
  high: number;
  critical: number;
}

interface EolCounts {
  low: number;
  medium: number;
  high: number;
  critical: number;
}

export interface TargetRowData {
  key: string;
  name: string;
  project: string;
  seen: string;
  vulnCounts: VulnCounts;
  eolCounts: EolCounts;
  dependencies: number;
  sbomID: string;
}

export interface ProjectRowData {
  id: string;
  vulns: VulnCounts;
  eolCounts: EolCounts;
  project: string;
  targets: TargetRowData[];
  dependencies: number;
  seen: string;
  sbomIDs: string[];
}

export const transformProjectsQuery = (
  data: ProjectsApiResponse
): ProjectRowData[] => {
  return data.projects.edges.map((edge) => {
    const node = edge.node;
    const project = projectRecompose({
      name: node.name,
      owner: node.owner,
      type: node.type,
      namespace: node.namespace,
    });

    const dependencies = node.commits.edges.reduce((acc, commitEdge) => {
      return (
        acc +
        commitEdge.node.hasSbom.edges.reduce((innerAcc, sbomEdge) => {
          return innerAcc + sbomEdge.node.hasPackageVersion.totalCount;
        }, 0)
      );
    }, 0);

    const seen =
      getRelativeTimeString(node.commits.edges[0]?.node.createdAt) || "";

    const totalVulnCounts: VulnCounts = {
      unknown: 0,
      low: 0,
      medium: 0,
      high: 0,
      critical: 0,
    };
    const totalEolCounts: EolCounts = {
      low: 0,
      medium: 0,
      high: 0,
      critical: 0,
    };

    const sbomIDs = node.commits.edges.flatMap((commitEdge) =>
      commitEdge.node.hasSbom.edges.map((sbomEdge) => sbomEdge.node.id)
    );

    const targets = node.commits.edges[0]?.node.hasSbom.edges.map(
      (sbomEdge) => {
        const vulnCounts = sbomEdge.node.aggregateVulnCounts;
        const eolCounts = sbomEdge.node.aggregateEolCounts;

        const transformedEolCounts: {
          critical: number;
          high: number;
          medium: number;
          low: number;
        } = {
          critical: eolCounts.eol,
          high: eolCounts.eol_lt6months,
          medium: eolCounts.eol_lt12months,
          low: eolCounts.eol_gt12months,
        };

        (Object.keys(vulnCounts) as Array<keyof VulnCounts>).forEach((key) => {
          totalVulnCounts[key] += vulnCounts[key];
        });

        (Object.keys(transformedEolCounts) as Array<keyof EolCounts>).forEach(
          (key) => {
            totalEolCounts[key] += transformedEolCounts[key];
          }
        );

        const packageInfo = sbomEdge.node.package;
        const name = targetRecompose(
          packageInfo.name.name,
          packageInfo.name.namespace.namespace,
          packageInfo.version
        );
        return {
          key: sbomEdge.node.id,
          name: name,
          vulnCounts,
          eolCounts: transformedEolCounts,
          dependencies: sbomEdge.node.hasPackageVersion.totalCount,
          project: project,
          seen: seen,
          sbomID: sbomEdge.node.id,
        };
      }
    );

    return {
      id: node.id,
      vulns: totalVulnCounts,
      eolCounts: totalEolCounts,
      project,
      sbomIDs: sbomIDs,
      targets,
      dependencies,
      seen,
    };
  });
};
