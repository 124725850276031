import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { useGetSbomS3UriLazyQuery } from "../../gql/graphql";
import { Button, message } from "antd";

interface DownloadSBOMButtonProps {
  sbomID?: string;
  disabled?: boolean;
}
const DownloadSBOMButton: React.FC<DownloadSBOMButtonProps> = ({
  sbomID,
  disabled,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  // because we're using presigned urls from S3, we can never cache the request
  // via apollo, hence the use of 'network-only' fetch policy
  const [getSbomS3Uri, { loading }] = useGetSbomS3UriLazyQuery({
    fetchPolicy: "network-only",
  });

  const handleDownloadClick = async () => {
    try {
      const result = await getSbomS3Uri({
        variables: {
          sbomID: sbomID,
        },
      });
      const queryData = result.data;
      if (queryData && queryData.getSbomS3URI.uri) {
        const link = document.createElement("a");
        link.href = queryData.getSbomS3URI.uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        messageApi.open({
          type: "error",
          content: "failed to download sbom",
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "failed to download sbom",
      });
    }
  };

  return (
    <Button
      style={{ height: "25px", padding: "0 7px" }}
      icon={<DownloadOutlined />}
      onClick={handleDownloadClick}
      disabled={loading || disabled}
      loading={loading}
    >
      SBOM
    </Button>
  );
};

export default DownloadSBOMButton;
