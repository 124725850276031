import { TinyArea } from "@ant-design/plots";
import React, { useMemo } from "react";

interface Props {
  data: any;
  height: number;
  disabled?: boolean;
}

const mockData = [10, 20, 40, 60, 40, 50, 30, 20, 30, 10];

const TinyAreaChart: React.FC<Props> = ({ data, height, disabled = false }) => {
  // this is needed so that the disabled chart is not redrawn on every interaction
  const memoizedAreaStyle = useMemo(() => {
    return disabled
      ? () => ({
          fill: "l(270) 0:#ffffff 1:#bfbfbf",
        })
      : undefined;
  }, [disabled]);

  const config = disabled
    ? {
        height: height,
        data: mockData,
        smooth: true,
        line: {
          color: "#bfbfbf",
        },
        areaStyle: memoizedAreaStyle,
      }
    : {
        height: height,
        data: data,
        smooth: true,
      };

  return disabled ? (
    <TinyArea {...config} style={{ pointerEvents: "none" }} />
  ) : (
    <TinyArea {...config} />
  );
};

export default TinyAreaChart;
