import { Button, Layout, Modal, Space, Timeline, Typography } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";
import { toHumanReadableDateTime } from "../utils/helpers";
import { DefaultService, OpenAPI, ScanHistory, ScansHistory } from "../client";

const { Content } = Layout;
const { Text } = Typography;

const HoverButtonStyle = {
  width: 20,
  margin: 0,
  padding: 0,
  color: "#73d13d",
  backgroundColor: "#f6ffed",
  borderColor: "#73d13d",
};

const ButtonStyle = {
  width: 20,
  margin: 0,
  padding: 0,
  color: "#52c41a",
  backgroundColor: "#d9f7be",
  borderColor: "#52c41a",
};

interface ScanHistoryButtonProps {
  imageName: string;
  projectName: string;
}

const ScanHistoryButton: React.FC<ScanHistoryButtonProps> = ({
  projectName,
  imageName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [scansHistory, setScansHistory] = useState<ScansHistory>();

  const { getAccessTokenSilently } = useAuth0();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleButtonClick = async () => {
    try {
      const token = await getAccessTokenSilently();
      OpenAPI.BASE = API_URL!;
      OpenAPI.HEADERS = {
        Authorization: `Bearer ${token}`,
      };
      await DefaultService.listSoftwareScanHistoryV1ScanHistoryGet(
        imageName,
        projectName
      ).then((response: ScansHistory) => {
        setScansHistory(response);
      });
    } catch (error) {
      console.error(error);
    }

    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderTimelineList = (scanHistory: ScansHistory) => {
    return scanHistory.scans.flatMap((scan: ScanHistory) => ({
      color: "#52c41a",
      children: (
        <Space direction="vertical">
          <Text strong>{toHumanReadableDateTime(scan.scanDate)}</Text>
          <Text type="secondary">
            Scanned image tag: <Text strong>{scan.imageTag}</Text>
          </Text>
        </Space>
      ),
    }));
  };

  return (
    <Content>
      <Button icon={<HistoryOutlined />} onClick={handleButtonClick}>
        Scan History
      </Button>
      <Modal
        title={
          <Space direction="horizontal">
            <Text>Scan History</Text>
          </Space>
        }
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="scan-history-cancel-btn" onClick={handleCancel}>
            Cancel
          </Button>,
          null,
        ]}
      >
        {scansHistory?.scans && scansHistory?.scans?.length > 0 && (
          <div style={{ maxHeight: 500, overflowY: "auto", padding: 24 }}>
            <Timeline items={renderTimelineList(scansHistory)} />
          </div>
        )}
      </Modal>
    </Content>
  );
};

export default ScanHistoryButton;
