import { Layout, Space, Row, Col, Button, Tooltip } from "antd";
import Table from "antd/es/table";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  useQueryParam,
  StringParam,
  withDefault,
  ArrayParam,
  ObjectParam,
} from "use-query-params";
import { PackageNode } from "../gql/graphql";
import { dependenciesColumns } from "../utils/dependencies";
import { gql, useApolloClient, useQuery } from "@apollo/client";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { CursorPagination } from "../components/CursorPagination";
import {
  DependenciesApiResponse,
  DependenciesQuery,
  DependenciesQueryPageSize,
  DependencyQueryManager,
  transformDependenciesQuery,
} from "../utils/queries/dependencies";
import DependencySelectFilter from "../components/filters/DependencySelectFilter";
import { DownloadOutlined } from "@ant-design/icons";
import { format } from "date-fns";

const { Content } = Layout;

const contentStyle = {
  margin: 12,
  width: "100%",
  minWidth: 800,
};

const generateCsv = (data: (PackageNode & { projects: string[] })[]) => {
  const headers = [
    "ecosystem",
    "label",
    "version",
    "ossfScore",
    "eol",
    "vulnerabilities"
  ] as const;

  const attributesMappers: {
    [key in (typeof headers)[number]]: (
      node: PackageNode & { projects: string[] }
    ) => string;
  } = {
    ecosystem: (node) => node.ecosystem,
    label: (node) => node.label,
    version: (node) => node.version,
    ossfScore: (node) =>
      `${node.attributes?.ossfScore?.score.toFixed(1) || ""}`,
    eol: (node) =>
      node.attributes?.eol?.date
        ? format(new Date(node.attributes?.eol?.date), "yyyy-MM-dd")
        : "",
    vulnerabilities: (node) =>
      `"${node.attributes?.vulns?.map((v) => v.vulnID).join(",") || ""}"`
  };

  const rows = data.map((node) => {
    return headers
      .map((header) => {
        return attributesMappers[header](node);
      })
      .join(",");
  });

  return [headers.join(",")].concat(rows).join("\n");
};


const DependenciesPage: React.FC = () => {
  const [type, setType] = useQueryParam("type", withDefault(ArrayParam, []));
  const [namespace, setNamespace] = useQueryParam("namespace", StringParam);
  const [name, setName] = useQueryParam("name", StringParam);
  const [nameSelect, setNameSelect] = useState<string | undefined>(undefined); // Used for the select component
  const [project, setProject] = useQueryParam("project", StringParam);
  const [target, setTarget] = useQueryParam("target", StringParam);
  const [isRefetching, setIsRefetching] = useState(false);
  const [projectSbomIDs, setProjectSbomIDs] = useQueryParam(
    "psbids",
    withDefault(ArrayParam, [])
  );
  const [cvssLevels, setCvssLevels] = useQueryParam(
    "cvss",
    withDefault(ArrayParam, [])
  );
  const [lifecycleLevels, setLifecycleLevels] = useQueryParam(
    "lifecycle",
    withDefault(ArrayParam, [])
  );
  const [lifecycleReasons, setLifecycleReasons] = useQueryParam(
    "lifecycleReasons",
    withDefault(ArrayParam, [])
  );
  const [scoreLevels, setScoreLevels] = useQueryParam(
    "score",
    withDefault(ArrayParam, [])
  );
  const [vulnerability, setVulnerability] = useQueryParam<
    | {
      label: string;
      value: string;
    }
    | undefined
  >("vulnerability", {
    encode: ObjectParam.encode,
    decode: ObjectParam.decode as () => { label: string; value: string },
  });

  const [exportLoading, setExportLoading] = useState(false);

  const [cursor, setCursor] = useState<string | null | undefined>(undefined);
  const [currentQuery, setCurrentQuery] = useState(DependenciesQuery);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<
    string | null | undefined
  >();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data, loading, error, refetch } = useQuery<DependenciesApiResponse>(
    gql`
      ${jsonToGraphQLQuery(currentQuery)}
    `,
    { context: { path: "/query" } }
  );

  const apolloClient = useApolloClient();

  useEffect(() => {
    const newQuery = new DependencyQueryManager(
      currentQuery,
      type,
      namespace,
      name,
      cvssLevels,
      scoreLevels,
      project,
      projectSbomIDs,
      lifecycleLevels,
      lifecycleReasons,
      vulnerability
    );
    setCurrentQuery(newQuery.getQuery());
  }, [
    type,
    namespace,
    name,
    scoreLevels,
    cvssLevels,
    lifecycleLevels,
    projectSbomIDs,
    project,
    currentQuery,
    lifecycleReasons,
    vulnerability,
  ]);

  const memoizedType = useMemo(() => type, [JSON.stringify(type)]);
  useEffect(() => {
    setIsRefetching(true);
    refetch({
      type: memoizedType,
      namespace: namespace,
      name: name,
      cursor: cursor,
    }).then(() => {
      setIsRefetching(false);
    });
  }, [memoizedType, namespace, name, cursor, refetch]);

  useEffect(() => {
    setLastEvaluatedKey(data?.packageVersions.pageInfo.endCursor);

    // Calculate total pages based on totalCount and page size
    const totalCount = data?.packageVersions.totalCount;
    let totalPages = 0;
    if (typeof totalCount === "number" && totalCount > 0) {
      totalPages = Math.ceil(totalCount / DependenciesQueryPageSize);
    }

    setTotalPages(totalPages);
  }, [data]);

  const onChange = (
    newEvaluatedKey: string | null | undefined,
    currentPage: number
  ) => {
    setCursor(newEvaluatedKey);
    setCurrentPage(currentPage);
  };

  const filters = {
    type,
    namespace,
    nameSelect,
    name,
    project,
    cvssLevels,
    scoreLevels,
    lifecycleLevels,
    target,
    projectSbomIDs,
    lifecycleReasons,
    vulnerability,
  };

  const handleFilterChange = (
    filterName: string,
    option:
      | { label: string; value: string }
      | { label: string; value: string }[]
  ) => {
    setCurrentPage(1);

    switch (filterName) {
      case "type":
        option = option as { label: string; value: string }[];
        setType(option?.map((opt) => opt.value) || undefined);
        break;
      case "namespace":
        option = option as { label: string; value: string };
        setNamespace(option?.label || undefined);
        break;
      case "name":
        option = option as { label: string; value: string };
        setName(option?.label || undefined);
        break;
      case "nameSelect":
        option = option as { label: string; value: string };
        setNameSelect(option?.label || undefined);
        break;
      case "project":
        option = option as { label: string; value: string };
        setProject(option?.label || undefined);
        setProjectSbomIDs(option?.value.split(",") || []);
        break;
      case "target":
        option = option as { label: string; value: string };
        setTarget(option?.label || undefined);
        setProjectSbomIDs(option?.value.split(",") || []);
        break;
      case "cvssLevels":
        option = option as { label: string; value: string }[];
        setCvssLevels(option?.map((opt) => opt.value) || undefined);
        break;
      case "scoreLevels":
        option = option as { label: string; value: string }[];
        setScoreLevels(option?.map((opt) => opt.value) || undefined);
        break;
      case "lifecycleLevels":
        option = option as { label: string; value: string }[];
        setLifecycleLevels(option?.map((opt) => opt.value) || undefined);
        break;
      case "lifecycleReasons":
        option = option as { label: string; value: string }[];
        setLifecycleReasons(option?.map((opt) => opt.value) || undefined);
        break;
      case "vulnerability":
        option = option as { label: string; value: string };
        setVulnerability(option?.value ? option : undefined);
        break;
      default:
        break;
    }
  };

  return (
    <Content style={contentStyle}>
      <Helmet>
        <title>Dependencies - Xeol</title>
      </Helmet>
      <Space style={{ width: "98%" }} direction="vertical" size="large">
        <Space
          style={{ justifyContent: "space-between", width: "100%" }}
          direction="horizontal"
        >
          <DependencySelectFilter
            filters={filters}
            enabledFilters={{
              type: true,
              namespace: true,
              name: true,
              nameSelect: true,
              project: true,
              projectSbomIDs: true,
              target: true,
              cvssLevels: true,
              scoreLevels: true,
              lifecycleLevels: true,
              lifecycleReasons: true,
              vulnerability: true,
            }}
            onFilterChange={handleFilterChange}
          />
          <Tooltip
            placement="left"
            title="Export the table with selected filters to a CSV file"
          >
            <Button
              icon={<DownloadOutlined />}
              loading={exportLoading}
              onClick={async () => {
                setExportLoading(true);
                const queryObject = new DependencyQueryManager(
                  currentQuery,
                  type,
                  namespace,
                  name,
                  cvssLevels,
                  scoreLevels,
                  project,
                  projectSbomIDs,
                  lifecycleLevels,
                  lifecycleReasons,
                  vulnerability
                ).getQuery({ ignorePagination: true });

                const query = gql`
                  ${jsonToGraphQLQuery(queryObject)}
                `;

                const { data } =
                  await apolloClient.query<DependenciesApiResponse>({
                    context: { path: "/query" },
                    query,
                  });

                const csvBlob = new Blob(
                  [generateCsv(data ? transformDependenciesQuery(data) : [])],
                  { type: "text/csv" }
                );

                const url = window.URL.createObjectURL(csvBlob);
                const urlParams = new URLSearchParams(window.location.search);
                urlParams.delete("m");

                const a = document.createElement("a");
                a.href = url;
                a.download = `${[
                  format(new Date(), "yyyy-MM-dd-HH-mm-ss"),
                  "dependencies",
                  urlParams.toString(),
                ]
                  .filter((s) => !!s)
                  .join("-")}.csv`;
                a.click();

                window.URL.revokeObjectURL(url);

                setExportLoading(false);
              }}
            >
              export
            </Button>
          </Tooltip>
        </Space>
        <Table
          size="small"
          rowClassName="fixed-height-row"
          dataSource={data ? transformDependenciesQuery(data) : []}
          columns={dependenciesColumns}
          loading={loading || isRefetching}
          locale={{ emptyText: "No dependencies found" }}
          rowKey="id"
          onRow={(record: PackageNode) => {
            return {
              onClick: () => {
                if (record.qualifiers && record.qualifiers.length > 0) {
                  const qualifiersObject = Object.fromEntries(
                    record.qualifiers.map((q) => [q.key, q.value])
                  );
                  const base64Qualifiers = btoa(
                    JSON.stringify(qualifiersObject)
                  );
                  window.location.href = `/package/${record.ecosystem
                    }/${encodeURIComponent(record.label)}/${record.version
                    }?qualifiers=${base64Qualifiers}`;
                } else {
                  window.location.href = `/package/${record.ecosystem
                    }/${encodeURIComponent(record.label)}/${record.version}`;
                }
              },
            };
          }}
          pagination={false}
        />
        <Row>
          <Col>
            <CursorPagination
              lastEvaluatedKey={lastEvaluatedKey}
              onChange={onChange}
              size={"small"}
              totalPages={totalPages}
              currentPage={currentPage}
            />
          </Col>
        </Row>
      </Space>
    </Content>
  );
};

export default DependenciesPage;
