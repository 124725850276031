import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, Avatar } from "antd";
import { message } from "antd";
import { LoginOutlined } from '@ant-design/icons';

const LoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const loginPageStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  };

  const avatarStyle = {
    marginBottom: 24,
  };

  const cardStyle = {
    width: 300,
  };

  const cardBodyStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get("error");
    const error_description = params.get("error_description");

    if (error) {
      if (
        error_description == "User is not assigned to the client application."
      ) {
        setErrorMsg(
          "This user has not been assigned to the Xeol application in Okta"
        );
      } else {
        setErrorMsg(error_description);
      }
    }
    const invitation = params.get("invitation");
    const organization = params.get("organization");

    if (!invitation || !organization) return;

    loginWithRedirect({
      authorizationParams: {
        invitation,
        organization,
      },
    });
  }, [loginWithRedirect, location.search]);

  useEffect(() => {
    if (errorMsg) {
      message.error(errorMsg);
    }
  }, [errorMsg]); // Only run when errorMsg changes

  return (
    <div style={loginPageStyle}>
      <Card style={cardStyle} bodyStyle={cardBodyStyle}>
        <Avatar
          style={avatarStyle}
          size={64}
          src="https://s3.amazonaws.com/static.xeol.io/xeol-logo/logo-white-bg.png"
        />
        <Button
          icon={<LoginOutlined />}
          onClick={() => loginWithRedirect()}
        >
          Login
        </Button>
      </Card>
    </div>
  );
};

export default LoginPage;
