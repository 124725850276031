import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  FormSelectQuery,
  FormSelectQueryResponse,
} from "../../utils/queries/formSelect";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import { Form } from "antd";
import { LightFilter, ProFormSelect } from "@ant-design/pro-components";
import React from "react";
import { projectRecompose } from "../../utils/projects";
import { targetRecompose } from "../../utils/targets";
import NotFoundSelect from "./NotFoundSelect";
import { Content } from "antd/es/layout/layout";
import { PackageNodeEolReason } from "../../gql/graphql";

interface FilterParams {
  type?: (string | null)[] | undefined | null;
  namespace?: string | undefined | null;
  nameSelect?: string | undefined | null;
  name?: string | undefined | null;
  cvssLevels?: (string | null)[] | undefined | null;
  scoreLevels?: (string | null)[] | undefined | null;
  lifecycleLevels?: (string | null)[] | undefined | null;
  project?: string | undefined | null;
  projectSbomIDs?: (string | null)[] | undefined | null;
  target?: string | undefined | null;
  lifecycleReasons?: (string | null)[] | undefined | null;
  vulnerability?:
    | {
        [key: string]: string | undefined;
      }
    | null
    | undefined;
}

interface AvailableFilters {
  type?: boolean;
  namespace?: boolean;
  nameSelect?: boolean;
  name?: boolean;
  cvssLevels?: boolean;
  scoreLevels?: boolean;
  lifecycleLevels?: boolean;
  project?: boolean;
  projectSbomIDs?: boolean;
  target?: boolean;
  lifecycleReasons?: boolean;
  vulnerability?: boolean;
}

interface DependencySelectComponentProps {
  className?: string;
  enabledFilters?: AvailableFilters;
  filters: FilterParams;
  onFilterChange: (
    filterName: keyof FilterParams,
    option:
      | { label: string; value: string }
      | { label: string; value: string }[],
    meta?: any
  ) => void;
}

const DependencySelectFilter: React.FC<DependencySelectComponentProps> = ({
  className,
  filters,
  enabledFilters,
  onFilterChange,
}) => {
  interface TargetMap {
    [projectName: string]: { label: string; value: string }[];
  }
  interface ProjectIDMap {
    [projectName: string]: string;
  }

  interface ProjectSbomIDMap {
    [projectName: string]: string[];
  }

  const [form] = Form.useForm();
  const [packageTypeOpts, setPackageTypeOpts] = useState<
    { label: string; value: string }[]
  >([]);
  const [packageNameOpts, setPackageNameOpts] = useState<
    { label: string; value: string }[]
  >([]);
  const [packageNamespaceOpts, setPackageNamespaceOpts] = useState<
    { label: string; value: string }[]
  >([]);
  const [projectsOpts, setProjectsOpts] = useState<
    { label: string; value: string }[]
  >([]);
  const [targetOpts, setTargetOpts] = useState<
    { label: string; value: string }[]
  >([]);
  const [targetMap, setTargetMap] = useState<TargetMap>({});
  const [vulnerabilityOpts, setVulnerabilityOpts] = useState<
    { label: string; value: string }[]
  >([]);

  const [projectIDMap, setProjectIDMap] = useState<ProjectIDMap>({});
  const [projectSbomIDMap, setProjectSbomIDMap] = useState<ProjectSbomIDMap>(
    {}
  );

  const { data, loading, error } = useQuery<FormSelectQueryResponse>(
    gql`
      ${jsonToGraphQLQuery(FormSelectQuery)}
    `,
    {
      context: { path: "/query" },
      variables: {
        type: filters.type,
        namespace: filters.namespace,
        name: filters.nameSelect,
      },
    }
  );

  useEffect(() => {
    if (!loading && data && data.projects) {
      const projectOptions = data.projects.edges.map((pkg) => {
        const sbomIDs = pkg.node.commits.edges
          .flatMap((commit) =>
            commit.node.hasSbom.edges.map((sbom) => sbom.node.id)
          )
          .join(",");

        const projectID = pkg.node.id;

        setProjectIDMap((prev) => {
          prev[projectRecompose(pkg.node)] = projectID;
          return prev;
        });

        setProjectSbomIDMap((prev) => {
          prev[projectRecompose(pkg.node)] = sbomIDs.split(",");
          return prev;
        });

        return {
          label: projectRecompose({
            name: pkg.node.name,
            owner: pkg.node.owner,
            type: pkg.node.type,
            namespace: pkg.node.namespace,
          }),
          value: enabledFilters?.projectSbomIDs ? sbomIDs : projectID,
        };
      });

      setProjectsOpts(projectOptions);

      const newTargetMap = data.projects.edges.reduce<TargetMap>((acc, pkg) => {
        const projectName = projectRecompose({
          name: pkg.node.name,
          owner: pkg.node.owner,
          type: pkg.node.type,
          namespace: pkg.node.namespace,
        });
        const targets = pkg.node.commits.edges.flatMap((commit) =>
          commit.node.hasSbom.edges.map((sbom) => {
            const { version, name } = sbom.node.package;
            const targetLabel = targetRecompose(
              name.name,
              name.namespace.namespace,
              version
            );
            return {
              label: targetLabel,
              value: sbom.node.id,
            };
          })
        );

        acc[projectName] = targets;
        return acc;
      }, {});

      setTargetMap(newTargetMap);
    }
  }, [data, loading, enabledFilters?.projectSbomIDs]);

  useEffect(() => {
    if (!loading && data && data.packageTypes) {
      const uniqueTypes = new Set<string>();
      const newOptions = data.packageTypes.edges.reduce<
        { label: string; value: string }[]
      >((options, pkg) => {
        if (!uniqueTypes.has(pkg.node.type)) {
          uniqueTypes.add(pkg.node.type);
          options.push({
            label: pkg.node.type,
            value: pkg.node.type,
          });
        }
        return options;
      }, []);

      setPackageTypeOpts(newOptions);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!loading && data && data.packageNames) {
      const uniqueNames = new Set(); // Create a set to track unique names
      const newOptions = data.packageNames.edges.reduce<
        { label: string; value: string }[]
      >((options, pkg) => {
        if (!uniqueNames.has(pkg.node.name)) {
          uniqueNames.add(pkg.node.name);
          options.push({
            label: pkg.node.name,
            value: pkg.node.name,
          });
        }
        return options;
      }, []);

      setPackageNameOpts(newOptions);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!loading && data && data.packageNamespaces) {
      const uniqueNamespaces = new Set<string>();
      const newOptions = data.packageNamespaces.edges.reduce<
        { label: string; value: string }[]
      >((options, pkg) => {
        if (!uniqueNamespaces.has(pkg.node.namespace)) {
          uniqueNamespaces.add(pkg.node.namespace);
          options.push({
            label: pkg.node.namespace,
            value: pkg.node.namespace,
          });
        }
        return options;
      }, []);

      setPackageNamespaceOpts(newOptions);
    }
  }, [data, loading]);

  useEffect(() => {
    if (!loading && data && data.isVulnerabilities) {
      setVulnerabilityOpts(
        data.isVulnerabilities.map((vuln) => {
          const osvIdParts = vuln.osv.osvID.split("-");

          const osvID = osvIdParts[0]
            .toUpperCase()
            .concat("-", osvIdParts.slice(1).join("-"));

          return {
            label: osvID,
            value: vuln.osv.id,
          };
        })
      );
    }
  }, [data, loading]);

  const resetTargetSelect = () => {
    setTargetOpts([]);
    form.setFieldsValue({ target: undefined });
    onFilterChange("target", { label: "", value: "" });
  };

  useEffect(() => {
    if (filters.project) {
      const targetsForProject = targetMap[filters.project] || [];
      if (targetsForProject.length > 1) {
        setTargetOpts(targetsForProject);
      }
    }
  }, [filters.project, targetMap]);

  return (
    <Content className={className}>
      <LightFilter size="middle" collapse={false} loading={loading} form={form}>
        {enabledFilters?.type && (
          <ProFormSelect
            name="ecosystem"
            label="ecosystem"
            mode="multiple"
            showSearch
            allowClear
            initialValue={
              filters?.type && filters.type.length > 0
                ? filters.type
                : undefined
            }
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              onFilterChange("type", option);
            }}
            fieldProps={{
              notFoundContent: <NotFoundSelect />,
            }}
            options={packageTypeOpts}
          />
        )}
        {enabledFilters?.namespace && (
          <ProFormSelect
            name="namespace"
            label="namespace"
            disabled={packageNamespaceOpts.length === 0}
            showSearch
            allowClear
            initialValue={filters.namespace}
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              onFilterChange("namespace", option);
            }}
            fieldProps={{
              notFoundContent: <NotFoundSelect />,
            }}
            options={packageNamespaceOpts}
          />
        )}
        {enabledFilters?.name && (
          <ProFormSelect
            name="name"
            placeholder="name"
            allowClear
            showSearch
            initialValue={filters.name}
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              onFilterChange("name", option);
            }}
            fieldProps={{
              onSearch: (value) => {
                onFilterChange("nameSelect", { label: value, value: value });
              },
              onClear: () => {
                onFilterChange("nameSelect", {
                  label: "",
                  value: "",
                });
              },
              notFoundContent: <NotFoundSelect />,
            }}
            options={packageNameOpts}
          />
        )}
        {enabledFilters?.cvssLevels && (
          <ProFormSelect
            name="cvss"
            label="cvss"
            mode="multiple"
            valueEnum={{
              low: "Low",
              medium: "Medium",
              high: "High",
              critical: "Critical",
              unknown: "Unknown",
            }}
            initialValue={
              filters?.cvssLevels && filters.cvssLevels.length > 0
                ? filters.cvssLevels
                : undefined
            }
            fieldProps={{
              notFoundContent: <NotFoundSelect />,
            }}
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              onFilterChange("cvssLevels", option);
            }}
          />
        )}
        {enabledFilters?.lifecycleLevels && (
          <ProFormSelect
            name="eol"
            label="eol"
            mode="multiple"
            valueEnum={{
              critical: "currently EOL",
              high: "EOL in <6mo",
              medium: "EOL in <12mo",
              low: "EOL in >12mo",
            }}
            fieldProps={{
              notFoundContent: <NotFoundSelect />,
            }}
            initialValue={
              filters?.lifecycleLevels && filters.lifecycleLevels.length > 0
                ? filters.lifecycleLevels
                : undefined
            }
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              onFilterChange("lifecycleLevels", option);
            }}
          />
        )}
        {enabledFilters?.lifecycleReasons && (
          <ProFormSelect
            name="eol reason"
            label="eol reason"
            mode="multiple"
            valueEnum={{
              [PackageNodeEolReason.RegistryDeprecated]: "registry deprecated",
              [PackageNodeEolReason.SourceArchived]: "source archived",
              [PackageNodeEolReason.VendorAnnounced]: "vendor announced",
            }}
            initialValue={
              filters?.lifecycleReasons && filters.lifecycleReasons.length > 0
                ? filters.lifecycleReasons
                : undefined
            }
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              onFilterChange("lifecycleReasons", option);
            }}
          />
        )}
        {enabledFilters?.scoreLevels && (
          <ProFormSelect
            name="score"
            label="score"
            mode="multiple"
            valueEnum={{
              low: "0 - 3.9",
              medium: "4.0 - 6.9",
              high: "7.0 - 8.9",
              superb: "9.0 - 10.0",
              unknown: "unknown",
            }}
            fieldProps={{
              notFoundContent: <NotFoundSelect />,
            }}
            initialValue={
              filters?.scoreLevels && filters.scoreLevels.length > 0
                ? filters.scoreLevels
                : undefined
            }
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              onFilterChange("scoreLevels", option);
            }}
          />
        )}
        {enabledFilters?.project && (
          <ProFormSelect
            name="project"
            label="project"
            showSearch
            allowClear
            initialValue={filters.project}
            options={projectsOpts}
            fieldProps={{
              autoClearSearchValue: true,
              onClear: resetTargetSelect,
              notFoundContent: <NotFoundSelect />,
            }}
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              resetTargetSelect();

              if (!option) {
                onFilterChange("project", option, null);
                return;
              } else {
                const meta = {
                  projectID:
                    projectIDMap[
                      Array.isArray(option) ? option[0].label : option.label
                    ],
                  projectSbomIDs:
                    projectSbomIDMap[
                      Array.isArray(option) ? option[0].label : option.label
                    ],
                };

                onFilterChange("project", option, meta);

                let projectName: string;
                if (Array.isArray(option)) {
                  projectName = option[0].label;
                } else {
                  projectName = option.label;
                }

                const targetsForProject = targetMap[projectName] || [];
                if (targetsForProject.length > 1) {
                  setTargetOpts(targetsForProject);
                }
              }
            }}
          />
        )}
        {enabledFilters?.target && targetOpts.length > 1 && (
          <ProFormSelect
            noStyle={true}
            name="target"
            label="target"
            showSearch
            allowClear
            options={targetOpts}
            fieldProps={{
              onClear: resetTargetSelect,
              notFoundContent: <NotFoundSelect />,
            }}
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              form.setFieldsValue({ target: value });
              onFilterChange("target", option);
            }}
          />
        )}
        {enabledFilters?.vulnerability && (
          <ProFormSelect
            name="vulnerability"
            label="vulnerability"
            showSearch
            allowClear
            initialValue={filters.vulnerability?.["value"]}
            options={vulnerabilityOpts}
            fieldProps={{
              autoClearSearchValue: true,
              notFoundContent: <NotFoundSelect />,
            }}
            onChange={(
              value: string,
              option:
                | { label: string; value: string }
                | { label: string; value: string }[]
            ) => {
              onFilterChange("vulnerability", option);
            }}
          />
        )}
      </LightFilter>
    </Content>
  );
};

export default DependencySelectFilter;
