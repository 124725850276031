import { Descriptions, Menu, MenuProps, Space, Tag, Typography } from "antd";
import type { DescriptionsProps } from "antd";
import { DoubleLeftOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-components";
import { useParams } from "react-router-dom";

import { PackageQualifierSpec, usePackageQuery } from "../gql/graphql";
import Loading from "../components/Loading";
import OSSFScore from "../components/OssfScore";
import Vulnerabilities from "../components/Vulnerabilities";
import PackageProjects from "../components/PackageProjects";
import { Content } from "antd/es/layout/layout";
import { Helmet } from "react-helmet-async";
import { ecosystemColorMap, extractNameAndNamespace } from "../utils/helpers";
import { StringParam, useQueryParam } from "use-query-params";
import { format } from "date-fns";
import { EolReasonTag } from "../components/tags/EolReasonTag";

const { Link } = Typography;

const PackagePage: React.FC = () => {
  const { type, namespaceandname, version } = useParams();
  const [base64Qualifiers, _] = useQueryParam("qualifiers", StringParam);

  const pkgQualifierSpec = (
    base64Qualifiers: string | null | undefined
  ): PackageQualifierSpec[] => {
    if (!base64Qualifiers) {
      return [];
    }

    let qualifiers: PackageQualifierSpec[] = [];
    const decodedQualifiers = atob(base64Qualifiers);
    const parsedQualifiers = JSON.parse(decodedQualifiers);

    qualifiers = Object.keys(parsedQualifiers).map((key) => ({
      key,
      value: parsedQualifiers[key],
    }));

    return qualifiers;
  };

  const { name, namespace, decodedNameAndNamespace } =
    extractNameAndNamespace(namespaceandname);
  const qualifiers = pkgQualifierSpec(base64Qualifiers);

  const { data, loading, error } = usePackageQuery({
    variables: {
      pkgSpec: {
        type: type,
        name: name,
        namespace: namespace,
        version: version,
        qualifiers: qualifiers,
      },
    },
  });

  const headerItems: MenuProps["items"] = [
    {
      key: "1",
      label: "Projects",
    },
  ];

  const contentStyle = {
    padding: 12,
    height: "100%",
    width: "100%",
    minWidth: 800,
  };

  if (data) {
    const items: DescriptionsProps["items"] = [
      {
        key: "ecosystem",
        label: "ecosystem",
        children: (
          <Tag
            bordered={false}
            color={ecosystemColorMap[type as keyof typeof ecosystemColorMap]}
          >
            {type}
          </Tag>
        ),
      },
      {
        key: "source",
        label: "source",
        children: data.package.package?.source?.repo ? (
          <Link
            href={data.package.package?.source?.repo}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.package.package?.source?.repo}
          </Link>
        ) : (
          "-"
        ),
      },
      {
        key: "qualifiers",
        label: "qualifiers",
        children: (
          <Content>
            {data.package?.package?.qualifiers?.length === 0 && "-"}
            {data.package?.package?.qualifiers?.map((qualifier) => (
              <Tag color="blue" key={qualifier.key}>
                {qualifier.key}: {qualifier.value}
              </Tag>
            ))}
          </Content>
        ),
      },
      {
        key: "score",
        label: "score",
        children: (
          <OSSFScore score={data.package?.package?.attributes?.ossfScore} />
        ),
      },
      {
        key: "vulns",
        label: "vulns",
        children: (
          <Vulnerabilities
            vulns={data.package?.package?.attributes?.vulns || []}
          />
        ),
      },
      {
        key: "eol",
        label: "eol",
        children: data?.package.package.attributes?.eol?.date ? (
          <Space direction="horizontal">
            {format(
              new Date(data.package.package.attributes.eol.date),
              "MMMM d, yyyy"
            )}
            <EolReasonTag node={data.package.package}></EolReasonTag>
          </Space>
        ) : (
          "-"
        ),
      },
    ];

    return (
      <Content style={contentStyle}>
        <Space style={{ width: "100%" }} direction="vertical">
          <Helmet>
            <title>
              {data.package.package?.label} - {data.package.package?.ecosystem}{" "}
              - Xeol
            </title>
          </Helmet>
          <PageHeader
            title={decodedNameAndNamespace}
            subTitle={version}
            backIcon={<DoubleLeftOutlined />}
            onBack={() => window.history.back()}
          >
            <Descriptions size="middle" items={items} />
          </PageHeader>
          <Menu
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            items={headerItems}
          />
          {data.package.projects && (
            <PackageProjects
              projects={data.package.projects}
              pkg={data.package.package}
            />
          )}
        </Space>
      </Content>
    );
  }

  return <Loading view={false} />;
};

export default PackagePage;
