import {
  LightFilter,
  PageHeader,
  ProFormSelect,
} from "@ant-design/pro-components";
import {
  Alert,
  Card,
  Col,
  Collapse,
  Descriptions,
  Drawer,
  DrawerProps,
  Empty,
  Input,
  Row,
  Space,
  Table,
  Tag,
  TagProps,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import Link from "antd/lib/typography/Link";
import { addYears, format, formatDistance } from "date-fns";
import Fuse from "fuse.js";
import { group, omit } from "radash";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import Loading from "../components/Loading";
import OSSFScore from "../components/OssfScore";
import Vulnerabilities from "../components/Vulnerabilities";
import {
  ImpactfulPackageNode,
  PackageNode,
  useGetImpactfulPackagesForTenantQuery,
  useGetUpgradeableVersionsForPackageVersionQuery,
  usePackageQuery,
} from "../gql/graphql";
import SeverityColors from "../utils/colors";
import { ecosystemColorMap, toHumanReadableDateTime } from "../utils/helpers";
import { EolReasonTag } from "../components/tags/EolReasonTag";
import { DistanceDate } from "../components/dates/DistanceDate";

type ImpactFilterCategory = "Low" | "Medium" | "High" | "Critical";
type EolFilterRange = [number, number];

const getImpactCategory = (impact: number): ImpactFilterCategory => {
  // Impact is a number between 0 and 1000
  switch (true) {
    case impact < 125:
      return "Low";
    case impact < 250:
      return "Medium";
    case impact < 375:
      return "High";
    default:
      return "Critical";
  }
};

const getTargetColor = (target: string) => {
  switch (target) {
    case "Image":
      return "geekblue";
    case "Directory":
      return "lime";
    default:
      return "default";
  }
};

const getUpgradeText = ({
  upgradeableVersions,
}: {
  upgradeableVersions: string[];
}) => {
  if (upgradeableVersions.length === 0) {
    return {
      message: (
        <Space direction="horizontal">
          <Tag color={SeverityColors.Critical}>REPLACE</Tag>
        </Space>
      ),
      descriptionText:
        "Either there are no newer versions of this package, or each newer version is EOL and / or has vulnerabilities. You may need to replace this package with a different one.",
    };
  }

  return {
    message: (
      <Space direction="horizontal">
        <Tag color={SeverityColors.High}>UPGRADE</Tag>
      </Space>
    ),
    descriptionText:
      "There are newer versions of this package that are not end of life and have no known vulnerabilities presented below. We recommend upgrading to one of them. Note that major version upgrades may require code changes.",
  };
};

const TagGrid = (props: {
  tags: string[];
  colorFunc?: (tag: string) => string;
}) => {
  return (
    <Row gutter={[8, 8]}>
      {props.tags.map((tag) => {
        return (
          <Col key={tag}>
            <Tag
              color={props.colorFunc ? props.colorFunc(tag) : "default"}
              key={tag}
            >
              {tag}
            </Tag>
          </Col>
        );
      })}
    </Row>
  );
};

const UpgradeOptions = (props: {
  selectedPackage: Omit<PackageNode, "__typename">;
}) => {
  const { data, loading, error } =
    useGetUpgradeableVersionsForPackageVersionQuery({
      variables: { packageVersionId: props.selectedPackage.id },
    });

  const upgradeableVersions =
    data?.getUpgradeableVersionsForPackageVersion.versions ?? [];

  const { message, descriptionText } = getUpgradeText({
    upgradeableVersions,
  });

  return loading ? (
    <Loading view={false} />
  ) : (
    <Card title={message} style={{ width: "100%" }}>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <div>{descriptionText}</div>
        {!!upgradeableVersions.length && (
          <Collapse
            items={[
              {
                key: "versions",
                label: "Available versions",
                children: <TagGrid tags={upgradeableVersions} />,
              },
            ]}
          />
        )}
      </Space>
    </Card>
  );
};

// We could re-use this elsewhere if needed
const PackageDrawer = (
  props: {
    selectedPackage: Omit<ImpactfulPackageNode, "__typename">;
  } & DrawerProps
) => {
  const { data, loading, error } = usePackageQuery({
    variables: { pkgSpec: { id: props.selectedPackage.id } },
  });

  const [searchTerm, setSearchTerm] = useState("");

  const items = [
    {
      key: "source",
      label: "source",
      children: data?.package.package?.source?.repo ? (
        <Link
          href={data.package.package?.source?.repo}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.package.package?.source?.repo}
        </Link>
      ) : (
        "-"
      ),
    },
    {
      key: "qualifiers",
      label: "qualifiers",
      children: (
        <Content>
          {data?.package.package.qualifiers?.length === 0 && "-"}
          {data?.package.package.qualifiers?.map((qualifier) => (
            <Tag color="blue" key={qualifier.key}>
              {qualifier.key}: {qualifier.value}
            </Tag>
          ))}
        </Content>
      ),
    },
    {
      key: "score",
      label: "score",
      children: (
        <OSSFScore score={data?.package.package.attributes?.ossfScore} />
      ),
    },
    {
      key: "vulns",
      label: "vulns",
      children: (
        <Vulnerabilities
          vulns={data?.package?.package?.attributes?.vulns || []}
        />
      ),
    },
    {
      key: "eol",
      label: "eol",
      children: data?.package.package.attributes?.eol?.date ? (
        <Space direction="horizontal">
          {format(
            new Date(data.package.package.attributes.eol.date),
            "MMMM d, yyyy"
          )}
          <EolReasonTag node={data.package.package}></EolReasonTag>
        </Space>
      ) : (
        "-"
      ),
    },
  ];

  const flattenedCommits =
    data?.package.projects?.flatMap((project) => {
      return (project.commits ?? []).map((commit) => {
        return {
          ...commit,
          projectName: project.name,
        };
      });
    }) ?? [];

  const isTruthy = <T extends Exclude<any, undefined>>(
    value: T | undefined
  ): value is T => !!value;

  const projects = Object.entries(
    group(flattenedCommits, (commit) => {
      return commit.projectName;
    })
  )
    .map(([projectName, commits]) => {
      // We need to find the latest commit on a project, and then determine if
      // its target is Image, Directory, or both.
      // First we find the latest commit, then we find any other commits with
      // the same hash. If there are any, we know that the target is both.

      const sorted = commits?.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });

      if (!sorted || sorted.length === 0) return;

      const selectedCommits = sorted.filter(
        (commit) => commit.hash === sorted[0].hash
      );

      return {
        projectName,
        key: projectName,
        hash: selectedCommits[0].hash,
        targets: [...new Set(selectedCommits.map((commit) => commit.target))],
        createdAt: selectedCommits[0].createdAt,
      };
    })
    .filter(isTruthy);

  const fuse = new Fuse(projects, {
    keys: ["projectName", "hash"],
  });

  const projectSearchResults = searchTerm
    ? fuse.search(searchTerm).map((result) => result.item)
    : projects;

  return (
    <Drawer
      title={
        <ImpactTag
          category={getImpactCategory(props.selectedPackage?.impact ?? 0)}
        ></ImpactTag>
      }
      width="65%"
      open={!!props.selectedPackage}
      {...omit(props, ["selectedPackage"])}
    >
      {loading ? (
        <Loading />
      ) : (
        <Space style={{ width: "100%" }} direction="vertical">
          <PageHeader
            title={data?.package.package.label}
            subTitle={data?.package.package.version}
          >
            <Descriptions size="middle" items={items} />
          </PageHeader>
          <div style={{ paddingTop: 24, width: "100%" }}>
            <Space direction="vertical" size="large">
              <UpgradeOptions selectedPackage={props.selectedPackage} />
              <Space size="middle">
                <Input.Search
                  placeholder="Search projects..."
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  style={{ width: 300 }}
                />
              </Space>
              <Table
                dataSource={projectSearchResults}
                expandable={
                  {
                    columnWidth: "3%",
                    expandedRowRender: (
                      record: (typeof projectSearchResults)[number]
                    ) => {
                      return (
                        <Space
                          direction="vertical"
                          size="small"
                          style={{ marginLeft: "3%", marginRight: "3%" }}
                        >
                          <Descriptions
                            items={[
                              {
                                key: "targets",
                                label: "targets",
                                children: (
                                  <TagGrid
                                    tags={record.targets}
                                    colorFunc={getTargetColor}
                                  />
                                ),
                              },
                            ]}
                          />
                        </Space>
                      );
                    },
                  } as any // There seems to be an antd type bug here, where it removes most of the fields (except projectName) on the 'record' parameter
                }
                columns={[
                  {
                    title: "Project",
                    key: "project",
                    dataIndex: "projectName",
                    width: "40%",
                    sortDirections: ["ascend", "descend"],
                    sorter: (
                      a: { projectName: string },
                      b: { projectName: string }
                    ) => {
                      return a.projectName.localeCompare(b.projectName);
                    },
                  },
                  {
                    title: "Commit",
                    dataIndex: "hash",
                    key: "commit",
                    width: "25%",
                    ellipsis: true,
                    render: (hash: string) => (
                      <div
                        style={{
                          marginLeft: "6px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Tooltip title={hash}>{hash}</Tooltip>
                      </div>
                    ),
                  },
                  {
                    title: "Last Scanned",
                    dataIndex: "createdAt",
                    key: "scanned",
                    width: "20%",
                    render: (date: string) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          style={{
                            marginLeft: "6px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Tooltip title={toHumanReadableDateTime(date)}>
                            {toHumanReadableDateTime(date)}
                          </Tooltip>
                        </div>
                      </div>
                    ),
                  },
                ]}
                tableLayout="fixed"
                style={{ width: "100%" }}
                size="small"
              />
            </Space>
          </div>
        </Space>
      )}
    </Drawer>
  );
};

const ImpactTag = (
  props: {
    category: ImpactFilterCategory;
  } & TagProps
) => {
  return (
    <Tag {...omit(props, ["category"])} color={SeverityColors[props.category]}>
      {props.category.toUpperCase()}
    </Tag>
  );
};

export const SignalsPage = () => {
  const { data, loading, error } = useGetImpactfulPackagesForTenantQuery();

  const [selectedImpactFilterOptions, setSelectedImpactFilterOptions] =
    useState<ImpactFilterCategory[]>(["Medium", "High", "Critical"]);

  const [selectedEolFilterOffset, setSelectedEolFilterOptions] =
    useState<EolFilterRange | null>(null);

  const [selectedPackage, setSelectedPackage] =
    useState<ImpactfulPackageNode | null>(null);

  const [tableLoading, setTableLoading] = useState(false);

  const filteredAndSortedPackages = data?.getImpactfulPackagesForTenant.packages
    .filter(
      (pkg) =>
        !!selectedImpactFilterOptions?.includes(getImpactCategory(pkg.impact))
    )
    .filter((pkg) => {
      if (!pkg.attributes?.eol?.date) return false;

      if (selectedEolFilterOffset === null) return true;

      const [minOffset, maxOffset] = selectedEolFilterOffset;

      const currentDate = new Date();

      return (
        addYears(new Date(pkg.attributes.eol.date), minOffset) < currentDate &&
        (maxOffset === Infinity ||
          currentDate <= addYears(new Date(pkg.attributes.eol.date), maxOffset))
      );
    })
    .map((pkg) => ({ ...pkg, key: pkg.id }))
    .sort((a, b) => {
      return b.impact - a.impact;
    });

  return (
    <Content
      style={{
        margin: 12,
        width: "100%",
        minWidth: 800,
      }}
    >
      <Helmet>
        <title>Signals - Xeol</title>
      </Helmet>

      {loading ? (
        <Loading />
      ) : (
        <Space
          direction="vertical"
          style={{ display: "flex", width: "98%" }}
          size="large"
        >
          <LightFilter>
            <ProFormSelect
              name="minimum impact"
              label="minimum impact"
              rules={[
                { required: true, message: "No impact level(s) selected" },
              ]}
              options={[
                {
                  label: <ImpactTag category="Low"></ImpactTag>,
                  value: "Low",
                },
                {
                  label: <ImpactTag category="Medium"></ImpactTag>,
                  value: "Medium",
                },
                {
                  label: <ImpactTag category="High"></ImpactTag>,
                  value: "High",
                },
                {
                  label: <ImpactTag category="Critical"></ImpactTag>,
                  value: "Critical",
                },
              ]}
              fieldProps={{
                onChange: (value: ImpactFilterCategory) => {
                  const orderedImpactCategories: ImpactFilterCategory[] = [
                    "Low",
                    "Medium",
                    "High",
                    "Critical",
                  ];

                  const idx = orderedImpactCategories.findIndex(
                    (category) => category === value
                  );

                  setTableLoading(true);
                  setTimeout(() => {
                    setTableLoading(false);
                    setSelectedImpactFilterOptions(
                      orderedImpactCategories.slice(idx)
                    );
                  }, 200);
                },
              }}
              initialValue={selectedImpactFilterOptions[0]}
              allowClear={false}
            />
            <ProFormSelect
              name="eol"
              label="eol"
              options={[
                {
                  label: "EOL in <12mo",
                  value: "eol-less-than-1-year",
                },
                {
                  label: "currently EOL",
                  value: "eol-current",
                },
              ]}
              fieldProps={{
                onChange: (value?: "eol-less-than-1-year" | "eol-current") => {
                  setTableLoading(true);
                  setTimeout(() => {
                    setTableLoading(false);
                    if (value === "eol-less-than-1-year") {
                      setSelectedEolFilterOptions([-1, 0]);
                    } else if (value === "eol-current") {
                      setSelectedEolFilterOptions([0, Infinity]);
                    } else {
                      setSelectedEolFilterOptions(null);
                    }
                  }, 200);
                },
              }}
            />
          </LightFilter>
          <Table
            pagination={{
              pageSize: 15,
              position: ["bottomLeft"],
              showSizeChanger: false,
            }}
            rowClassName="fixed-height-row"
            loading={tableLoading}
            columns={[
              {
                title: "Impact",
                dataIndex: "impact",
                key: "impact",
                width: "7rem",
                render: (impact: number) => {
                  const category = getImpactCategory(impact);
                  return <ImpactTag category={category}></ImpactTag>;
                },
                sortDirections: ["ascend", "descend"],
                sorter: (a: ImpactfulPackageNode, b: ImpactfulPackageNode) => {
                  return a.impact - b.impact;
                },
              },
              {
                title: "Label",
                dataIndex: "label",
                key: "label",
                width: "50%",
                sortDirections: ["ascend", "descend"],
                sorter: (a: ImpactfulPackageNode, b: ImpactfulPackageNode) => {
                  return a.label.localeCompare(b.label);
                },
                render: (
                  label: string,
                  packageVersion: ImpactfulPackageNode
                ) => {
                  return (
                    <Space direction="vertical" size="small">
                      <b>{label}</b>
                      <Space direction="horizontal">
                        <Tag
                          color={
                            packageVersion.ecosystem in ecosystemColorMap
                              ? ecosystemColorMap[
                                  packageVersion.ecosystem as keyof typeof ecosystemColorMap
                                ]
                              : "default"
                          }
                        >
                          {packageVersion.ecosystem}
                        </Tag>
                        {`v${packageVersion.version}`}
                      </Space>
                    </Space>
                  );
                },
              },
              {
                title: "Projects",
                key: "projects",
                sortDirections: ["ascend", "descend"],
                render: (packageVersion: ImpactfulPackageNode) => {
                  return (
                    <p>
                      {packageVersion.projectIds.length} (
                      {Math.round(packageVersion.projectImpact)}%)
                    </p>
                  );
                },
                sorter: (a: ImpactfulPackageNode, b: ImpactfulPackageNode) => {
                  return a.projectIds.length - b.projectIds.length;
                },
              },
              {
                title: "Vulnerabilities",
                key: "vulnerabilities",
                sortDirections: ["ascend", "descend"],
                render: (packageVersion: ImpactfulPackageNode) => {
                  return packageVersion.attributes?.vulns?.length || "-";
                },
                sorter: (a: ImpactfulPackageNode, b: ImpactfulPackageNode) => {
                  return (
                    (a.attributes?.vulns?.length ?? 0) -
                    (b.attributes?.vulns?.length ?? 0)
                  );
                },
              },
              {
                title: "EOL",
                key: "eol",
                dataIndex: ["attributes", "eol", "date"],
                sortDirections: ["ascend", "descend"],
                render: (date: string, node) => {
                  const eolDate = new Date(date);
                  const currentDate = new Date();

                  return (
                    <Space direction="vertical" size="small">
                      <DistanceDate date={eolDate} />
                      <EolReasonTag node={node}></EolReasonTag>
                    </Space>
                  );
                },
                sorter: (a: ImpactfulPackageNode, b: ImpactfulPackageNode) => {
                  const aTime = a.attributes?.eol?.date
                    ? new Date(a.attributes.eol.date).getTime()
                    : 0;
                  const bTime = b.attributes?.eol?.date
                    ? new Date(b.attributes.eol.date).getTime()
                    : 0;
                  return aTime - bTime;
                },
              },
            ]}
            onRow={(record) => {
              return {
                onClick: () => {
                  setSelectedPackage(record);
                },
                style: {
                  cursor: "pointer",
                },
              };
            }}
            size="small"
            dataSource={filteredAndSortedPackages}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <span>No signals that match current filter(s)</span>
                  }
                />
              ),
            }}
            showHeader={!!filteredAndSortedPackages?.length}
          />
        </Space>
      )}
      {selectedPackage && (
        <PackageDrawer
          selectedPackage={selectedPackage}
          onClose={() => setSelectedPackage(null)}
        ></PackageDrawer>
      )}
    </Content>
  );
};

export default SignalsPage;
