import { Menu, MenuProps } from "antd";
import React, { useEffect } from "react";
import DependenciesPage from "./dependencies";
import { StringParam, useQueryParam } from "use-query-params";
import ProjectsPage from "./projects";
import ImagesView from "../components/ImagesView";
import HomePage from "./home";

const InventoryPage = () => {
  const [selectedKey = "dependencies", setSelectedKey] = useQueryParam(
    "m",
    StringParam
  );

  useEffect(() => {
    setSelectedKey(selectedKey);
  }, [setSelectedKey, selectedKey]);

  const headerItems: MenuProps["items"] = [
    {
      key: "dependencies",
      label: "Dependencies",
    },
    {
      key: "projects",
      label: "Projects",
    },
    {
      key: "images",
      label: "Images",
    },
  ];

  const handleMenuClick = (e: any) => {
    setSelectedKey(e.key);
    // Create a URLSearchParams object with only the 'm' parameter
    const searchParams = new URLSearchParams();
    searchParams.set("m", e.key);

    // Update the URL with the new search parameters
    window.history.pushState(
      {},
      "",
      `${window.location.pathname}?${searchParams}`
    );
  };

  const renderContent = () => {
    switch (selectedKey) {
      case "projects":
        return <ProjectsPage />;
      case "dependencies":
        return <DependenciesPage />;
      case "images":
        return <HomePage />;
      default:
        return <DependenciesPage />;
    }
  };

  return (
    <>
      <Menu
        mode="horizontal"
        selectedKeys={[selectedKey?.toString() || "1"]}
        items={headerItems}
        onClick={handleMenuClick}
      />
      {renderContent()}
    </>
  );
};

export default InventoryPage;
