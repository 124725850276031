import React, { ReactNode } from "react";
import { Popover, Button } from "antd";

interface PopoverListProps {
  mainTag: ReactNode;
  popoverTags: ReactNode[];
}

const PopoverList: React.FC<PopoverListProps> = ({ mainTag, popoverTags }) => {
  if (!mainTag) return null;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {mainTag}
      {popoverTags.length > 0 && (
        <span>
          <Popover
            placement="bottomRight"
            content={<div style={{ maxWidth: "550px" }}>{popoverTags}</div>}
          >
            <Button size="small">+{popoverTags.length}</Button>
          </Popover>
        </span>
      )}
    </div>
  );
};

export default PopoverList;
