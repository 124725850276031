import { DualAxes } from "@ant-design/plots";
import React from "react";
import SeverityColors from "../../utils/colors";

interface Props {
  height: number;
  barData: any;
  lineData: any;
  disabled?: boolean;
}

const mockData = [
  {
    time: "2019-03",
    value: 350,
    type: "uv",
  },
  {
    time: "2019-04",
    value: 900,
    type: "uv",
  },
  {
    time: "2019-05",
    value: 300,
    type: "uv",
  },
  {
    time: "2019-06",
    value: 450,
    type: "uv",
  },
  {
    time: "2019-07",
    value: 470,
    type: "uv",
  },
  {
    time: "2019-08",
    value: 370,
    type: "uv",
  },
  {
    time: "2019-03",
    value: 220,
    type: "bill",
  },
  {
    time: "2019-04",
    value: 300,
    type: "bill",
  },
  {
    time: "2019-05",
    value: 250,
    type: "bill",
  },
  {
    time: "2019-06",
    value: 220,
    type: "bill",
  },
  {
    time: "2019-07",
    value: 362,
    type: "bill",
  },
  {
    time: "2019-08",
    value: 262,
    type: "bill",
  },
];

const mockLine = [
  {
    time: "2019-03",
    percent: 60,
  },
  {
    time: "2019-04",
    percent: 80,
  },
  {
    time: "2019-05",
    percent: 50,
  },
  {
    time: "2019-06",
    percent: 30,
  },
  {
    time: "2019-07",
    percent: 20,
  },
  {
    time: "2019-08",
    percent: 10,
  },
];

const StackedBarChart: React.FC<Props> = ({
  height,
  barData,
  lineData,
  disabled = false,
}) => {
  const config = disabled
    ? {
        data: [mockData, mockLine],
        xField: "time",
        yField: ["value", "percent"],
        height: height,
        geometryOptions: [
          {
            geometry: "column",
            isStack: true,
            seriesField: "type",
            color: "#bfbfbf",
          },
          {
            geometry: "line",
            color: "#bfbfbf",
          },
        ],
        xAxis: {
          label: {
            style: { fontSize: 0 },
          },
        },
        yAxis: {
          value: {
            label: {
              style: {
                fontSize: 0,
              },
            },
          },
          count: {
            label: {
              style: {
                fontSize: 0,
              },
            },
          },
        },
        legend: {
          itemName: {
            style: {
              fontSize: 0,
            },
          },
        },
      }
    : {
        data: [barData, lineData],
        xField: "time",
        yField: ["value", "percent"],
        height: height,
        geometryOptions: [
          {
            geometry: "column",
            isStack: true,
            seriesField: "type",
            color: [
              SeverityColors.Critical,
              SeverityColors.High,
              SeverityColors.Medium,
              SeverityColors.Low,
              SeverityColors.Unknown,
            ],
          },
          {
            geometry: "line",
            color: "#1677ff",
          },
        ],
        yAxis: [
          {},
          {
            min: 0,
            max: 100,
          },
        ],
      };

  return disabled ? (
    <DualAxes {...config} style={{ pointerEvents: "none" }} />
  ) : (
    <DualAxes {...config} />
  );
};

export default StackedBarChart;
