import React from "react";

const NotFoundSelect: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "30px",
        padding: "5px",
      }}
    >
      Not Found
    </div>
  );
};

export default NotFoundSelect;
