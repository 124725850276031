import { Select } from "antd";
import React, { useState } from "react";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

export const GROUP_BY_PROJECTS = "Projects";
export const GROUP_BY_IMAGES = "Images";
export const GROUP_BY_SOFTWARE = "Software";

export type GroupByOptionsType =
  | typeof GROUP_BY_PROJECTS
  | typeof GROUP_BY_IMAGES
  | typeof GROUP_BY_SOFTWARE;

interface Props {
  value?: GroupByOptionsType;
  handleSelect?: (selected: GroupByOptionsType) => void;
}

const GroupByDropdown: React.FC<Props> = ({ value, handleSelect }) => {
  const [selected, setSelected] = useState<GroupByOptionsType>(
    value || GROUP_BY_PROJECTS
  );
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = (value: GroupByOptionsType) => {
    sessionStorage.setItem("groupByView", value);
    setSelected(value);
    if (handleSelect) {
      handleSelect(value);
    }
  };

  return (
    <Select
      style={{ width: 110 }}
      value={selected}
      onChange={handleChange}
      onDropdownVisibleChange={isOpen => setDropdownOpen(isOpen)}
      suffixIcon={isDropdownOpen ? <UpCircleOutlined /> : <DownCircleOutlined />}
    >
      <Option value={GROUP_BY_PROJECTS}>{GROUP_BY_PROJECTS}</Option>
      <Option value={GROUP_BY_IMAGES}>{GROUP_BY_IMAGES}</Option>
      <Option value={GROUP_BY_SOFTWARE}>{GROUP_BY_SOFTWARE}</Option>
    </Select>
  );
};

export default GroupByDropdown;
