import { Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import html2canvas from 'html2canvas';
import React, { useState } from "react";

interface Props {
  disabled?: boolean;
}

const ScreenCaptureButton: React.FC<Props> = ({ disabled = false }) => {
  const [loading, setLoading] = useState(false);

  const captureScreen = () => {
    const content = document.getElementById('page-content');

    if (content) {
      setLoading(true);
      html2canvas(content).then((canvas) => {
        const base64image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = 'xeol-analytics.png';
        link.href = base64image;
        link.click();
        setLoading(false);
      });
    }
  };

  return (
    <Button
      icon={<CameraOutlined />}
      disabled={disabled}
      onClick={captureScreen}
      loading={loading}
    >
      Screenshot
    </Button>
  );
};

export default ScreenCaptureButton;
