export const projectNameDecompose = (
  projectName: string
): { namespace: string; name: string; owner: string; type: string } => {
  if (!projectName) {
    return { type: "", owner: "", namespace: "", name: "" };
  }

  const parts = projectName.split("//");
  if (parts.length < 2 || parts == undefined) {
    throw new Error("Invalid project name format");
  }

  const type = parts[0];
  const restParts = parts[1].split("/");
  if (restParts.length === 3) {
    const [owner, namespace, name] = restParts;
    return { type, owner, namespace, name };
  } else {
    const [owner, name] = restParts;
    return { type, owner, namespace: "", name };
  }
};

export const projectRecompose = ({
  name,
  owner,
  type,
  namespace,
}: {
  name: string;
  owner: string;
  type: string;
  namespace?: string;
}): string => {
  if (!type || !owner || !name) {
    return "";
  }
  return namespace
    ? `${type}//${owner}/${namespace}/${name}`
    : `${type}//${owner}/${name}`;
};
//   name: string,
//   owner: string,
//   type: string,
//   namespace?: string
// ): string => {
//   console.log(type, owner, namespace, name);
//   if (!type || !owner || !name) {
//     return "";
//   }
//   return namespace
//     ? `${type}//${owner}/${namespace}/${name}`
//     : `${type}//${owner}/${name}`;
// };
