import React from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Switch } from "antd";

const { Meta } = Card;

interface integration {
  avatar: string;
  title: string;
  description: string;
}

const IntegrationCardStyle = {
  minWidth: 300,
};

const CardMetaStyle = {
  height: 100,
};

const IntegrationCard: React.FC<{ integration: integration }> = ({
  integration,
}) => {
  return (
    <Card
      // style={IntegrationCardStyle}
      actions={[
        <Button key={`${integration.title}-setting-button`} type="link" disabled={true}>
          <SettingOutlined />
        </Button>,
        <Switch checkedChildren="On" unCheckedChildren="Off" key="switch" disabled={true} />,
      ]}
    >
      <Meta
        // style={CardMetaStyle}
        avatar={<Avatar src={integration.avatar} size={60} />}
        title={integration.title}
        description={integration.description}
      />
    </Card>
  );
};

export default IntegrationCard;
