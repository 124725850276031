import React from "react";
import { Space, Tag, Typography } from "antd";
import { PackageNodeVulns } from "../gql/graphql";
const { Link } = Typography;
import { DisconnectOutlined, LinkOutlined } from "@ant-design/icons";
interface VulnerabilitiesProps {
  vulns?: PackageNodeVulns[];
  singleVuln?: PackageNodeVulns;
}

const Vulnerabilities: React.FC<VulnerabilitiesProps> = ({
  vulns,
  singleVuln,
}) => {
  const renderVuln = (v: PackageNodeVulns) =>
    v.link ? (
      <Link key={v.id} href={v.link} target="_blank" rel="noopener noreferrer">
        <Tag color="red">
          <LinkOutlined /> {v.vulnID}
        </Tag>
      </Link>
    ) : (
      <Tag key={v.id} color="red">
        <DisconnectOutlined /> {v.vulnID}
      </Tag>
    );

  if (singleVuln) {
    return renderVuln(singleVuln);
  }

  if (vulns && vulns.length === 0) {
    return <>-</>;
  } else {
    return (
      <Space style={{ display: "flex", flexWrap: "wrap" }}>
        {vulns?.map(renderVuln)}
      </Space>
    );
  }
};

export default Vulnerabilities;
