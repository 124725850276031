import React from "react";
import { format, formatDistance } from "date-fns";

export const DistanceDate = (props: { date: Date }) => {
  const { date } = props;
  const currentDate = new Date();

  return (
    <div>
      {format(date, "MMMM d, yyyy")}{" "}
      <span style={{ color: "GrayText", whiteSpace: "nowrap" }}>
        {`(${formatDistance(date, currentDate, {
          addSuffix: true,
        })})`}
      </span>
    </div>
  );
};
