import {
  Avatar,
  Button,
  Col,
  Descriptions,
  DescriptionsProps,
  Drawer,
  Layout,
  Popover,
  Row,
  Segmented,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { ExportOutlined, FileTextOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";

import {
  DefaultService,
  EOLFilterOptions,
  EolSoftwareNote,
  OpenAPI,
  Page_EolSoftwareFirst_,
  ProjectImage,
} from "../../client";
import {
  decodeSpaces,
  isEol,
  renderImageName,
  toReallyHumanReadableDate,
  truncateString,
} from "../../utils/helpers";
import { SearchInput } from "../Search";
import { renderSoftwareIcon } from "../../utils/iconRenderer";
import NoteButton from "../buttons/NoteButton";
import ScanHistoryButton from "../ScanHistoryModal";
import TruncatedText from "../TruncatedText";
import { renderSignatureTag } from "../../utils/TagRenderer";
import {
  AUTH0_ROLES_URL,
  DecodedJWT,
  USER_ROLE,
  UserRoles,
} from "../../utils/jwt";

const { Content } = Layout;
const { Link } = Typography;

interface ImageDrawerProps {
  isDrawerVisible: boolean;
  setIsDrawerVisible: (value: boolean) => void;
  selectedImage: ProjectImage;
  projectName: string;
  setSelectedImage: (value: ProjectImage | undefined) => void;
}

interface DataType {
  id: string;
  key: string;
  productName: string;
  productPermalink: string | null | undefined;
  productNote: EolSoftwareNote | null | undefined;
  version: string;
  artifactType: string;
  eol: string;
}

export const renderLabelsList = (
  labels: Record<string, string> | null | undefined,
  truncateWidthTopLevel: number,
  truncateWidthPopover: number
) => {
  if (!labels) return null;
  const labelsArray = Object.entries(labels);
  if (labelsArray.length === 0) return null;

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        {labelsArray.slice(0, 1).map(([key, value], _) => (
          <Tag key={`${key}:${value}`}>
            {truncateString(`${key}: ${value}`, truncateWidthTopLevel)}
          </Tag>
        ))}
        {labelsArray.length > 1 && (
          <span>
            <Popover
              placement="bottomRight"
              content={
                <div style={{ maxWidth: "550px" }}>
                  {labelsArray.map(([key, value], _) => (
                    <Tag style={{ marginTop: "5px" }} key={`${key}:${value}`}>
                      <Tooltip title={`${key}: ${value}`}>
                        {truncateString(
                          `${key}: ${value}`,
                          truncateWidthPopover
                        )}
                      </Tooltip>
                    </Tag>
                  ))}
                </div>
              }
            >
              <Button size="small">+{labelsArray.length - 1}</Button>
            </Popover>
          </span>
        )}
      </div>
    </>
  );
};

export const ImageDrawer: React.FC<ImageDrawerProps> = ({
  isDrawerVisible,
  setIsDrawerVisible,
  selectedImage,
  projectName,
  setSelectedImage,
}) => {
  const [eolPackageData, setEolPackageData] =
    React.useState<Page_EolSoftwareFirst_>();
  const [eolData, setEolData] = React.useState<DataType[]>();
  const [noteCount, setNoteCount] = React.useState(0);
  const [search, setSearch] = React.useState<string>("");
  const [filter, setFilter] = useState<EOLFilterOptions>(EOLFilterOptions.ALL);
  const [token, setToken] = React.useState<string>();
  const [userRole, setUserRole] = useState<UserRoles>(USER_ROLE);

  const { user, getAccessTokenSilently } = useAuth0();
  const API_URL = process.env.REACT_APP_API_URL;

  const queryKey = ["eol", search, filter];

  const { isLoading, error, data } = useQuery(
    queryKey,
    () => {
      OpenAPI.BASE = API_URL!;
      OpenAPI.HEADERS = {
        Authorization: `Bearer ${token}`,
      };
      return DefaultService.getProjectImageSoftwareImageTagsImageTagIdEolSoftwareGet(
        selectedImage.id,
        search,
        filter
      );
    },
    {
      enabled: isDrawerVisible && token !== undefined,
    }
  );

  const transformData = (
    eolPackageData: Page_EolSoftwareFirst_ | undefined
  ): DataType[] => {
    if (!eolPackageData) return [];
    return eolPackageData.items.map((item) => {
      return {
        id: item.id,
        key: item.id,
        productName: item.productName,
        productPermalink: item.productPermalink,
        productNote: item.productNote,
        version: item.releaseCycle,
        artifactType: item.artifactType,
        eol: item.eolDate,
      };
    });
  };

  const countProductNotes = (eolData: DataType[]): number => {
    return eolData.filter((item: DataType) => item.productNote?.id !== null)
      .length;
  };

  const renderNameColumnTitle = (noteCount: number) => {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={20}>Name</Col>
        {noteCount !== 0 && (
          <Col>
            <Tag icon={<FileTextOutlined />} color="warning">
              {`${noteCount}`}
            </Tag>
          </Col>
        )}
      </Row>
    );
  };

  const EOLFilterOptionMappings = {
    [EOLFilterOptions.ALL]: "All",
    [EOLFilterOptions.EOL]: "EOL",
    [EOLFilterOptions.EOL_LESS_THAN_6MO]: "EOL <6mo",
    [EOLFilterOptions.EOL_LESS_THAN_12MO]: "EOL <12mo",
    [EOLFilterOptions.EOL_MORE_THAN_12MO]: "EOL >12mo",
  };

  const columns: ColumnsType<DataType> = [
    {
      title: renderNameColumnTitle(noteCount),
      dataIndex: "productName",
      key: "productName",
      width: "50%",
      render: (text, record) => {
        return (
          <Content
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Row style={{ width: "100%" }}>
              <Col span={20}>
                <Space direction="horizontal">
                  <Avatar
                    size="small"
                    src={renderSoftwareIcon(
                      record.productName,
                      record.artifactType
                    )}
                  />
                  {record.productPermalink ? (
                    <Link
                      href={record.productPermalink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Space direction="horizontal">
                        <TruncatedText label={text} columnLength={430} />
                        <ExportOutlined style={{ fontSize: 10 }} />
                      </Space>
                    </Link>
                  ) : (
                    <TruncatedText label={text} columnLength={430} />
                  )}
                </Space>
              </Col>
              <Col>
                <NoteButton
                  softwareId={record.id}
                  userProfile={user?.picture}
                  eolPackageData={eolData}
                  setNoteCount={setNoteCount}
                  noteCount={noteCount}
                  setEolPackageData={setEolData}
                  userRole={userRole}
                  note={record?.productNote?.noteContent || null}
                  noteId={record.productNote?.id || null}
                  noteCreator={record?.productNote?.userProfile || null}
                />
              </Col>
            </Row>
          </Content>
        );
      },
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      width: "15%",
      render: (text, record) => {
        return <TruncatedText type="primary" label={text} columnLength={140} />;
      },
    },
    {
      title: "EOL Date",
      dataIndex: "eol",
      key: "eol",
      width: "20%",
      render: (text, record) => {
        const tag = isEol(record.eol);
        return (
          <Row>
            <Col span={16}>{toReallyHumanReadableDate(record.eol)}</Col>
            <Col span={8}>
              {tag && (
                <Tag bordered={false} color="red">
                  EOL
                </Tag>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  const descriptions: DescriptionsProps["items"] = [
    {
      key: 1,
      label: "Project",
      children: decodeSpaces(projectName),
    },
    {
      key: 2,
      label: "Image",
      children: selectedImage.name,
    },
    {
      key: 3,
      label: "Last Scanned",
      children: toReallyHumanReadableDate(selectedImage.latestScanDate),
    },
    {
      key: 4,
      label: "Tag",
      children: <Tag>{selectedImage.tag || "N/A"}</Tag>,
    },
    {
      key: 5,
      label: "Verified",
      children: renderSignatureTag(selectedImage),
    },
    {
      key: 6,
      label: "Labels",
      children: selectedImage.labels ? (
        <div style={{ maxWidth: "100%", display: "flex", flexWrap: "wrap" }}>
          {renderLabelsList(selectedImage.labels, 78, 78)}
        </div>
      ) : (
        []
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      const t = await getAccessTokenSilently();
      const decodedJwt: DecodedJWT = jwtDecode(t);
      setUserRole(decodedJwt[AUTH0_ROLES_URL][0]);
      setToken(t);
    }

    if (!isDrawerVisible) {
      // Reset state here
      setEolPackageData(undefined);
      setSelectedImage(undefined);
      setSearch("");
    }

    fetchData();
    setEolPackageData(data);
    const transforedEolData = transformData(data);
    setEolData(transforedEolData);
    setNoteCount(countProductNotes(transforedEolData));
  }, [
    data,
    isDrawerVisible,
    setIsDrawerVisible,
    setSelectedImage,
    getAccessTokenSilently,
  ]);

  return (
    <Drawer
      key={selectedImage.id}
      title={renderImageName(selectedImage)}
      width={900}
      closable={true}
      onClose={() => {
        setIsDrawerVisible(false);
        setEolData([]);
      }}
      open={isDrawerVisible}
    >
      <Space direction="vertical">
        <ScanHistoryButton
          projectName={projectName}
          imageName={selectedImage.name}
        />
        <Descriptions
          style={{
            marginTop: 24,
            marginBottom: 24,
          }}
          labelStyle={{ width: 120 }}
          items={descriptions}
          column={2}
          size={"small"}
        />
        <Row
          justify="space-between"
          align="middle"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Col>
            <SearchInput
              placeholder={"search"}
              onSearch={(value) => setSearch(value)}
            />
          </Col>
          <Col>
            <Segmented
              options={Object.values(EOLFilterOptionMappings)}
              onChange={(selectedText: any) => {
                const selectedEnum = Object.keys(EOLFilterOptionMappings).find(
                  (key) =>
                    EOLFilterOptionMappings[
                      key as keyof typeof EOLFilterOptionMappings
                    ] === selectedText
                );
                setFilter(selectedEnum as EOLFilterOptions);
              }}
            />
          </Col>
        </Row>
        <Table
          key={selectedImage.id}
          size="small"
          pagination={{ hideOnSinglePage: true }}
          loading={isLoading}
          columns={columns}
          dataSource={eolData}
        />
      </Space>
    </Drawer>
  );
};
