import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { RetryLink } from "@apollo/client/link/retry";

export function useApolloClient() {
  const ENGINE_API_URL = process.env.REACT_APP_ENGINE_API_URL;
  const { getAccessTokenSilently } = useAuth0();

  const query = new HttpLink({
    uri: `${ENGINE_API_URL}/query`,
  });

  const oldQuery = new HttpLink({
    uri: `${ENGINE_API_URL}/oldquery`,
  });

  const authLinkQuery = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }).concat(query);

  const authLinkOldQuery = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }).concat(oldQuery);

  const directionalLink = new RetryLink().split(
    (operation: any) => operation.getContext().path === "/query",
    authLinkQuery,
    authLinkOldQuery
  );

  const client = new ApolloClient({
    link: directionalLink,
    cache: new InMemoryCache(),
  });

  return client;
}
