import {
  Button,
  Col,
  Descriptions,
  DescriptionsProps,
  Drawer,
  Alert,
  Row,
  Select,
  Timeline,
  Spin,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { Carousel } from "antd";

import {
  PackageNode,
  GetPackageUpgradePathByConstraintsQuery,
  GetPackageUpgradePathQuery,
  PkgSpec,
  useGetPackageUpgradePathByConstraintsQuery,
  useGetPackageUpgradePathQuery,
} from "../../gql/graphql";
import {
  UpCircleOutlined,
  EllipsisOutlined,
  CheckCircleTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import axios from "axios";
import { DefaultOptionType } from "antd/es/select";
import { ApolloError } from "@apollo/client";
import { normalizeSemver } from "../../utils/semver";
import semver from "semver";
import TruncatedText from "../TruncatedText";
import OSSFScore from "../OssfScore";
import Vulnerabilities from "../Vulnerabilities";
import { ForceGraph2D } from "react-force-graph";
import { UpgradePathTimeline } from "./UpgradePath";

const { Text } = Typography;
const { Link } = Typography;
const { Title } = Typography;

interface PackageDrawerProps {
  isDrawerVisible: boolean;
  setIsDrawerVisible: (value: boolean) => void;
  selectedNode: PackageNode | null;
  selectedProject: string;
  selectedSbomID: string;
  // NOTE: this is only needed temporaril. Only packages that
  // are connected to an SBOM via the new has_package_versions
  // table can be upgraded. Once we have migrated all SBOMs to
  // the new format, we can remove this prop.
  upgradePathFeatureEnabled: boolean;
  setSelectedNode: (node: PackageNode | null) => void;
}

export const PackageDrawer: React.FC<PackageDrawerProps> = ({
  isDrawerVisible,
  setIsDrawerVisible,
  selectedNode,
  selectedSbomID,
  selectedProject,
  upgradePathFeatureEnabled,
  setSelectedNode,
}) => {
  const ENGINE_API_URL = process.env.REACT_APP_ENGINE_API_URL;
  const [versions, setVersions] = React.useState<DefaultOptionType[]>([]);
  const [selectedDropdownVersion, setSelectedDropdownVersion] =
    React.useState<string>("");
  const [selectedUpgradeVersion, setSelectedUpgradeVersion] =
    React.useState<string>("");
  const [manualSelectedPkgSpec, setManualSelectedPkgSpec] =
    React.useState<PkgSpec>();
  const [magicSelectedPkgSpec, setMagicSelectedPkgSpec] =
    React.useState<PkgSpec>();
  const [isHighestVersion, setIsHighestVersion] =
    React.useState<boolean>(false);

  const [manualUpgradePathData, setManualUpgradePathData] =
    React.useState<GetPackageUpgradePathQuery>();
  const [selectedVulnerabilityIDs, setSelectedVulnerabilityIDs] =
    React.useState<string[]>([]);
  const [magicManualUpgradePathData, setMagicManualUpgradePathData] =
    React.useState<GetPackageUpgradePathByConstraintsQuery>();
  const [magicManualUpgradePathLoading, setMagicManualUpgradePathLoading] =
    React.useState(false);
  const [magicManualUpgradePathError, setMagicManualUpgradePathError] =
    React.useState<ApolloError>();
  const [manualUpgradePathLoading, setManualUpgradePathLoading] =
    React.useState(false);
  const [manualUpgradePathError, setManualUpgradePathError] =
    React.useState<ApolloError>();

  const clearState = () => {
    setSelectedVulnerabilityIDs([]);
    setSelectedDropdownVersion("");
    setSelectedUpgradeVersion("");
    setMagicSelectedPkgSpec(undefined);
    setIsHighestVersion(false);
  };

  const onClose = () => {
    setIsDrawerVisible(false);
    setSelectedNode(null);
    setVersions([]);
    setManualUpgradePathData(undefined);
    setManualUpgradePathLoading(false);
    setManualUpgradePathError(undefined);
    clearState();
  };

  const {
    data: upgradePathData,
    loading: upgradePathLoading,
    error: upgradePathError,
  } = useGetPackageUpgradePathQuery({
    variables: {
      pkgSpec: manualSelectedPkgSpec!,
      versionTo: selectedUpgradeVersion,
      sbomID: selectedSbomID,
    },
    skip: !manualSelectedPkgSpec || !selectedUpgradeVersion,
  });

  const {
    data: magicUpgradePathData,
    loading: magicUpgradePathLoading,
    error: magicUpgradePathError,
  } = useGetPackageUpgradePathByConstraintsQuery({
    variables: {
      pkgSpec: magicSelectedPkgSpec!,
      vulnerabilityIDs: selectedVulnerabilityIDs!,
      sbomID: selectedSbomID,
    },
    skip: !magicSelectedPkgSpec || !selectedVulnerabilityIDs,
  });

  type PackageData = {
    packageKey: {
      system: string;
      name: string;
    };
    versions: PackageVersion[];
  };

  type PackageVersion = {
    versionKey: {
      system: string;
      name: string;
      version: string;
    };
    isDefault: boolean;
    publishedAt: string;
  };

  const textWidth = 442;

  useEffect(() => {
    setManualUpgradePathData(upgradePathData);
    setManualUpgradePathLoading(upgradePathLoading);
    setManualUpgradePathError(upgradePathError);
  }, [upgradePathData, upgradePathLoading, upgradePathError]);

  useEffect(() => {
    setMagicManualUpgradePathData(magicUpgradePathData);
    setMagicManualUpgradePathLoading(magicUpgradePathLoading);
    setMagicManualUpgradePathError(magicUpgradePathError);
  }, [magicUpgradePathData, magicUpgradePathLoading, magicUpgradePathError]);

  useEffect(() => {
    const fetchDepsDevVersions = async (pkg: PackageNode) => {
      try {
        const system = pkg.attributes?.pkgType?.toLowerCase();
        const pkgName = encodeURIComponent(pkg.label).toLowerCase();
        // we need to call our engine reverse proxy for the deps.dev API
        // because the API does not support CORS
        const result = await axios.get(
          `${ENGINE_API_URL}/depsdev/v3alpha/systems/${system}/packages/${pkgName}`
        );
        const pkgdata = result.data as PackageData;
        const pkgVersions = pkgdata.versions
          .filter((v) => {
            try {
              // filter out prerelease versions and versions that are less than the current version
              return (
                semver.gt(v.versionKey.version, normalizeSemver(pkg.version)) &&
                semver.prerelease(v.versionKey.version) == null
              );
            } catch (error) {
              return v.versionKey.version;
            }
          })
          .map(
            (v) =>
              ({
                label: v.versionKey.version,
                value: v.versionKey.version,
              } as DefaultOptionType)
          )
          .sort((a, b) => {
            if (typeof a.value === "string" && typeof b.value === "string") {
              return semver.compare(a.value, b.value);
            }
            return 0;
          });
        setVersions(pkgVersions);
        if (pkgVersions.length == 0) {
          setIsHighestVersion(true);
        }
      } catch (error: any) {
        // error swallowing is intentional here
      }
    };
    if (selectedNode) {
      fetchDepsDevVersions(selectedNode);
    }
  }, [selectedNode, ENGINE_API_URL]);

  const renderDrawer = (pkg: PackageNode | null) => {
    const items: DescriptionsProps["items"] = [
      {
        key: "1",
        label: "Project",
        span: 2,
        children: selectedProject,
      },
      {
        key: "2",
        label: "End-of-Life",
        children: pkg?.attributes?.eol?.date || "-",
      },
      {
        key: "3",
        label: "Source",
        span: 2,
        children: pkg?.source?.repo ? (
          <Link
            href={pkg?.source?.repo}
            target="_blank"
            rel="noopener noreferrer"
          >
            {pkg?.source?.repo}
          </Link>
        ) : (
          "-"
        ),
      },
      {
        key: "4",
        label: "OSSF Score",
        children: <OSSFScore score={pkg?.attributes?.ossfScore} />,
      },
      {
        key: "5",
        span: 1,
        label: "Vulnerabilities",
        children: <Vulnerabilities vulns={pkg?.attributes?.vulns || []} />,
      },
    ];

    const splitNameAndNamespace = (pkg: PackageNode): [string, string] => {
      const parts = pkg.label.split("/");
      if (parts.length === 1) {
        return [parts[0], ""];
      }
      return [parts[1], parts[0]];
    };

    const joinNameAndNamespace = (name: string, namespace: string): string => {
      if (namespace === "") {
        return name;
      }
      return `${namespace}/${name}`;
    };

    const renderUpgradePathNotFound = (
      pkg: PackageNode,
      version: string,
      error: any
    ) => {
      if (pkg.attributes?.pkgType?.toLowerCase() == "nuget") {
        return (
          <Alert
            style={{ marginTop: "24px" }}
            message="Upgrade Path Not Found"
            description={
              <p>
                {`We couldn't find any direct dependencies that require ${pkg.label} to be at least v${version}. You could try pinning this transitive dependency using `}
                <a href="https://devblogs.microsoft.com/nuget/introducing-central-package-management/">
                  transitive dependency pinning with NuGet.
                </a>
              </p>
            }
            type="info"
          />
        );
      }

      return (
        <Alert
          style={{ marginTop: "24px" }}
          message="Upgrade Path Not Found"
          description={`We couldn't find any direct dependencies where the version of the package ${pkg.label} >= v${selectedDropdownVersion}.`}
          type="info"
        />
      );
    };

    const isTopLevelPackage =
      pkg?.label ===
      joinNameAndNamespace(
        manualUpgradePathData?.getPackageUpgradePath?.name ?? "",
        manualUpgradePathData?.getPackageUpgradePath?.namespace ?? ""
      );

    return (
      pkg && (
        <>
          <div>
            <Descriptions items={items} />
          </div>
          {pkg.attributes?.componentType != "application" &&
            upgradePathFeatureEnabled && ( // only library component types with versions on deps.dev can be upgraded
              <>
                {pkg.attributes?.vulns && pkg.attributes?.vulns.length > 0 && (
                  <div>
                    <div>
                      <Title level={3}>Vuln Remediation</Title>
                      <p>{`Find an upgrade chain where no vulnerabilities are present in this package, or it no longer exists as a transitive dependency.`}</p>
                      {!magicManualUpgradePathData && (
                        <Button
                          type="primary"
                          onClick={() => {
                            const [name, namespace] =
                              splitNameAndNamespace(pkg);
                            setSelectedVulnerabilityIDs(
                              pkg?.attributes?.vulns?.map((v) => v.id) || []
                            );
                            setMagicSelectedPkgSpec({
                              name: name,
                              namespace: namespace,
                              version: pkg.version,
                              type: pkg.attributes?.pkgType?.toLowerCase(),
                            } as PkgSpec);
                          }}
                        >
                          Upgrade Chain
                        </Button>
                      )}
                    </div>
                    <div>
                      <UpgradePathTimeline
                        upgradePathData={
                          magicManualUpgradePathData?.getPackageUpgradePathByConstraints
                        }
                        upgradePathLoading={magicUpgradePathLoading}
                        upgradePathError={magicUpgradePathError}
                        pkg={pkg}
                      />
                    </div>
                  </div>
                )}
                <div>
                  <Title level={3}>Manual Upgrade Chain</Title>
                  <div style={{ marginTop: "8px" }}>
                    <Select
                      disabled={isHighestVersion || versions.length == 0}
                      options={versions}
                      style={{ width: 120, marginRight: "8px" }}
                      onChange={(selectedVersion) => {
                        clearState();
                        setSelectedDropdownVersion(selectedVersion);
                      }}
                      placeholder="version"
                    />
                    <Button
                      disabled={isHighestVersion || versions.length == 0}
                      type="primary"
                      onClick={() => {
                        setSelectedUpgradeVersion(selectedDropdownVersion);
                        const [name, namespace] = splitNameAndNamespace(pkg);
                        setManualSelectedPkgSpec({
                          name: name,
                          namespace: namespace,
                          version: pkg.version,
                          type: pkg.attributes?.pkgType?.toLowerCase(),
                        } as PkgSpec);
                      }}
                    >
                      Submit
                    </Button>
                    {isHighestVersion ? (
                      <span style={{ marginLeft: "4px" }}>
                        <CheckCircleTwoTone twoToneColor="#52c41a" /> this is
                        the highest version of this package available
                      </span>
                    ) : pkg.attributes?.componentType == "application" ? (
                      <span style={{ marginLeft: "4px" }}>
                        <ExclamationCircleTwoTone twoToneColor="#fadb14" />
                        {`can't
                      upgrade this package as it's an application type`}
                      </span>
                    ) : versions.length == 0 ? (
                      <span style={{ marginLeft: "4px" }}>
                        <ExclamationCircleTwoTone twoToneColor="#fadb14" /> no
                        versions of this package found
                      </span>
                    ) : null}
                  </div>
                  <div>
                    <UpgradePathTimeline
                      upgradePathData={
                        manualUpgradePathData?.getPackageUpgradePath
                      }
                      upgradePathLoading={manualUpgradePathLoading}
                      upgradePathError={manualUpgradePathError}
                      pkg={pkg}
                    />
                  </div>
                </div>
              </>
            )}
        </>
      )
    );
  };

  return (
    <Drawer
      title={`${selectedNode?.label} ${selectedNode?.version}`}
      placement="right"
      width={800}
      closable={true}
      onClose={onClose}
      open={isDrawerVisible}
    >
      {renderDrawer(selectedNode)}
    </Drawer>
  );
};
