import { Input } from "antd";
import React, { KeyboardEvent } from "react";

const { Search } = Input;

interface SearchInputProps {
  placeholder: string;
  defaultValue?: string | null;
  onSearch: (value: string) => void;
  style?: React.CSSProperties;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  placeholder,
  onSearch,
  style,
  defaultValue,
}) => {
  const [searchValue, setSearchValue] = React.useState(
    defaultValue ? defaultValue : ""
  );

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onSearch(e.currentTarget.value);
    }
  };

  return (
    <Search
      style={style}
      placeholder={placeholder}
      onSearch={onSearch}
      onChange={(e) => setSearchValue(e.target.value)}
      onKeyDown={handleKeyPress}
      value={searchValue}
    />
  );
};
