import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Button,
  Card as AntdCard,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Layout,
  message,
  Modal,
  Popconfirm,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import QueueAnim from "rc-queue-anim";

import Card from "../components/Card";
import JSONTextArea from "../components/JSONTextArea";
import Loading from "../components/Loading";
import RBACView from "../utils/jwt";
import { renderSoftwareIcon } from "../utils/iconRenderer";
import { decodeSpaces } from "../utils/helpers";
import { EOL } from "os";
import { Helmet } from "react-helmet-async";

const { Meta } = AntdCard;
const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;
const { Text } = Typography;

type Policy = {
  id: string;
  policy_scope: PolicyScopeType;
  policy_type: string;
  warn_date: string;
  deny_date: string;
  warn_days: number;
  deny_days: number;
  product_name: string;
  project_name: string;
  cycle: string;
  cycle_operator: string;
  organization: string;
  notary_policy_json: string;
};

type EolSoftware = {
  id: string;
  organization_id: string;
  scan_id: string;
  product_name: string;
  release_cycle: string;
  eol_date: string;
  latest_release_date: string;
  release_date: string;
  artifact_name: string;
  artifact_version: string;
  artifact_type: string;
  purl: string;
};

type OptionType = {
  value: string;
  label: string;
};

type SoftwareAndVersionListType = {
  [key: string]: { value: string; label: string }[];
};

type ProjectsListType = {
  project_name: string;
};

const GLOBAL_SCOPE = "global";
const PROJECT_SCOPE = "project";
const SOFTWARE_SCOPE = "software";
type PolicyScopeType =
  | typeof GLOBAL_SCOPE
  | typeof PROJECT_SCOPE
  | typeof SOFTWARE_SCOPE;

const EOL_POLICY = "EOL";
const NOTARY_POLICY = "NOTARY";
type PolicyTypes = typeof EOL_POLICY | typeof NOTARY_POLICY;

const tooltipStyle = {
  color: "#bfbfbf",
};

const addPolicyButtonStyle = {
  margin: 12,
  width: 500,
  height: 100,
};

const contentStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  height: "100%",
};

const formItemStyle = {
  width: 400,
};

const PoliciesPage: React.FC = () => {
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [currentPolicy, setCurrentPolicy] = useState<Policy | null>(null);
  const [isPolicyEditModalOpen, setIsPolicyEditModalOpen] = useState(false);
  const [isPolicyAddModalOpen, setIsPolicyAddModalOpen] = useState(false);
  const [softwareAndVersionList, setSoftwareAndVersionList] =
    useState<SoftwareAndVersionListType>({});
  const [versionList, setVersionList] = useState<OptionType[]>([]);
  const [softwareList, setSoftwareList] = useState<OptionType[]>([]);
  const [projectsList, setProjectsList] = useState<OptionType[]>([]);
  const [policyScope, setPolicyScope] = useState<PolicyScopeType>(GLOBAL_SCOPE);
  const [policyType, setPolicyType] = useState<PolicyTypes>(EOL_POLICY);
  const [loading, setLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const API_URL = process.env.REACT_APP_API_URL;
  const [form] = Form.useForm();

  const showDeleteModal = (policy: Policy) => {
    setCurrentPolicy(policy);
  };

  const handleDeleteOk = async () => {
    try {
      const token = await getAccessTokenSilently();
      await axios.delete(`${API_URL}/v1/policy/${currentPolicy?.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setPolicies(policies.filter((policy) => policy.id !== currentPolicy?.id));
      setCurrentPolicy(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteCancel = () => {
    setCurrentPolicy(null);
  };

  const showPolicyEditModal = (policy: Policy) => {
    form.setFieldsValue({
      policy_type: policy.policy_type || "",
      policy_scope: policy.policy_scope || "",
      warn_date: policy.warn_date ? dayjs(policy.warn_date) : null,
      deny_date: policy.deny_date ? dayjs(policy.deny_date) : null,
      warn_days: policy.warn_days ? Math.abs(policy.warn_days) : 0,
      warn_before_after:
        policy.warn_days && policy.warn_days > 0 ? "before" : "after",
      deny_days: policy.deny_days ? Math.abs(policy.deny_days) : 0,
      deny_before_after:
        policy.deny_days && policy.deny_days > 0 ? "before" : "after",
      product_name: policy.product_name || "",
      project_name: policy.project_name
        ? decodeSpaces(policy.project_name)
        : "",
      cycle: [policy.cycle] || "",
      cycle_operator: policy.cycle_operator || "",
      notary_policy_json:
        JSON.stringify(policy.notary_policy_json, null, 2) || "",
    });

    setCurrentPolicy(policy);
    setPolicyScope(policy.policy_scope as PolicyScopeType);
    setPolicyType(policy.policy_type as PolicyTypes);
    setIsPolicyEditModalOpen(true);
  };

  const handlePolicyEditCancel = () => {
    setIsPolicyEditModalOpen(false);
    setCurrentPolicy(null);
    setPolicyScope(GLOBAL_SCOPE);
    setPolicyType(EOL_POLICY);
    form.resetFields();
  };

  const showPolicyAddModal = () => {
    const fetchEolSoftware = async () => {
      const token = await getAccessTokenSilently();
      const res = await axios.get(`${API_URL}/v1/scanner/eol_software`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSoftwareList(getProductNames(res.data));
      // setVersionList(getReleaseCycles(res.data));
      setSoftwareAndVersionList(getSoftwareAndReleaseCycles(res.data));
    };

    const fetchProjects = async () => {
      const token = await getAccessTokenSilently();
      const res = await axios.get(`${API_URL}/v1/scanner/projects`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProjectsList(getProjectNames(res.data));
    };

    fetchEolSoftware();
    fetchProjects();
    setCurrentPolicy(null);
    setIsPolicyAddModalOpen(true);
  };

  const handlePolicyAddCancel = () => {
    setIsPolicyAddModalOpen(false);
    setCurrentPolicy(null);
    setPolicyScope(GLOBAL_SCOPE);
    setPolicyType(EOL_POLICY);
    form.resetFields();
  };

  const getProjectNames = (projectsList: ProjectsListType[]) => {
    return projectsList.map(({ project_name }) => ({
      value: project_name,
      label: decodeSpaces(project_name),
    }));
  };

  const getProductNames = (eolSoftwareList: EolSoftware[]) => {
    return eolSoftwareList.reduce(
      (acc: { value: string; label: string }[], software: EolSoftware) => {
        if (!acc.find((item) => item.value === software.product_name)) {
          acc.push({
            value: software.product_name,
            label: software.product_name,
          });
        }
        return acc;
      },
      []
    );
  };

  const getReleaseCycles = (eolSoftwareList: EolSoftware[]) => {
    return eolSoftwareList.reduce(
      (acc: { value: string; label: string }[], software: EolSoftware) => {
        if (!acc.find((item) => item.value === software.release_cycle)) {
          acc.push({
            value: software.release_cycle,
            label: software.release_cycle,
          });
        }
        return acc;
      },
      []
    );
  };

  const getSoftwareAndReleaseCycles = (
    eolSoftwareList: EolSoftware[]
  ): SoftwareAndVersionListType => {
    const result: SoftwareAndVersionListType = {};

    eolSoftwareList.forEach((software: EolSoftware) => {
      if (!result[software.product_name]) {
        result[software.product_name] = [];
      }

      if (
        !result[software.product_name].some(
          (item) => item.value === software.release_cycle
        )
      ) {
        result[software.product_name].push({
          value: software.release_cycle,
          label: software.release_cycle,
        });
      }
    });

    return result;
  };

  const handleProductNameSelect = (value: string, option: any) => {
    setVersionList(softwareAndVersionList[value]);
  };

  const handlePolicyScopeSelect = (value: PolicyScopeType, option: any) => {
    setPolicyScope(value);
    setPolicyType(EOL_POLICY);
    form.resetFields();
    form.setFieldsValue({ policy_scope: value });
  };

  const handlePolicyTypeSelect = (value: PolicyTypes, option: any) => {
    setPolicyType(value);
  };

  const renderPolicies = () => {
    const cycleOperatorToHumanReadable = (policyOperator: string): string => {
      switch (policyOperator) {
        case "LTE":
          return "≤";
        case "LT":
          return "<";
        case "EQ":
          return "";
        default:
          return "";
      }
    };

    const renderCardText = (policy: Policy): string => {
      switch (policy.policy_scope) {
        case GLOBAL_SCOPE:
          return `Global ${policy.policy_type} policy`;
        case PROJECT_SCOPE:
          return `${policy.policy_type} policy for ${decodeSpaces(
            policy.project_name
          )}`;
        case SOFTWARE_SCOPE:
          return `${policy.policy_type} policy for ${policy.product_name} ${policy.cycle}`;
        default:
          return `${policy.policy_type} policy`;
      }
    };

    const renderSentence = (action: string, days: number) => {
      if (days === 0) {
        return `- ${action} on EOL day`;
      } else if (days > 0) {
        return `- ${action} ${Math.abs(days)} days before EOL`;
      } else if (days < 0) {
        return `- ${action} ${Math.abs(days)} days after EOL`;
      } else {
        return "";
      }
    };

    const renderCardContent = (policy: Policy) => {
      let firstSentence = "";
      let warnSentence = "";
      let denySentence = "";

      if (policy.policy_type === EOL_POLICY) {
        if (policy.policy_scope === GLOBAL_SCOPE) {
          firstSentence = "All builds with EOL software will:";
          warnSentence = policy.warn_date
            ? `- Warn from: ${policy.warn_date}`
            : renderSentence("Warn", policy.warn_days);
          denySentence = policy.deny_date
            ? `- Block from: ${policy.deny_date}`
            : renderSentence("Block", policy.deny_days);
        } else if (policy.policy_scope === PROJECT_SCOPE) {
          firstSentence = `All builds within ${decodeSpaces(
            policy.project_name
          )} with EOL software will:`;
          warnSentence = policy.warn_date
            ? `- Warn from: ${policy.warn_date}`
            : renderSentence("Warn", policy.warn_days);
          denySentence = policy.deny_date
            ? `- Block from: ${policy.deny_date}`
            : renderSentence("Block", policy.deny_days);
        } else if (policy.policy_scope === SOFTWARE_SCOPE) {
          firstSentence = `All builds with ${
            policy.product_name
          } version ${cycleOperatorToHumanReadable(policy.cycle_operator)}${
            policy.cycle
          } will:`;
          warnSentence = policy.warn_date
            ? `- Warn from: ${policy.warn_date}`
            : "";
          denySentence = policy.deny_date
            ? `- Block from: ${policy.deny_date}`
            : "";
        }
      } else if (policy.policy_type === NOTARY_POLICY) {
        if (policy.policy_scope === GLOBAL_SCOPE) {
          firstSentence = "All builds with unverified images will:";
          warnSentence = policy.warn_date
            ? `- Warn from: ${policy.warn_date}`
            : renderSentence("Warn", policy.warn_days);
          denySentence = policy.deny_date
            ? `- Block from: ${policy.deny_date}`
            : renderSentence("Block", policy.deny_days);
        } else if (policy.policy_scope === PROJECT_SCOPE) {
          firstSentence = `All builds within ${decodeSpaces(
            policy.project_name
          )} with unverified images will:`;
          warnSentence = policy.warn_date
            ? `- Warn from: ${policy.warn_date}`
            : renderSentence("Warn", policy.warn_days);
          denySentence = policy.deny_date
            ? `- Block from: ${policy.deny_date}`
            : renderSentence("Block", policy.deny_days);
        }
      }

      return (
        <Space align="start" direction="vertical">
          <Text type="secondary">{firstSentence}</Text>
          <Text type="secondary">{warnSentence}</Text>
          <Text type="secondary">{denySentence}</Text>
        </Space>
      );
    };

    const getLogo = (policy: Policy): string => {
      if (policy.policy_type === EOL_POLICY) {
        switch (policy.policy_scope) {
          case GLOBAL_SCOPE:
            return "global-logo";
          case PROJECT_SCOPE:
            return "project-logo";
          case SOFTWARE_SCOPE:
            return policy.product_name;
        }
      } else if (policy.policy_type === NOTARY_POLICY) {
        switch (policy.policy_scope) {
          case GLOBAL_SCOPE:
            return "global-notary-logo";
          case PROJECT_SCOPE:
            return "project-notary-logo";
          case SOFTWARE_SCOPE:
            return policy.product_name;
        }
      }

      return "";
    };

    return policies.map((policy: Policy) => (
      <div key={policy.id}>
        <Card
          key={policy.id}
          width={500}
          actions={[
            <Button
              key="edit"
              type="link"
              icon={<EditOutlined />}
              onClick={() => showPolicyEditModal(policy)}
            >
              Edit
            </Button>,
            <Popconfirm
              key="remove-policy-pop"
              title="Remove policy"
              description="Are you sure you want to remove this policy?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              okButtonProps={{
                style: {
                  backgroundColor: "red",
                  color: "white",
                },
              }}
              onConfirm={handleDeleteOk}
              onCancel={handleDeleteCancel}
            >
              <Button
                key="delete"
                type="link"
                icon={<DeleteOutlined />}
                style={{ color: "red" }}
                onClick={() => showDeleteModal(policy)}
              >
                Delete
              </Button>
            </Popconfirm>,
          ]}
        >
          <Meta
            style={{ margin: 24 }}
            avatar={
              <Avatar size={48} src={renderSoftwareIcon(getLogo(policy), "")} />
            }
            title={renderCardText(policy)}
            description={renderCardContent(policy)}
          />
        </Card>
      </div>
    ));
  };

  const inPlacePolicyListReplace = (policies: Policy[], newPolicy: Policy) => {
    const newPolicies = [];

    for (const policy of policies) {
      if (policy.id == newPolicy.id) {
        newPolicies.push(newPolicy);
      } else {
        newPolicies.push(policy);
      }
    }

    return newPolicies;
  };

  const renderBeforeAfterEolSelect = (key: string) => {
    return (
      <Form.Item style={{ margin: 0, padding: 0 }} name={key}>
        <Select value="before" style={{ width: 120, textAlign: "left" }}>
          <Option value="before">Before EOL</Option>
          <Option value="after">After EOL</Option>
        </Select>
      </Form.Item>
    );
  };

  const onFormSubmit = async (values: any) => {
    const validateSoftwareEOLPolicy = (values: any) => {
      if (!values.warn_date && !values.deny_date) {
        message.error("You must select at least one warn date or block date");
        return;
      }

      if (values.warn_date) {
        values.warn_date = values.warn_date.format("YYYY-MM-DDT00:00:00");
      } else {
        values.warn_date = null;
      }

      if (values.deny_date) {
        values.deny_date = values.deny_date.format("YYYY-MM-DDT00:00:00");
      } else {
        values.deny_date = null;
      }

      if (values.cycle) {
        values.cycle = values.cycle[0];
      }

      return values;
    };

    const validateGlobalEOLPolicy = (values: any) => {
      if (values.warn_days === undefined && values.deny_days === undefined) {
        message.error("You must input at least one warn days or block days");
        return;
      }

      if (values.warn_before_after === "after") {
        values.warn_days = -values.warn_days;
      }

      if (values.deny_before_after === "after") {
        values.deny_days = -values.deny_days;
      }

      return values;
    };

    const validateGlobalNotaryPolicy = (values: any) => {
      if (!values.warn_date && !values.deny_date) {
        message.error("You must select at least one warn date or block date");
        return;
      }

      if (values.warn_date) {
        values.warn_date = values.warn_date.format("YYYY-MM-DDT00:00:00");
      } else {
        values.warn_date = null;
      }

      if (values.deny_date) {
        values.deny_date = values.deny_date.format("YYYY-MM-DDT00:00:00");
      } else {
        values.deny_date = null;
      }

      values.notary_policy_json = JSON.parse(values.notary_policy_json);

      return values;
    };

    const validatePolicyInputs = (values: any) => {
      if (
        (values.policy_scope === GLOBAL_SCOPE ||
          values.policy_scope === PROJECT_SCOPE) &&
        values.policy_type === EOL_POLICY
      ) {
        return validateGlobalEOLPolicy(values);
      } else if (
        values.policy_scope === SOFTWARE_SCOPE &&
        values.policy_type === EOL_POLICY
      ) {
        return validateSoftwareEOLPolicy(values);
      } else if (
        (values.policy_scope === GLOBAL_SCOPE ||
          values.policy_scope === PROJECT_SCOPE) &&
        values.policy_type === NOTARY_POLICY
      ) {
        return validateGlobalNotaryPolicy(values);
      }
      return values;
    };

    values = validatePolicyInputs(values);

    try {
      const token = await getAccessTokenSilently();

      if (currentPolicy) {
        const res = await axios.patch(
          `${API_URL}/v1/policy/${currentPolicy.id}`,
          values,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        setPolicies(inPlacePolicyListReplace(policies, res.data));
        handlePolicyEditCancel();
        message.success(
          `${values.policy_scope} ${values.policy_type} policy updated!`
        );
      } else {
        const res = await axios.post(`${API_URL}/v1/policy`, values, {
          headers: { Authorization: `Bearer ${token}` },
        });

        policies.push(res.data);
        handlePolicyAddCancel();
        message.success(
          `New ${values.policy_scope} ${values.policy_type} policy added!`
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const policyFormItems = [
    {
      name: "policy_scope",
      label: "Policy Scope",
      rules: [{ required: true, message: "Policy scope is required" }],
      content: (
        <Select
          value={GLOBAL_SCOPE}
          style={formItemStyle}
          options={[
            { value: GLOBAL_SCOPE, label: "Global" },
            { value: PROJECT_SCOPE, label: "Project" },
            { value: SOFTWARE_SCOPE, label: "Software" },
          ]}
          onChange={handlePolicyScopeSelect}
          disabled={currentPolicy ? true : false}
        />
      ),
      tooltip: (
        <Tooltip title="Order of precendence: Software overrides Project which overrides Global">
          <QuestionCircleOutlined style={tooltipStyle} />
        </Tooltip>
      ),
      enabled: true,
    },
    {
      name: "policy_type",
      label: "Policy Type",
      rules: [],
      content: (
        <Select
          value="EOL"
          style={formItemStyle}
          options={[
            { value: EOL_POLICY, label: "EOL" },
            { value: NOTARY_POLICY, label: "Notary" },
          ]}
          onChange={handlePolicyTypeSelect}
          disabled={
            policyScope !== GLOBAL_SCOPE || currentPolicy ? true : false
          }
        />
      ),
      tooltip: (
        <Tooltip
          title={
            policyScope !== GLOBAL_SCOPE
              ? "Notary policies can only be set at a global level right now"
              : "Set EOL policies or Notary policies that affects all builds"
          }
        >
          <QuestionCircleOutlined style={tooltipStyle} />
        </Tooltip>
      ),
      enabled: true,
    },
    {
      name: "warn_days",
      label: "Warn",
      content: (
        <InputNumber
          style={formItemStyle}
          addonAfter={renderBeforeAfterEolSelect("warn_before_after")}
          min={0}
          step={10}
        />
      ),
      tooltip: (
        <Tooltip title="You must specify either warn or block days. 0 means on the EOL day itself.">
          <QuestionCircleOutlined style={tooltipStyle} />
        </Tooltip>
      ),
      enabled:
        policyScope !== SOFTWARE_SCOPE && policyType !== NOTARY_POLICY
          ? true
          : false,
    },
    {
      name: "deny_days",
      label: "Block",
      content: (
        <InputNumber
          style={formItemStyle}
          addonAfter={renderBeforeAfterEolSelect("deny_before_after")}
          min={0}
          step={10}
        />
      ),
      tooltip: (
        <Tooltip title="You must specify either warn or block days. 0 means on the EOL day itself.">
          <QuestionCircleOutlined style={tooltipStyle} />
        </Tooltip>
      ),
      enabled:
        policyScope !== SOFTWARE_SCOPE && policyType !== NOTARY_POLICY
          ? true
          : false,
    },
    {
      name: "warn_date",
      label: "Warn From",
      rules: [{ required: false, message: "Warn date is required" }],
      content: <DatePicker style={formItemStyle} format="YYYY-MM-DD" />,
      tooltip: (
        <Tooltip title="You must specify either a warn or block date">
          <QuestionCircleOutlined style={tooltipStyle} />
        </Tooltip>
      ),
      enabled:
        policyScope === SOFTWARE_SCOPE || policyType == NOTARY_POLICY
          ? true
          : false,
    },
    {
      name: "deny_date",
      label: "Block From",
      rules: [{ required: false, message: "Block date type is required" }],
      content: <DatePicker style={formItemStyle} format="YYYY-MM-DD" />,
      tooltip: (
        <Tooltip title="You must specify either a warn or block date">
          <QuestionCircleOutlined style={tooltipStyle} />
        </Tooltip>
      ),
      enabled:
        policyScope === SOFTWARE_SCOPE || policyType == NOTARY_POLICY
          ? true
          : false,
    },
    {
      name: "project_name",
      label: "Project Name",
      rules: [{ required: true, message: "Project name is required" }],
      content: (
        <Select
          style={formItemStyle}
          options={projectsList}
          disabled={currentPolicy ? true : false}
          showSearch
        />
      ),
      tooltip: null,
      enabled: policyScope === PROJECT_SCOPE ? true : false,
    },
    {
      name: "notary_policy_json",
      label: "Notary Policy JSON",
      rules: [
        { required: true, message: "Notary Policy JSON name is required" },
      ],
      content: (
        <JSONTextArea
          style={formItemStyle}
          disabled={currentPolicy ? true : false}
        />
      ),
      tooltip: null,
      enabled:
        policyScope === GLOBAL_SCOPE && policyType === NOTARY_POLICY
          ? true
          : false,
    },
    {
      name: "product_name",
      label: "Software Name",
      rules: [{ required: true, message: "Software name is required" }],
      content: (
        <Select
          style={formItemStyle}
          options={softwareList}
          onChange={handleProductNameSelect}
          disabled={currentPolicy ? true : false}
        />
      ),
      tooltip: null,
      enabled: policyScope === "software" ? true : false,
    },
    {
      name: "cycle",
      label: "Software Version",
      rules: [
        { required: true, message: "Software version is required" },
        {
          validator: (_: any, value: any) => {
            if (value && value.length > 1) {
              return Promise.reject(new Error("Only one version per policy"));
            }
            return Promise.resolve();
          },
        },
      ],
      content: (
        <Select
          mode="tags"
          style={formItemStyle}
          options={versionList}
          disabled={currentPolicy ? true : false}
        />
      ),
      tooltip: (
        <Tooltip title="The versions in the dropdown are from your scanned builds, you can specify a custom version not in the dropdown by just typing it.">
          <QuestionCircleOutlined style={tooltipStyle} />
        </Tooltip>
      ),
      enabled: policyScope === SOFTWARE_SCOPE ? true : false,
    },
    {
      name: "cycle_operator",
      label: "Version Operator",
      rules: [{ required: true, message: "Version Operator is required" }],
      content: (
        <Select
          style={formItemStyle}
          options={[
            { value: "LTE", label: "≤" },
            { value: "LT", label: "<" },
            { value: "EQ", label: "=" },
          ]}
        />
      ),
      tooltip: (
        <Tooltip title="(=) apply this policy to the exact version, (<) apply this policy to all lower versions as well, (≤) apply this policy to this and all lower versions">
          <QuestionCircleOutlined style={tooltipStyle} />
        </Tooltip>
      ),
      enabled: policyScope === SOFTWARE_SCOPE ? true : false,
    },
  ];

  const policyFormInitialValues = {
    policy_scope: GLOBAL_SCOPE,
    policy_type: "EOL",
    warn_before_after: "before",
    deny_before_after: "before",
  };

  useEffect(() => {
    const fetchPolicies = async () => {
      try {
        const token = await getAccessTokenSilently();
        const res = await axios.get(`${API_URL}/v1/policy`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setPolicies(res.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPolicies();
  }, [getAccessTokenSilently, API_URL]);

  return (
    <RBACView view="policies">
      <Content style={contentStyle}>
        <Helmet>
          <title>Policies - Xeol</title>
        </Helmet>
        {loading ? (
          <Loading></Loading>
        ) : (
          <Space align="center" direction="vertical">
            <Button
              style={addPolicyButtonStyle}
              type="dashed"
              onClick={showPolicyAddModal}
            >
              Add Policy
            </Button>
            <QueueAnim type={["right", "left"]} leaveReverse>
              {renderPolicies()}
            </QueueAnim>
            <Modal
              open={isPolicyEditModalOpen || isPolicyAddModalOpen}
              onCancel={
                currentPolicy ? handlePolicyEditCancel : handlePolicyAddCancel
              }
              bodyStyle={{ paddingTop: 48 }}
              width={700}
              footer={[]}
            >
              <Form
                form={form}
                name="policy-form"
                onFinish={onFormSubmit}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={policyFormInitialValues}
              >
                {policyFormItems.map(
                  (item) =>
                    item.enabled && (
                      <Form.Item key={item.name} label={item.label}>
                        <Space>
                          <Form.Item
                            name={item.name}
                            rules={item.rules}
                            noStyle
                          >
                            {item.content}
                          </Form.Item>
                          {item.tooltip}
                        </Space>
                      </Form.Item>
                    )
                )}
                <Form.Item label="buttons" noStyle>
                  <Space
                    align="end"
                    direction="horizontal"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      key="cancel-add-edit"
                      onClick={
                        currentPolicy
                          ? handlePolicyEditCancel
                          : handlePolicyAddCancel
                      }
                    >
                      Cancel
                    </Button>
                    <Button key="ok-add-edit" type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Modal>
          </Space>
        )}
      </Content>
    </RBACView>
  );
};

export default PoliciesPage;
