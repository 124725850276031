import { EnumType, VariableType } from "json-to-graphql-query";

export const FormSelectQuery = {
  query: {
    __variables: {
      type: "[String!]",
      namespace: "String",
      name: "String",
      projectName: "String",
      projectNamespace: "String",
      projectType: "String",
    },
    projects: {
      __args: {
        where: {
          type: new VariableType("projectType"),
          name: new VariableType("projectName"),
          namespace: new VariableType("projectNamespace"),
        },
      },
      edges: {
        node: {
          commits: {
            __args: {
              first: 1,
              orderBy: {
                direction: new EnumType("DESC"),
                field: new EnumType("CREATED_AT"),
              },
            },
            edges: {
              node: {
                hasSbom: {
                  edges: {
                    node: {
                      package: {
                        version: true,
                        name: {
                          name: true,
                          namespace: {
                            namespace: true,
                            type: {
                              type: true,
                            },
                          },
                        },
                      },
                      id: true,
                    },
                  },
                },
              },
            },
          },
          id: true,
          name: true,
          owner: true,
          namespace: true,
          type: true,
        },
      },
    },
    packageNamespaces: {
      __args: {
        orderBy: {
          field: new EnumType("NAMESPACE"),
          direction: new EnumType("DESC"),
        },
        where: {
          namespaceNEQ: "",
          hasTypeWith: {
            typeNEQ: "",
            typeIn: new VariableType("type"),
          },
        },
      },
      edges: {
        node: {
          namespace: true,
        },
      },
    },
    packageTypes: {
      __args: {
        orderBy: {
          field: new EnumType("TYPE"),
          direction: new EnumType("DESC"),
        },
        where: {
          typeNEQ: "xeol",
        },
      },
      edges: {
        node: {
          type: true,
        },
      },
    },
    isVulnerabilities: {
      osv: {
        id: true,
        osvID: true,
      },
    },
    packageNames: {
      __args: {
        first: 100,
        orderBy: {
          field: new EnumType("NAME"),
          direction: new EnumType("DESC"),
        },
        where: {
          nameNotIn: ["", " ", "+", "."],
          nameContainsFold: new VariableType("name"),
          hasNamespaceWith: {
            namespace: new VariableType("namespace"),
            hasTypeWith: {
              typeNEQ: "",
              typeIn: new VariableType("type"),
            },
          },
        },
      },
      edges: {
        node: {
          name: true,
        },
      },
    },
  },
};

export interface FormSelectQueryResponse {
  projects: {
    edges: Array<{
      node: {
        commits: {
          edges: Array<{
            node: {
              hasSbom: {
                edges: Array<{
                  node: {
                    package: {
                      version: string;
                      name: {
                        name: string;
                        namespace: {
                          namespace: string;
                          type: {
                            type: string;
                          };
                        };
                      };
                    };
                    id: string;
                  };
                }>;
              };
            };
          }>;
        };
        id: string;
        name: string;
        namespace: string;
        owner: string;
        type: string;
      };
    }>;
  };
  packageNamespaces: {
    edges: Array<{
      node: {
        namespace: string;
      };
    }>;
  };
  packageTypes: {
    edges: Array<{
      node: {
        type: string;
      };
    }>;
  };
  isVulnerabilities: Array<{
    osv: {
      id: string;
      osvID: string;
    };
  }>;
  packageNames: {
    edges: Array<{
      node: {
        name: string;
      };
    }>;
  };
}
