import React from "react";
import { Layout } from "antd";

import ForceDirectedGraph from "../components/graph/ForceDirectedGraph";
import { Helmet } from "react-helmet-async";

const { Content } = Layout;

const GraphPage: React.FC = () => {
  return (
    <Content>
      <Helmet>
        <title>Graph - Xeol</title>
      </Helmet>
      <ForceDirectedGraph></ForceDirectedGraph>
    </Content>
  );
};

export default GraphPage;
