import { Button, Layout } from "antd";
import { RadarChartOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

interface Props {
  sbomID: string;
  project: string;
  target: string;
  package_version_id: string;
}

const GraphProjectButton: React.FC<Props> = ({
  sbomID,
  project,
  target,
  package_version_id,
}) => {
  const navigate = useNavigate();

  const onClick = () => {
    const encodedProject = encodeURIComponent(project);
    const encodedTarget = encodeURIComponent(target);
    navigate(
      `/graph?project=${encodedProject}&target=${encodedTarget}&sbom_id=${sbomID}&package_version_id=${package_version_id}&direct=0`
    );
  };

  return (
    <Content onClick={(e: any) => e.stopPropagation()}>
      <Button
        icon={<RadarChartOutlined />}
        size="small"
        onClick={onClick}
      />
    </Content>
  );
};

export default GraphProjectButton;
