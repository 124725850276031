import React from "react";
import { Tag } from "antd";
import { LinkOutlined, DisconnectOutlined } from "@ant-design/icons";
import { PackageNodeOssfScore, Maybe } from "../gql/graphql";
import { Typography } from "antd";
const { Link } = Typography;

interface OSSFScoreProps {
  score: Maybe<PackageNodeOssfScore> | undefined;
}

const OSSFScore: React.FC<OSSFScoreProps> = ({ score }) => {
  if (!score || score.score === 0 || !score.score) {
    return <span>-</span>;
  }

  const formattedScore = score.score.toFixed(1);

  return score.link ? (
    <Link href={score.link} target="_blank" rel="noopener noreferrer">
      <Tag color="green">
        <LinkOutlined /> {formattedScore}
      </Tag>
    </Link>
  ) : (
    <Tag color="green">
      <DisconnectOutlined /> {formattedScore}
    </Tag>
  );
};

export default OSSFScore;
