import {
  Col,
  Badge,
  Layout,
  Row,
  Space,
  Statistic,
  Tooltip,
  Typography,
  Button,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
import React, { useEffect, useState } from "react";
import {
  DownCircleOutlined,
  ExportOutlined,
  InfoCircleOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import {
  ArrayParam,
  DateParam,
  StringParam,
  createEnumParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import Card from "../components/Card";
import DateRangePicker from "../components/filters/DateRangePicker";
import Loading from "../components/Loading";
import RadarChart from "../components/visualization/RadarChart";
import ScreenCaptureButton from "../components/buttons/ScreenCaptureButton";
import StackedBarChart from "../components/visualization/StackedBarChart";
import TinyAreaChart from "../components/visualization/TinyAreaChart";
import { Helmet } from "react-helmet-async";
import {
  PackageType,
  Period,
  VulnCount,
  useGetAnalyticsQuery,
} from "../gql/graphql";
import {
  MiniData,
  PieData,
  TrendBarData,
  TrendData,
  calcOverallSupplyChainScore,
  transformEolData,
  transformOssfData,
  transformVulnData,
} from "../utils/analytics";
import dayjs, { Dayjs } from "dayjs";
import PieChart from "../components/visualization/PieChart";
import DependencySelectFilter from "../components/filters/DependencySelectFilter";
import { LightFilter, ProFormSelect } from "@ant-design/pro-components";
import NotFoundSelect from "../components/filters/NotFoundSelect";
import Link from "antd/es/typography/Link";
import TrendCard from "../components/visualization/TrendCard";

const { Content } = Layout;
const { Text } = Typography;

const contentStyle = {
  width: "100%",
  height: "100%",
  minWidth: 800,
};

const defaultRange90Days: [Dayjs, Dayjs] = [dayjs().add(-90, "d"), dayjs()];

const AnalyticsPage: React.FC = () => {
  const [margin, setMargin] = useState(12);
  const [radarChartData, setRadarChartData] = useState<PieData[]>();
  const [overallSupplyChainScore, setOverallSupplyChainScore] = useState("-");
  const [loading, setLoading] = useState(true);

  const [vulnCriticalMiniData, setVulnCriticalMiniData] = useState<MiniData>();
  const [vulnTrendBarData, setVulnTrendBarData] = useState<TrendBarData[]>();
  const [vulnCurrentData, setVulnCurrentData] = useState<PieData[]>([]);
  const [vulnTrendLineData, setVulnTrendLineData] = useState<TrendData[]>();

  const [eolCriticalMiniData, setEolCriticalMiniData] = useState<MiniData>();
  const [eolTrendBarData, setEolTrendBarData] = useState<TrendBarData[]>();
  const [eolCurrentData, setEolCurrentData] = useState<PieData[]>([]);
  const [eolTrendLineData, setEolTrendLineData] = useState<TrendData[]>();

  const [ossfCriticalMiniData, setOssfCriticalMiniData] = useState<MiniData>();
  const [ossfTrendBarData, setOssfTrendBarData] = useState<TrendBarData[]>();
  const [ossfCurrentData, setOssfCurrentData] = useState<PieData[]>([]);
  const [ossfTrendLineData, setOssfTrendLineData] = useState<TrendData[]>([]);

  const [packageTypes, setPackageTypes] = useState<DefaultOptionType[]>([]);

  const [project, setProject] = useQueryParam("project", StringParam);
  const [projectID, setProjectID] = useQueryParam("pid", StringParam);
  const [projectSbomIDs, setProjectSbomIDs] = useQueryParam(
    "psbids",
    withDefault(ArrayParam, [])
  );

  const packageTypeEnumParam = createEnumParam(Object.values(PackageType));
  const [packageTypeSelected = PackageType.All, setPackageTypeSelected] =
    useQueryParam("type", packageTypeEnumParam);

  const periodEnumParam = createEnumParam(Object.values(Period));
  const [period = Period.Daily, setPeriod] = useQueryParam(
    "period",
    periodEnumParam
  );

  const [rangeStart = defaultRange90Days[0].toDate(), setRangeStart] =
    useQueryParam("start", DateParam);
  const [rangeEnd = defaultRange90Days[1].toDate(), setRangeEnd] =
    useQueryParam("end", DateParam);

  const updateRadarChartData = (
    nameToUpdate: string,
    newValue: number
  ): void => {
    setRadarChartData((prevData) => {
      // If prevData is undefined, initialize it as an empty array
      const currentData = prevData || [];

      // Check if the entry exists in the current data
      const entryIndex = currentData.findIndex(
        (item) => item.name === nameToUpdate
      );

      let updatedData;
      if (entryIndex !== -1) {
        // Update the existing entry
        updatedData = currentData.map((item, index) =>
          index === entryIndex ? { ...item, value: newValue } : item
        );
      } else {
        // Add a new entry
        updatedData = [...currentData, { name: nameToUpdate, value: newValue }];
      }

      // Extract and calculate the scores
      const vulnScore =
        updatedData.find((item) => item.name === "Vulnerability")?.value || 0;
      const eolScore =
        updatedData.find((item) => item.name === "EOL")?.value || 0;
      const ossfScore =
        updatedData.find((item) => item.name === "Maintainability")?.value || 0;
      const overallScore = calcOverallSupplyChainScore(
        vulnScore,
        eolScore,
        ossfScore
      );

      // Update the overall supply chain score
      setOverallSupplyChainScore(overallScore);

      return updatedData;
    });
  };

  const renderTopStatsBar = () => {
    const renderOverallScoreCard = (disabled = false) => {
      return (
        <Card style={{ margin: margin }}>
          <Statistic
            title={
              <Content>
                Supply Chain Score{" "}
                <Tooltip title="This is the health of your supply chain. This score comes from your vulnerability, maintainability, and end-of-life scores.">
                  <InfoCircleOutlined />
                </Tooltip>
              </Content>
            }
            value={disabled ? "-" : overallSupplyChainScore}
          />
          <RadarChart data={radarChartData} height={194} disabled={disabled} />
        </Card>
      );
    };

    const renderStatsCard = (
      title: string,
      tooltip: string,
      numerator: number,
      denominator: number,
      miniChartData: number[],
      disabled = false,
      coming = false
    ) => {
      const percentageString =
        denominator === 0
          ? numerator === 0
            ? "0%"
            : "N/A"
          : ((numerator / denominator) * 100).toFixed(
              (numerator / denominator) * 100 < 1 ? 1 : 0
            ) + "%";

      const card = (
        <Card style={{ margin: margin }}>
          <Statistic
            title={
              disabled ? (
                <Content>
                  <Text type="secondary">{title}</Text>
                </Content>
              ) : (
                <Content>
                  {title}{" "}
                  <Tooltip title={tooltip}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Content>
              )
            }
            value={disabled ? " " : percentageString}
            suffix={
              disabled ? (
                ""
              ) : (
                <Text type="secondary">
                  ({`${numerator} / ${denominator}`})
                </Text>
              )
            }
          />
          <TinyAreaChart data={miniChartData} height={40} disabled={disabled} />
        </Card>
      );

      if (coming === true) {
        return <Badge.Ribbon text="Coming Soon">{card}</Badge.Ribbon>;
      }

      return card;
    };

    return (
      <Row>
        <Col span={8}>{renderOverallScoreCard()}</Col>
        <Col span={16}>
          <Row>
            <Col span={12}>
              {renderStatsCard(
                "Critical Vulnerabilities",
                "Number of dependencies with a critical CVSS score. The domain is the total number of vulnerabilities of any CVSS score.",
                vulnCriticalMiniData?.numerator || 0,
                vulnCriticalMiniData?.denominator || 0,
                vulnCriticalMiniData?.percents || []
              )}
            </Col>
            <Col span={12}>
              {renderStatsCard(
                "End-of-Life Software",
                "Number of end-of-life dependencies. The domain is the total number of dependencies with end-of-life dates.",
                eolCriticalMiniData?.numerator || 0,
                eolCriticalMiniData?.denominator || 0,
                eolCriticalMiniData?.percents || []
              )}
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              {renderStatsCard(
                "Low Quality Packages",
                "Number of dependencies with an OSSF score lower than 4. The domain is the total number of dependencies with an OSSF score.",
                ossfCriticalMiniData?.numerator || 0,
                ossfCriticalMiniData?.denominator || 0,
                ossfCriticalMiniData?.percents || []
              )}
            </Col>
            <Col span={12}>
              {renderStatsCard(
                "Copyleft Licenses",
                "Number of dependencies with a copyleft license. The domain is the total number of dependencies with known licenses.",
                0,
                0,
                [],
                true,
                true
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const handleFilterChange = (
    filterName: string,
    option:
      | { label: string; value: string }
      | { label: string; value: string }[],
    meta?: any
  ) => {
    switch (filterName) {
      case "project":
        option = option as { label: string; value: string };
        setProject(option?.label || undefined);
        setProjectID(meta?.projectID || undefined);
        setProjectSbomIDs(meta?.projectSbomIDs || []);
        break;
      default:
        break;
    }
  };

  const renderActionBar = () => {
    return (
      <Content style={{ display: "flex", justifyContent: "space-between" }}>
        <Space style={{ marginLeft: margin }} direction="horizontal">
          <DependencySelectFilter
            filters={{ project }}
            enabledFilters={{
              type: false,
              namespace: false,
              name: false,
              nameSelect: false,
              project: true,
              target: false,
              cvssLevels: false,
              scoreLevels: false,
              lifecycleLevels: false,
              lifecycleReasons: false,
              vulnerability: false,
            }}
            onFilterChange={handleFilterChange}
          />
          <LightFilter size="middle" collapse={false} loading={loading}>
            <ProFormSelect
              name="ecosystem"
              label="ecosystem"
              allowClear={false}
              style={{ width: 130 }}
              initialValue={"ALL"}
              onChange={(value: PackageType) => {
                setPackageTypeSelected(value);
              }}
              fieldProps={{
                notFoundContent: <NotFoundSelect />,
              }}
              options={packageTypes}
            />
          </LightFilter>
          <DateRangePicker
            period={period}
            rangeStart={rangeStart}
            rangeEnd={rangeEnd}
            setPeriod={setPeriod}
            setRangeStart={setRangeStart}
            setRangeEnd={setRangeEnd}
          />
        </Space>
        <Space style={{ marginRight: 12 }}>
          <ScreenCaptureButton />
        </Space>
      </Content>
    );
  };

  const renderTrends = () => {
    const getQueryParams = (kind: string, level: string) => {
      const params = new URLSearchParams();

      if (packageTypeSelected && packageTypeSelected != PackageType.All) {
        params.append("type", packageTypeSelected.toLowerCase());
      }

      if (project) {
        params.append("project", project);
      }

      if (projectSbomIDs) {
        for (const sbomID of projectSbomIDs) {
          !sbomID || params.append("psbids", sbomID);
        }
      }

      if (kind === "Vulnerabilities") {
        params.append("cvss", level);
      } else if (kind === "Unsupported Software") {
        params.append("lifecycle", level);
      } else if (kind === "Package Health") {
        params.append("score", level);
      }

      return params;
    };

    return (
      <Content>
        <Helmet>
          <title>Home - Xeol</title>
        </Helmet>
        <Row>
          <Col span={24}>
            <TrendCard
              title="Vulnerabilities"
              margin={margin}
              pieData={vulnCurrentData}
              barData={vulnTrendBarData}
              lineData={vulnTrendLineData}
              levelsMap={{
                Critical: "critical",
                High: "high",
                Medium: "medium",
                Low: "low",
              }}
              getQueryParams={getQueryParams}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TrendCard
              title="Unsupported Software"
              margin={margin}
              pieData={eolCurrentData}
              barData={eolTrendBarData}
              lineData={eolTrendLineData}
              levelsMap={{
                Critical: "critical",
                High: "high",
                Medium: "medium",
                Low: "low",
              }}
              getQueryParams={getQueryParams}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <TrendCard
              title="Package Health"
              margin={margin}
              pieData={ossfCurrentData}
              barData={ossfTrendBarData}
              lineData={ossfTrendLineData}
              levelsMap={{
                "0.1 - 3.9": "low",
                "4.0 - 6.9": "medium",
                "7.0 - 8.9": "high",
                "9.0 - 10.0": "superb",
              }}
              getQueryParams={getQueryParams}
            />
          </Col>
        </Row>
      </Content>
    );
  };

  const mapStringToPackageTypeEnum = (
    value: string | null
  ): PackageType | undefined => {
    if (!value) {
      return undefined;
    }
    if ((PackageType as any)[value]) {
      return (PackageType as any)[value] as PackageType;
    }
    return undefined;
  };

  const {
    data,
    loading: queryLoading,
    error,
  } = useGetAnalyticsQuery({
    variables: {
      start: rangeStart,
      end: rangeEnd,
      period: period ? period : Period.Daily,
      projectID: projectID,
      packageType: mapStringToPackageTypeEnum(packageTypeSelected),
    },
  });

  useEffect(() => {
    if (data && !queryLoading) {
      const {
        trendBarData: vulntbd,
        pieData: vulncurrent,
        trendData: vulntld,
        miniData: vulnmini,
        supplyChainScore: supplyChainVulnScore,
      } = transformVulnData(
        data.getAnalytics.vulnCounts as VulnCount[],
        data.getAnalytics.allCounts.vulnCounts as VulnCount[]
      );

      setVulnTrendBarData(vulntbd);
      setVulnCurrentData(vulncurrent);
      setVulnTrendLineData(vulntld);
      setVulnCriticalMiniData(vulnmini);

      /////

      const {
        trendBarData: eoltbd,
        pieData: eolcurrent,
        trendData: eoltld,
        miniData: eolmini,
        supplyChainScore: supplyChainEolScore,
      } = transformEolData(
        data.getAnalytics.eolCounts as VulnCount[],
        data.getAnalytics.allCounts.eolCounts as VulnCount[]
      );

      setEolTrendBarData(eoltbd);
      setEolCurrentData(eolcurrent);
      setEolTrendLineData(eoltld);
      setEolCriticalMiniData(eolmini);

      /////

      const {
        trendBarData: ossftbd,
        pieData: ossfcurrent,
        trendData: ossftld,
        miniData: ossfmini,
        supplyChainScore: supplyChainOssfScore,
      } = transformOssfData(
        data.getAnalytics.ossfCounts as VulnCount[],
        data.getAnalytics.allCounts.ossfCounts as VulnCount[]
      );

      setOssfTrendBarData(ossftbd);
      setOssfCurrentData(ossfcurrent);
      setOssfTrendLineData(ossftld);
      setOssfCriticalMiniData(ossfmini);

      //////

      const packageTypeSelect = data.getAnalytics.packageTypes.map((item) => ({
        value: item,
        label: item,
      }));
      setPackageTypes(packageTypeSelect);

      updateRadarChartData("Vulnerability", supplyChainVulnScore);
      updateRadarChartData("Vulnerability", supplyChainVulnScore);
      updateRadarChartData("EOL", supplyChainEolScore);
      updateRadarChartData("Maintainability", supplyChainOssfScore);
      setLoading(false);
    }

    if (error) {
      console.log(error.message);
      console.log(error.stack);
      console.log(error.cause);
    }
  }, [data, error, queryLoading]);

  return (
    <Content style={contentStyle}>
      {loading ? (
        <Loading />
      ) : (
        <Space style={{ width: "100%" }} direction="vertical">
          {renderTopStatsBar()}
          {renderActionBar()}
          {renderTrends()}
        </Space>
      )}
    </Content>
  );
};

export default AnalyticsPage;
