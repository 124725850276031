import { Button } from "antd";
import {
  DownCircleOutlined,
  MinusCircleOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";

const SortingButtonStyle = {
  marginLeft: 0,
  paddingLeft: 0,
  fontWeight: "bold",
};

interface Props {
  label: string;
  disabled?: boolean;
  dataKey?: string;
  onClick: (event: any, sortOrder: string) => void;
}

export const SORT_ASC = "ASC";
export const SORT_DES = "DES";
export const SORT_NIL = "NIL";

const SortButton: React.FC<Props> = ({
  label,
  disabled = false,
  dataKey = "",
  onClick,
}) => {
  const [sortOrder, setSortOrder] = useState(SORT_ASC);

  const renderSortButtonIcon = (sortOrder: string) => {
    if (disabled) {
      return <></>;
    }

    switch (sortOrder) {
      case SORT_ASC:
        return <UpCircleOutlined />;
      case SORT_DES:
        return <DownCircleOutlined />;
      default:
        return <MinusCircleOutlined />;
    }
  };

  const onSortButtonClick = (event: any) => {
    if (sortOrder == SORT_ASC) {
      setSortOrder(SORT_DES);
    } else if (sortOrder == SORT_DES) {
      setSortOrder(SORT_ASC);
    }
    onClick(event, sortOrder);
  };

  return (
    <Button
      style={SortingButtonStyle}
      type="link"
      onClick={onSortButtonClick}
      data-key={dataKey}
    >
      {label}
      {renderSortButtonIcon(sortOrder)}
    </Button>
  );
};

export default SortButton;
