import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Input,
  Layout,
  message,
  Popconfirm,
  Row,
  Space,
  Typography,
} from "antd";
import {
  CopyOutlined,
  DownCircleOutlined,
  EditOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect, ChangeEvent } from "react";

import Card from "../components/Card";
import Loading from "../components/Loading";
import RBACView from "../utils/jwt";
import { OpenAPI, DefaultService } from "../client";
import { Helmet } from "react-helmet-async";

const { Content } = Layout;
const { TextArea } = Input;
const { Text } = Typography;

const InputStyle = {
  width: 440,
};

const ButtonStyle = {
  width: 160,
};

type ApiKey = {
  id: string;
  key: string;
  organization: string;
};

const SettingsPage: React.FC = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [orgName, setOrgName] = useState("");
  const [orgId, setOrgId] = useState("");
  const [apiKey, setApiKey] = useState<ApiKey | null>(null);
  const [regenerateModalOpen, setIsRegenerateModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [certificate, setCertificate] = useState("");
  const [isEditingCertificate, setIsEditingCertificate] = useState(false);
  const [isCardCollapsed, setIsCardCollapsed] = useState(true);

  const { user, getAccessTokenSilently } = useAuth0();

  const handleRegenerateOk = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.patch(
        `${API_URL}/api_key/${apiKey?.id}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setApiKey(res.data);
    } catch (error) {
      console.error(error);
    }

    handleRegenerateCancel();
  };

  const handleRegenerateCancel = () => {
    setIsRegenerateModalOpen(false);
  };

  const handleOrgNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrgName(e.target.value);
  };

  const handleUpdateClick = async () => {
    try {
      const token = await getAccessTokenSilently();
      const res = await axios.patch(
        `${API_URL}/organizations/${orgId}`,
        { name: orgName },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setOrgName(res.data.name);
      message.success(`Company name changed to ${orgName}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGenerateApiKeyClick = async () => {
    try {
      if (apiKey) {
        setIsRegenerateModalOpen(true);
      } else {
        const token = await getAccessTokenSilently();
        const res = await axios.post(
          `${API_URL}/api_key`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setApiKey(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const copyApiKey = async () => {
    if (apiKey?.key) {
      try {
        await navigator.clipboard.writeText(apiKey.key);
        message.success("Copied to clipboard");
      } catch (err) {
        message.error("Failed to copy to clipboard");
      }
    } else {
      message.error("Generate an API Key first");
    }
  };

  const handleCertificateSave = async () => {
    try {
      const token = await getAccessTokenSilently();
      OpenAPI.BASE = API_URL!;
      OpenAPI.HEADERS = {
        Authorization: `Bearer ${token}`,
      };
      const resp = await DefaultService.createCertificateCertificateUpsertPost({
        certificate: certificate,
      });
      message.success("Certificate updated");
    } catch (error) {
      message.error("Failed to update certificate");
    }
  };

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const res = await axios.get(
          `${API_URL}/organizations/auth0/${user?.org_id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setOrgName(res.data.name);
        setOrgId(res.data.id);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchCertificate = async () => {
      try {
        const token = await getAccessTokenSilently();
        OpenAPI.BASE = API_URL!;
        OpenAPI.HEADERS = {
          Authorization: `Bearer ${token}`,
        };
        const resp = await DefaultService.getCertificateCertificateGet();
        setCertificate(resp.certificate);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchApiKey = async () => {
      try {
        const token = await getAccessTokenSilently();
        const res = await axios.get(`${API_URL}/api_key`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        setApiKey(res.data);
        setLoading(false);
      } catch (error: any) {
        console.error(error);

        // if (error.response && error.response.status) {
        //   if (error.response.status == 403) {
        //     return (
        //       <Result
        //         status="403"
        //         title="403"
        //         subTitle="Sorry, you are not authorized to access this page."
        //         extra={<Button type="primary">Back Home</Button>}
        //       />
        //     );
        //   }
        // }
      }
    };

    fetchOrg();
    fetchCertificate();
    fetchApiKey();
  }, []);

  return (
    <RBACView view="settings">
      <Content>
        <Helmet>
          <title>Settings - Xeol</title>
        </Helmet>
        {!orgName && !apiKey && !certificate ? (
          <Loading></Loading>
        ) : (
          <Content>
            <Card title="Company Profile">
              <Space direction="vertical">
                <Row align="middle">
                  <Space direction="horizontal">
                    <Input
                      style={InputStyle}
                      value={orgName}
                      onChange={handleOrgNameInputChange}
                    />
                    <Button
                      icon={<EditOutlined />}
                      style={ButtonStyle}
                      onClick={handleUpdateClick}
                    >
                      Update
                    </Button>
                  </Space>
                </Row>
                <Row align="middle">
                  <Space direction="horizontal">
                    <Input.Password
                      style={InputStyle}
                      placeholder="API Key"
                      value={apiKey?.key}
                      addonAfter={<CopyOutlined onClick={copyApiKey} />}
                    />
                    {apiKey ? (
                      <Popconfirm
                        key="regen-key-pop"
                        title="Regenerate API Key"
                        description="Are you sure you want to renegerate your API Key?"
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        okButtonProps={{
                          style: {
                            backgroundColor: "red",
                            color: "white",
                          },
                        }}
                        onConfirm={handleRegenerateOk}
                        onCancel={handleRegenerateCancel}
                      >
                        <Button
                          icon={<RedoOutlined />}
                          style={ButtonStyle}
                          onClick={handleGenerateApiKeyClick}
                          danger
                        >
                          Regenerate
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        icon={<PlusCircleOutlined />}
                        style={ButtonStyle}
                        onClick={handleGenerateApiKeyClick}
                      >
                        Generate
                      </Button>
                    )}
                  </Space>
                </Row>
              </Space>
            </Card>
            <Card
              title="Notary Certificates"
              extra={
                <Button
                  type="text"
                  onClick={() => setIsCardCollapsed(!isCardCollapsed)}
                >
                  {isCardCollapsed ? (
                    <UpCircleOutlined />
                  ) : (
                    <DownCircleOutlined />
                  )}
                </Button>
              }
            >
              {!isCardCollapsed && (
                <Space style={{ width: "100%" }} direction="vertical">
                  {isEditingCertificate ? (
                    <TextArea
                      value={certificate}
                      onChange={(e) => setCertificate(e.target.value)}
                    />
                  ) : (
                    <pre>{certificate}</pre>
                  )}
                  {isEditingCertificate ? (
                    <Button
                      onClick={async () => {
                        await handleCertificateSave();
                        setIsEditingCertificate(false);
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button onClick={() => setIsEditingCertificate(true)}>
                      Edit
                    </Button>
                  )}
                </Space>
              )}
            </Card>
          </Content>
        )}
      </Content>
    </RBACView>
  );
};

export default SettingsPage;
