export const targetRecompose = (
  name: string,
  namespace: string,
  version: string
) => {
  if (name === "" && namespace === "" && version === "") {
    return "";
  }

  const cleanedNamespace = namespace.replace("cdx/", "");

  if (cleanedNamespace === "cdx" && name === "." && version === "") {
    return "Directory";
  }

  const versionPrefix = version.startsWith("sha256") ? "@" : ":";

  return `${cleanedNamespace}/${name}${versionPrefix}${version}`;
};
