import { Tooltip, Typography, } from 'antd';
import React, { useState } from 'react';

const { Text } = Typography;

interface Props {
  label: string;
  type?: string;
  columnLength?: number;
  charLength?: number;
}

const TruncatedText: React.FC<Props> = ({ label, type = "bold", columnLength, charLength }) => {
  const renderTruncatedString = (
    str: string,
    columnLength: number | undefined,
    charLength: number | undefined
  ) => {
    let textProps = {};

    if (type === "bold") {
      textProps = { strong: true };
    } else if (type === "secondary") {
      textProps = { type: "secondary" };
    } else if (type === "primary") {
      textProps = { type: "primary" };
    }

    if (columnLength && str.length * 10 > columnLength) {
      return (
        <Tooltip title={str}>
          <Text {...textProps}>{str.substring(0, columnLength / 10) + "..."}</Text>
        </Tooltip>
      );
    } else if (charLength && str.length > charLength) {
      return (
        <Tooltip title={str}>
          <Text {...textProps}>{str.substring(0, charLength) + "..."}</Text>
        </Tooltip>
      );
    } else {
      return <Text {...textProps}>{str}</Text>;
    }
  };

  return renderTruncatedString(label, columnLength, charLength)
};

export default TruncatedText;