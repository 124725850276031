import { Avatar, Badge, Button, Layout, Menu, Space, Tag } from "antd";
import {
  ApiOutlined,
  BarChartOutlined,
  CodeSandboxOutlined,
  FileDoneOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  RadarChartOutlined,
  SettingOutlined,
  TeamOutlined,
  HeatMapOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "../styles/menu.css";

import {
  DecodedJWT,
  UserRoles,
  AUTH0_ROLES_URL,
  USER_ROLE,
  OWNER_ROLE,
} from "../utils/jwt";

const { Content } = Layout;

interface Props {
  collapsed: boolean;
  onCollapse: (collapsed: boolean) => void;
}

const LeftMenu: React.FC<Props> = ({ collapsed, onCollapse }) => {
  const [userRole, setUserRole] = useState<UserRoles>(USER_ROLE);

  const { getAccessTokenSilently, logout, user } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  const MenuBackgroundColor = "#f5f5f5";

  const CollapseButtonStyle = {
    position: "absolute",
    right: "-15px",
    top: "50%",
    transform: "translateY(-50%)",
  } as React.CSSProperties;

  const MenuStyle = {
    backgroundColor: MenuBackgroundColor,
    borderRight: 0,
    marginTop: "6px",
  } as React.CSSProperties;

  const StickyMenuStyle = {
    backgroundColor: MenuBackgroundColor,
    borderRight: 0,
    position: "absolute",
    width: "100%",
    bottom: 0,
  } as React.CSSProperties;

  type MenuItem = Required<MenuProps>["items"][number];

  const renderMenuItem = (
    label: React.ReactNode,
    key: React.Key,
    disabled: boolean,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem => {
    return {
      key,
      icon,
      children,
      label,
      type,
      disabled,
    } as MenuItem;
  };

  const handleCollapse = () => {
    onCollapse(!collapsed);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("organizationId");
    sessionStorage.removeItem("groupByView");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleMenuItemClick = (event: any) => {
    // Check if Ctrl or Cmd key is pressed during the click
    if (event.domEvent.metaKey || event.domEvent.ctrlKey) {
      // Open the link in a new tab
      window.open(event.key, "_blank");
    } else {
      // Navigate in-app for a regular click without Ctrl or Cmd key
      navigate(event.key);
    }
  };
  const menuItems: MenuItem[] = [
    renderMenuItem("Home", "/", false, <BarChartOutlined />),
    renderMenuItem("Signals", "/signals", false, <HeatMapOutlined />),
    renderMenuItem("Graph", "/graph", false, <RadarChartOutlined />),
    renderMenuItem("Inventory", "/inventory", false, <CodeSandboxOutlined />),
    renderMenuItem(
      "Policies",
      "/policies",
      userRole !== USER_ROLE ? false : true,
      <FileDoneOutlined />
    ),
    renderMenuItem(
      "Integrations",
      "/integrations",
      userRole !== USER_ROLE ? false : true,
      <ApiOutlined />
    ),
    renderMenuItem("Team", "/team", false, <TeamOutlined />),
    renderMenuItem(
      "Settings",
      "/settings",
      userRole === OWNER_ROLE ? false : true,
      <SettingOutlined />
    ),
  ];

  const stickyMenuItems: MenuItem[] = [
    renderMenuItem(
      user?.name,
      "profile",
      false,
      <Avatar
        src={user?.picture}
        size={16}
        style={{ verticalAlign: "middle" }}
      />,
      [
        renderMenuItem(user?.email, "user-email", true),
        renderMenuItem(userRole, "user-role", true),
        renderMenuItem("Logout", "logout", false, <LogoutOutlined />),
      ]
    ),
  ];

  useEffect(() => {
    const decodeToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedJwt: DecodedJWT = jwtDecode(token);
        setUserRole(decodedJwt[AUTH0_ROLES_URL][0]);
      } catch (error) {
        console.error("error", error);
      }
    };

    decodeToken();
  }, [getAccessTokenSilently]);

  return (
    <Content>
      <Button
        style={CollapseButtonStyle}
        shape="circle"
        size="middle"
        onClick={handleCollapse}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      <div className="demo-logo-vertical" />
      <Menu
        style={MenuStyle}
        mode="inline"
        selectedKeys={[location.pathname]}
        defaultSelectedKeys={[location.pathname]}
        items={menuItems}
        onClick={handleMenuItemClick}
      />
      <Menu
        style={StickyMenuStyle}
        mode="vertical"
        items={stickyMenuItems}
        onClick={handleLogout}
      />
    </Content>
  );
};

export default LeftMenu;
