import { ProjectImage } from "../client";

export const decodeSpaces = (encodedString: string): string => {
  return encodedString.replace(/%20/g, " ");
};

export const ecosystemColorMap = {
  npm: "lime",
  pypi: "magenta",
  maven: "purple",
  nuget: "cyan",
  golang: "volcano",
  deb: "gold",
  rpm: "green",
  apk: "geekblue",
  generic: "orange",
};

export const shortenProjectName = (inputPath: string) => {
  if (inputPath.length > 50) {
    let slashCount = 0;
    for (let i = 0; i < inputPath.length; i++) {
      if (inputPath[i] === "/") {
        slashCount += 1;
      }
      if (slashCount === 3) {
        return "..." + inputPath.substring(i);
      }
    }
  }
  return decodeSpaces(inputPath);
};

export const renderImageName = (image: ProjectImage) => {
  if (image.name == "SourceScan") {
    return "Directory";
  } else {
    return `${image.name}:${image.tag}`;
  }
};

/**
 * convert a date to a really human readable string, such as
 * May 9, 2025
 */
export function toReallyHumanReadableDate(dateString: string): string {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
}

/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 */
export function getRelativeTimeString(
  dateString: string,
  lang = navigator.language
): string {
  if (!dateString) {
    return "";
  }

  const dateObj = new Date(dateString);

  // Allow dates or times to be passed
  const timeMs = dateObj.getTime();

  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

  // Array reprsenting one minute, hour, day, week, month, etc in seconds
  const cutoffs = [
    60,
    3600,
    86400,
    86400 * 7,
    86400 * 30,
    86400 * 365,
    Infinity,
  ];

  // Array equivalent to the above but in the string representation of the units
  const units: Intl.RelativeTimeFormatUnit[] = [
    "second",
    "minute",
    "hour",
    "day",
    "week",
    "month",
    "year",
  ];

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex(
    (cutoff) => cutoff > Math.abs(deltaSeconds)
  );

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: "auto" });
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}

export const toHumanReadableDate = (dateString: string): string => {
  const [year, month, day] = dateString.split("-");
  const date = new Date(Number(year), Number(month) - 1, Number(day));

  const checkDate = new Date(1990, 0, 1);
  if (date < checkDate) {
    return "True";
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString(undefined, options);
};

export const toHumanReadableDateTime = (datetimeString: string): string => {
  const date = new Date(datetimeString);
  return `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
};

export const isEol = (eolDate: string): boolean => {
  const input = new Date(eolDate);
  const today = new Date();

  input.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);

  return input < today;
};

export const truncateString = (str: string, cutoff: number): string => {
  if (str.length > cutoff) {
    return str.substring(0, cutoff) + "...";
  }
  return str;
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// turns github.com/go-logr/logr into
// pkgspec: namespace=github.com/go-logr, name=logr
export const extractNameAndNamespace = (
  namespaceandname: string | undefined
): {
  name: string;
  namespace: string;
  decodedNameAndNamespace: string;
} => {
  if (!namespaceandname) {
    return {
      name: "",
      namespace: "",
      decodedNameAndNamespace: "",
    };
  }

  const decoded = decodeURIComponent(namespaceandname);
  const split = decoded.split("/");

  if (split.length < 3) {
    return {
      name: split.length > 1 ? split[1] : split[0],
      namespace: split.length > 1 ? split[0] : "",
      decodedNameAndNamespace: decoded,
    };
  }

  return {
    name: split[split.length - 1],
    namespace: split.slice(0, split.length - 1).join("/"),
    decodedNameAndNamespace: decoded,
  };
};
