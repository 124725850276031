/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EOLFilterOptions {
    ALL = 'All',
    EOL = 'EOL',
    EOL_LESS_THAN_6MO = 'EOL LESS THAN 6MO',
    EOL_LESS_THAN_12MO = 'EOL LESS THAN 12MO',
    EOL_MORE_THAN_12MO = 'EOL MORE THAN 12MO',
}
